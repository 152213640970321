import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'toolbar-ui',
  templateUrl: './toolbar-ui.component.html',
  styleUrls: ['./toolbar-ui.component.scss']
})
export class ToolBarUiComponent implements OnInit {
  @Input() backButton: boolean = false;
  @Input() showAvatar: boolean = false;
  @Input() pageTitle: string;
  @Input() subPageTitle: string;
  @Input() profileImage: string;
  @Input() quickPerforms: any;
  @Input() quickPerformsGroup: any;
  @Input() breadCrums: any;
  @Input() hoverActionIcon: any;
  @Output() onQuickAction: EventEmitter<any> = new EventEmitter();
  @Output() onBackAction: EventEmitter<any> = new EventEmitter();
  @Output() pageLayoutChange: EventEmitter<any> = new EventEmitter();
  @Output() onHoverAction: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  backAction() {
    this.onBackAction.emit();
  }

  quickAction(key) {
    this.onQuickAction.emit(key);
  }

  hoverAction(evt) {
    this.onHoverAction.emit(evt);
  }

  onPageLayoutChange(evt) {
    this.pageLayoutChange.emit(evt);
  }

}