/* tslint:disable */
import { Injectable } from '@angular/core';
import { SDKModels } from '../lib/services/custom/SDKModels';
import {
  User, Employee, Nav, MasterEntry, UIConfig, Attendance, Holiday, Leave, Email, ImportExport, Export,
  Setting, UIForms, CredentialTypes, Environments, Preferences,
  NgClasses, CoreService, Worker, Widget
} from './models';


export interface Models { [name: string]: any }

@Injectable()
export class OMSModels extends SDKModels {

  protected models: Models = {
    User: User,
    Employee: Employee,
    Nav: Nav,
    MasterEntry: MasterEntry,
    UIConfig: UIConfig,
    Attendance: Attendance,
    Holiday: Holiday,
    Leave: Leave,
    Email: Email,
    ImportExport: ImportExport,
    Export: Export,
    Setting: Setting,
    CoreService: CoreService,
    UIForms: UIForms,
    CredentialTypes: CredentialTypes,
    Environments: Environments,
    Preferences: Preferences,
    NgClasses: NgClasses,
    Worker: Worker,
    Widget: Widget

  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
