/* tslint:disable */

declare var Object: any;
export class Environments {  
  constructor(data?: Environments) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Environments`.
   */
  public static getModelName() {
    return "Environments";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Environments for dynamic purposes.
  **/
  public static factory(data: Environments): Environments{
    return new Environments(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Environments',
      plural: 'environments',      
      path: 'environments',      
    }
  }
}
