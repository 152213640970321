import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthSystemComponent } from './auth-system.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [AuthSystemComponent],
  exports: [AuthSystemComponent]
})
export class AuthSystemModule { }