import * as moment from 'moment'
import * as env from '../../environments/environment'

let
code,
commonRegex = env.environment.commonRegex,
regexErrors = env.environment.regexErrors,
captcha_check_counter = 1,
// captcha_check_counter = 0,


fadeOut = elem => {
  let fadeEffect = setInterval(function () {
    if (!elem.style.opacity) {
      elem.style.opacity = 1;
    }
    if (elem.style.opacity > 0) {
      elem.style.opacity -= 0.1;
    } else {
      elem && elem.parentNode && elem.parentNode.removeChild(elem);
      clearInterval(fadeEffect);
    }
  }, 20);
},

remove = elem => {
  elem.parentNode.removeChild(elem);
},

removeErrorMsgs = elem => {
  let wrapper = elem.closest('nz-form-item');
  // remove old error messages
  wrapper && wrapper.querySelector('.custom-error') && remove(wrapper.querySelector('.custom-error'));
  return wrapper;
},

shake = (elem, regex=null, msg="Invalid Inpupt") => {
  elem.classList.add("shake");
  let wrapper = removeErrorMsgs(elem),
  // wrapper.querySelector('.custom-error') && remove(wrapper.querySelector('.custom-error'));
  errMsg = document.createElement('span'); // new element for error message

  if(regex) {
    for(let key in commonRegex) {
      (commonRegex[key] === regex) && regexErrors[key] && (msg = regexErrors[key]);
    }
  }
  // adding class to element
  errMsg.setAttribute('class','custom-error');
  // setting message
  errMsg.innerHTML = msg; 
  
  // pushing element on view
  if(wrapper) {
    if(wrapper.querySelector('nz-form-explain')) {
      let ref = wrapper.querySelector('nz-form-explain');
      ref.parentNode.insertBefore(errMsg, ref);
    } else {
      wrapper.appendChild(errMsg);
    }
  }
  // removing element after 2 seconds
  setTimeout(() => { fadeOut(errMsg) }, 2000);
  // stop shaking effect
  setTimeout(() => elem.classList.remove('shake'), 300)
  return false;
},

regexFilter = (e, regex) => {
  removeErrorMsgs(e.target)
  if(e.target.selectionStart == 0 && e.charCode == 32)
    return shake(e.target, null, `Value can't start with space`)
  
  if(!e.key.match(regex))
    return shake(e.target, regex);
  return true;
},

pasteFilter = (e, regex) => {
  removeErrorMsgs(e.target)
  if(!e.clipboardData.getData('text').match(regex)) 
    return shake(e.target, regex);
  return true;
},

validateFloat = (e, decimalPlaces=null) => {
  if(!(e.charCode == 46 || e.charCode >= 48 && e.charCode <= 57)) {
    return shake(e.target);
  } else if(decimalPlaces || decimalPlaces === 0) {
    if(decimalPlaces === 0 && e.charCode == 46) return shake(e.target);
    
    let val = e.target.value;
    if(val && val.indexOf('.') >= 0) {
      if(e.charCode == 46) return shake(e.target);
      
      let arr = val.split('.');
      if(arr[1].length >= decimalPlaces) return shake(e.target);
    }
  }
},

createCaptcha = (elemId) => {
  //clear the contents of captcha div first 
  document.getElementById(elemId).innerHTML = "";
  var charsArray =
  "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ@!#$%^&*";
  var lengthOtp = 6;
  var captcha = [];
  for (var i = 0; i < lengthOtp; i++) {
    //below code will not allow Repetition of Characters
    var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
    if (captcha.indexOf(charsArray[index]) == -1)
      captcha.push(charsArray[index]);
    else i--;
  }
  var canv = document.createElement("canvas");
  canv.id = elemId;
  canv.width = 200;
  canv.height = 50;
  var ctx = canv.getContext("2d");
  ctx.font = "25px Georgia";
  ctx.strokeText(captcha.join(""), 0, 30);
  //storing captcha so that can validate you can save it somewhere else according to your specific requirements
  code = captcha.join("");
  document.getElementById(elemId).appendChild(canv); // adds the canvas to the body element
  captcha_check_counter = 1;
},

validateCaptcha = (elemId, captcha, callback) => {
  event.preventDefault();
  if (captcha == code) {
    callback(true)
  }else{
    callback(false)
    captcha_check_counter >= 5 ? createCaptcha(elemId) : captcha_check_counter++;
  }
},

getDPI = () => {
  var dpiTestElement = document.createElement('div'),
  body = document.getElementsByTagName('body')[0],
  dpi = 72;

  dpiTestElement.setAttribute('class', 'dpi-test-elem');
  dpiTestElement.setAttribute('id', 'dpi-test');
  body.appendChild(dpiTestElement);
  dpi = document.getElementById('dpi-test').offsetHeight;
  dpiTestElement.parentNode.removeChild(dpiTestElement);

  return dpi;
},

getPos = (elem) => {
  // for contentedit field
  if (elem.isContentEditable) {
      elem.focus()
      let _range = document.getSelection().getRangeAt(0)
      let range = _range.cloneRange()
      range.selectNodeContents(elem)
      range.setEnd(_range.endContainer, _range.endOffset)
      return range.toString().length;
  }
  // for texterea/input element
  return elem.selectionStart
},

setPos = (elem, pos) => {
  // for contentedit field
  if (elem.isContentEditable) {
      elem.focus()
      document.getSelection().collapse(elem, 10);
      return
  }
  elem.setSelectionRange(pos, pos)
},

getAge = _dob => {
  let dob = moment(_dob), age;
  if(dob.isValid()) {
    if(age = moment().diff(dob, 'years')) {
      return `${age} Year${age > 1 ? 's' : ''}`;
    } else if (age = moment().diff(dob, 'months') && age > 3) {
      return `${age} Month${age > 1 ? 's' : ''}`;
    } else if (age = moment().diff(dob, 'days')) {
      return `${age} Day${age > 1 ? 's' : ''}`;
    } else if (age = moment().diff(dob, 'hours')) {
      return `${age} Hour${age > 1 ? 's' : ''}`;
    } else if (age = moment().diff(dob, 'minutes')) {
      return `${age} Minute${age > 1 ? 's' : ''}`;
    } else if (age = moment().diff(dob, 'seconds')) {
      return `${age} Second${age > 1 ? 's' : ''}`;
    }
    return 'N/A';
  }
  return 'N/A';
},

getAddressString = add => {
    let address = '';
    address = add.hasOwnProperty("house_no") && add.house_no ? add.house_no : '';
    address += address ? add.hasOwnProperty("locality")            && add.locality             ? `, ${add.locality}`                 : '' : add.locality            ? add.locality : '';
    address += address ? add.hasOwnProperty("street")              && add.street               ? `, ${add.street}`                   : '' : add.street              ? add.street : '';
    address += address ? add.hasOwnProperty("nearest_rly_station") && add.nearest_rly_station  ? `, near ${add.nearest_rly_station}` : '' : add.nearest_rly_station ? `near ${add.nearest_rly_station}` : '';
    address += address ? add.hasOwnProperty("cityName")            && add.cityName             ? `, ${add.cityName}`                 : '' : add.cityName            ? add.cityName : '';
    address += address ? add.hasOwnProperty("stateName")           && add.stateName            ? `, ${add.stateName}`                : '' : add.stateName           ? add.stateName : '';
    address += address ? add.hasOwnProperty("countryName")         && add.countryName          ? `, ${add.countryName}`              : '' : add.countryName         ? add.countryName : '';
    address += address ? add.hasOwnProperty("zip")                 && add.zip                  ? ` - ${add.zip}`                     : '' : add.zip                 ? add.zip : '';

    return address;
}

export { regexFilter, pasteFilter, validateFloat, createCaptcha, validateCaptcha, getPos, setPos, getDPI, getAge, getAddressString }