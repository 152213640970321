import { Routes, RouterModule } from '@angular/router';
import { DayCareUserComponent } from './day-care-user.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'day-care',
    component: DayCareUserComponent,
    children: [
      { path: '', redirectTo: 'manage-patients', pathMatch: 'full' },
      { path: 'today-schedule', loadChildren: './today-scheduled/today-slots.module#TodaySlotsModule' },
      { path: 'manage-patients', loadChildren: './manage-patients/manage-patients.module#ManagepatientsModule' },
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
