import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../shared/api/auth.guard';
import { routing } from './base.routing';
import { BaseComponent } from './base.component';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { DebugerModule } from '../core/debuger/debuger.module';
import { HeaderModule } from '../core/layouts/header/header.module';
// import { ArchiveDataComponent } from './archive-data/archive-data.component';

@NgModule({
  imports: [
    CommonModule,
    LayoutsModule,
    HeaderModule,
    routing,
    NgZorroAntdModule.forRoot(),
    DebugerModule,
  ],
  declarations: [BaseComponent],
  providers: [AuthGuard],
})
export class BaseModule { }
