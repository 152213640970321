export class UIConfig {
  public static config: any = {
    pageTitle: 'Follow-up After Treatment',
    technique: [
      {
        "id": 1,
        // "key": "2D",
        "label": "2D",
      },
      {
        "id": 2,
        // "key": "3DCRT",
        "label": "3DCRT",
      },
      {
        "id": 3,
        // "key": "IMRT",
        "label": "IMRT",
      },
    ],
    commonSearchIn: [
      'first_name',
      'last_name',
      'cr_no',
      'rt_no',
      'hbcr_no'
    ],
    commonAppointmentSearchIn: [
      // 'booking_date',
      'first_name',
      //'booking_time',
    ],
    commonTreatmentsFollowUpsSearchIn: [
      // 'booking_date',
      'followup_date',
      // 'booking_time',
    ],
    quickPerforms: [
      { nzType: 'default', nzShape: '', antIcons: '', btnText: 'Add Old Patient Record', key: 'add_old_patient_record', disabled: 'true' }
    ],
    quickPerformsSection: [
      // { nzType: 'default', nzTitle: 'Export', nzShape: '', antIcons: 'export', btnText: '', key: 'export' },
      // { nzType: 'default', nzTitle: 'Import', nzShape: '', antIcons: 'download', btnText: '', key: 'import' },
      { nzType: 'primary', nzTitle: '', nzShape: '', antIcons: '', btnText: 'Book Slot', key: 'bookSlot' },
    ],
    tableUI: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R. Number', width: '10%' },
        { label: 'Age', width: '5%' },
        { label: 'Primary Site', width: '10%' },
        { label: 'Gender', width: '5%' },
        { label: 'Patient Status', width: '10%' },
        { label: 'Action', width: '20%' },
      ],
    },
    treatmentsDataHeading: {
      columns: [
        { label: 'Site Name', width: '35%' },
        { label: 'Treatment Type', width: '35%' },
        { label: 'Treatment Completion', width: '30%' },
      ],
    },
    appointmentHeading: {
      columns: [
        { label: 'Doctor Name', width: '10%' },
        { label: 'Booking Date', width: '10%' },
        { label: 'Booking Time', width: '10%' },
        { label: 'Appointment Status', width: '15%' },
        { label: 'Appointment Type', width: '15%' },
        { label: 'Remark', width: '15%' },
      ],
    },
    referHeadeingData: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'Age', width: '10%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R.', width: '10%' },
        { label: 'Gender', width: '10%' },
        { label: 'Action', width: '20%' },
      ],
    },
    infiltrationHeading: {
      columns: [
        { label: '', width: '25%' },
        { label: 'Not Applicable', width: '30%' },
        { label: 'Present', width: '15%' },
        { label: 'Absent', width: '15%' },
        { label: 'Unknown', width: '15%' },
      ],
    },
    premalignantHeading: {
      columns: [
        { label: '', width: '45%' },
        { label: 'Present', width: '15%' },
        { label: 'Not Present', width: '25%' },
        { label: 'Unknown', width: '15%' },
      ],
    },
    performstatusHeading: {
      columns: [
        { label: 'Heading', width: '90%' },
        { label: 'Action', width: '10%' },
      ],
    },
    detailsExternalRTHeading: {
      columns: [
        { label: '', width: '15%' },
        { label: 'I', width: '25%' },
        { label: 'II', width: '25%' },
        { label: 'III', width: '25%' },
      ],
    },
    detailsBrachytherapyHeading: {
      columns: [
        { label: '', width: '15%' },
        { label: 'I', width: '15%' },
        { label: 'II', width: '15%' },
        { label: 'III', width: '15%' },
        { label: 'IV', width: '15%' },
        { label: 'V', width: '15%' },
        { label: '>V', width: '15%' },
      ],
    },
    ctalonewhoHeading: {
      columns: [
        { label: '', width: '55%' },
        { label: 'Primary', width: '20%' },
        { label: 'Neck Node', width: '20%' },
      ],
    },
    rtalonewhoHeading: {
      columns: [
        { label: '', width: '55%' },
        { label: 'Primary', width: '20%' },
        { label: 'Neck Node', width: '20%' },
      ],
    },
    rtctwhoHeading: {
      columns: [
        { label: '', width: '55%' },
        { label: 'Primary', width: '20%' },
        { label: 'Neck Node', width: '20%' },
      ],
    },
    ctcyclesHeading: {
      columns: [
        { label: 'Cycles', width: '5%' },
        { label: 'I', width: '10%' },
        { label: 'II', width: '10%' },
        { label: 'III', width: '10%' },
        { label: 'IV', width: '10%' },
        { label: 'V', width: '10%' },
        { label: 'VI', width: '10%' },
        { label: '>VI', width: '10%' },
      ],
    },
    tableUIOPDPatient: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R. Number', width: '10%' },
        { label: 'Reg. Date', width: '10%' },
        { label: 'Primary Site', width: '10%' },
        { label: 'Age', width: '5%' },
        { label: 'Gender', width: '5%' },
        { label: 'Status', width: '10%' },
        { label: 'Action', width: '15%' },
      ],
    },
    tableUIOngoing: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R. Number', width: '10%' },
        { label: 'Primary Site', width: '10%' },
        { label: 'Treatment', width: '10%' },
        { label: 'Start Date', width: '10%' },
        { label: 'Treatment Status', width: '13%' },
        { label: 'Action', width: '15%' },
      ],
    },
    tableUIOldpatient: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'Age', width: '5%' },
        { label: 'Primary Site', width: '10%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R. Number', width: '10%' },
        { label: 'Status', width: '15%' }
      ],
    },
    tableUIFollowUpPatient: {
      columns: [
        { label: '', width: '1%' },
        { label: 'Patient Name', width: '10%' },
        { label: 'Primary Site', width: '10%' },
        { label: 'Treatment Type', width: '12%' },
        { label: 'C.R. Number', width: '10%' },
        { label: 'R.T. Number', width: '10%' },
        { label: 'H.B.C.R. Number', width: '10%' },
        { label: 'Doctor', width: '13%' },
        { label: 'Status', width: '10%' },
        { label: 'Action', width: '15%' },
      ],
    },
    habitsHeading: {
      columns: [
        { label: 'Habits', width: '50%' },
        { label: 'Yes / No / Unknown', width: '60%' },
      ],
    },
    comorbidHeading: {
      columns: [
        { label: 'Co-Morbid', width: '30%' },
        { label: 'Yes / No / Unknown', width: '60%' },
      ],
    },
    performstatusData: [
      {
        id: 1,
        statment: 'Able to carry out all normal activity without restriction.',
        field: 'checkbox',
        value: 0
      },
      {
        id: 2,
        statment: 'Restricted in physically strenuous activity but ambulatory and able carry out light work.',
        field: 'checkbox',
        value: 1
      },
      {
        id: 3,
        statment: 'Ambulatory and capable of all self-care but unable to carry out any work; up and about more than 50% of waking hours.',
        field: 'checkbox',
        value: 2
      },
      {
        id: 4,
        statment: 'Capable of only limited self-care; confined to bed or chair more than 50% of waking hours.',
        field: 'checkbox',
        value: 3
      },
      {
        id: 5,
        statment: 'Completely disabled; cannot carry on any self-care; totally confined to bed or chair.',
        field: 'checkbox',
        value: 4
      },
      {
        id: 6,
        statment: 'Unknown',
        field: 'checkbox',
        value: 9
      }
    ],
    lateralityData: [
      {
        id: 1,
        name: 'Not a paired site'
      },
      {
        id: 2,
        name: 'Right'
      },
      {
        id: 3,
        name: 'Left'
      },
      {
        id: 4,
        name: 'Only one site involved, right or left, unknown'
      },
      {
        id: 5,
        name: 'Bilateral involvement, lateral origin unknown'
      },
      {
        id: 6,
        name: 'Paired site, but no information concerning laterality'
      }
    ],
    squencediagnosticData: [
      {
        id: 1,
        name: 'One Primary Only'
      },
      {
        id: 2,
        name: 'First of two or more primaries'
      },
      {
        id: 3,
        name: 'Second of two or more primaries'
      },
      {
        id: 4,
        name: 'Third of three or more primaries'
      },
      {
        id: 5,
        name: 'Fourth of four or more primaries'
      },
      {
        id: 6,
        name: 'Fifth of five or more primaries'
      },
      {
        id: 7,
        name: 'Sixth of six or more primaries'
      },
      {
        id: 8,
        name: 'Seventh of seven or more primaries'
      },
      {
        id: 9,
        name: 'Eigth or later primary'
      },
      {
        id: 10,
        name: 'Unspecified sequence number (Unknown)'
      }
    ],
    OPDData: [],
    diagnosedData: [],
    ongoingData: [],
    oldpatientData: [],
    habitsData: [
      {
        id: 1,
        habits: 'No Specific Habits',
      },
      {
        id: 2,
        habits: 'Cigarette Smoking',
      },
      {
        id: 3,
        habits: 'Beedi Smoking',
      },
      {
        id: 4,
        habits: 'Tabacco Chewing',
      },
      {
        id: 5,
        habits: 'Alcohol Consumption',
      },
      {
        id: 6,
        habits: 'Use of Misheri'
      },
      {
        id: 7,
        habits: 'Pan-masala chewing'
      },
      {
        id: 8,
        habits: 'Use of snuff'
      },
      {
        id: 9,
        habits: 'Betel nut chewing'
      },
      {
        id: 10,
        habits: 'Gutkha chewing'
      }
    ],
    comorbidData: [
      {
        id: 1,
        morbid: 'Tuberculosis'
      },
      {
        id: 2,
        morbid: 'Hypertension'
      },
      {
        id: 3,
        morbid: 'Diabetes'
      },
      {
        id: 4,
        morbid: 'Ischaemic Heart Disease'
      },
      {
        id: 5,
        morbid: 'Bronchial Asthma'
      },
      {
        id: 6,
        morbid: 'Allergic Conditions (specify)'
      },
      {
        id: 7,
        morbid: 'Hepatitis/ HBsAg +ve'
      },
      {
        id: 8,
        morbid: 'Others (specify)'
      },
      {
        id: 9,
        morbid: 'AIDS/HIV +ve'
      },
      {
        id: 10,
        morbid: 'HPV +ve'
      },
      {
        id: 11,
        morbid: 'Renal Insufficiency'
      }
    ],
    stagingFollowedData: [
      {
        id: 1,
        name: 'TNM staging'
      },
      {
        id: 2,
        name: 'Others (specify)'
      },
      {
        id: 3,
        name: 'Unknown'
      }
    ],
    stagingDoneData: [
      {
        id: 1,
        name: 'Report Institution'
      },
      {
        id: 2,
        name: 'Previous Institution'
      },
      {
        id: 3,
        name: 'Others (specify)'
      },
      {
        id: 4,
        name: 'Unknown'
      }
    ],
    tnmstageTData: [
      {
        id: 1,
        name: 'TX'
      },
      {
        id: 2,
        name: 'T0'
      },
      {
        id: 3,
        name: 'TiS'
      },
      {
        id: 4,
        name: 'T1'
      },
      {
        id: 5,
        name: 'T2'
      },
      {
        id: 6,
        name: 'T2a'
      },
      {
        id: 7,
        name: 'T2b'
      },
      {
        id: 8,
        name: 'T3'
      },
      {
        id: 9,
        name: 'T4'
      },
      {
        id: 10,
        name: 'T4a'
      },
      {
        id: 11,
        name: 'T4b'
      },
      {
        id: 12,
        name: 'Unknown'
      }
    ],
    tnmstageSData: [
      {
        id: 1,
        name: 'NX'
      },
      {
        id: 2,
        name: 'N0'
      },
      {
        id: 3,
        name: 'N1'
      },
      {
        id: 4,
        name: 'N1a'
      },
      {
        id: 5,
        name: 'N1b'
      },
      {
        id: 6,
        name: 'N2'
      },
      {
        id: 7,
        name: 'N2a'
      },
      {
        id: 8,
        name: 'N2b'
      },
      {
        id: 9,
        name: 'N2c'
      },
      {
        id: 10,
        name: 'N3'
      },
      {
        id: 11,
        name: 'N3a'
      },
      {
        id: 12,
        name: 'N3b'
      },
      {
        id: 13,
        name: 'Unknown'
      },
    ],
    specifylevelData: [
      { id: 1, name: 1 }, { id: 2, name: 2 }, { id: 3, name: 3 }, { id: 4, name: 4 }, { id: 5, name: 5 }, { id: 6, name: 6 }, { id: 7, name: 7 }
    ],
    tnmstageMData: [
      { id: 1, name: 'MX' }, { id: 2, name: 'M0' }, { id: 3, name: 'M1' }, { id: 4, name: 'Unknown' }
    ],
    premalignantData: [
      {
        id: 1,
        name: 'Leukoplakia'
      },
      {
        id: 2,
        name: 'Erythroplakia'
      },
      {
        id: 3,
        name: 'Sub-mucosal Fibrosis'
      },
      {
        id: 4,
        name: 'Others (specify)'
      }
    ],
    stageGroupingData: [
      {
        id: 1,
        name: 'I'
      },
      {
        id: 2,
        name: 'II'
      },
      {
        id: 3,
        name: 'III'
      },
      {
        id: 4,
        name: 'IV'
      },
      {
        id: 5,
        name: 'IVA'
      },
      {
        id: 6,
        name: 'IVB'
      },
      {
        id: 7,
        name: 'IVC'
      },
      {
        id: 8,
        name: 'Unknown'
      }
    ],
    investigationstagingData: [
      {
        id: 1,
        name: 'Chest X-ray'
      },
      {
        id: 2,
        name: 'Other X-ray (specify)'
      },
      {
        id: 3,
        name: 'CT-Scan'
      },
      {
        id: 4,
        name: 'MRI'
      },
      {
        id: 5,
        name: 'Barium Swallow'
      },
      {
        id: 6,
        name: 'Ultrasonogram'
      },
      {
        id: 7,
        name: 'Endoscopy'
      },
      {
        id: 8,
        name: 'Others (specify)'
      }
    ],
    actualassessmentData: [
      {
        id: 1,
        name: 'One Consultant Oncologist (CO) only'
      },
      {
        id: 2,
        name: 'Two COs from same department'
      },
      {
        id: 3,
        name: 'Two COs from different departments'
      },
      {
        id: 4,
        name: 'Tumour Board/Joint Clinic'
      },
      {
        id: 5,
        name: 'Others (specify)'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    regisriData: [
      {
        id: 0,
        name: 'No'
      },
      {
        id: 2,
        name: 'Yes'
      },
      {
        id: 3,
        name: 'Unknown'
      }
    ],
    priortreatmentData: [
      {
        id: 1,
        name: 'Surgery',
        control: 'surgery',
        dateControl: 'surgeryDate',
        status: 0
      },
      {
        id: 2,
        name: 'Radiotherapy',
        control: 'radioTherapy',
        dateControl: 'radioTherapyDate',
        status: 1
      },
      {
        id: 3,
        name: 'Chemotherapy',
        control: 'chemoTheray',
        dateControl: 'chemoTherayDate',
        status: 2
      },
      {
        id: 4,
        name: 'Others (specify)',
        control: 'others',
        dateControl: 'otherDate',
        status: 3
      }
    ],
    intentionData: [
      {
        id: 1,
        name: 'Curative/Radical'
      },
      {
        id: 2,
        name: 'Palliative'
      },
      {
        id: 3,
        name: 'No Treatment'
      },
      {
        id: 4,
        name: 'Unknown'
      }
    ],
    palliativeData: [
      {
        id: 1,
        name: 'Palliative RT Only'
      },
      {
        id: 2,
        name: 'Palliative RT + CT'
      },
      {
        id: 3,
        name: 'Palliative CT Only'
      },
      {
        id: 4,
        name: 'Pain & Symptom Relief Drugs (specify)'
      },
      {
        id: 5,
        name: 'Palliative Surgery'
      },
      {
        id: 6,
        name: 'Others (specify)'
      },
      {
        id: 7,
        name: 'Unknown'
      }
    ],
    cancerditreatData: [
      {
        id: 1,
        name: 'Surgery'
      },
      {
        id: 2,
        name: 'Radiotherapy'
      },
      {
        id: 3,
        name: 'Chemotherapy'
      },
      {
        id: 4,
        name: 'Others (specify)'
      }
    ],
    surgeryData: [
      {
        id: 1,
        name: 'Surgery Not Planned'
      },
      {
        id: 2,
        name: 'Yes, done as planned'
      },
      {
        id: 3,
        name: 'Surgery planned but not taken'
      },
      {
        id: 4,
        name: 'Others (specify)'
      },
      {
        id: 5,
        name: 'Unknown'
      }
    ],
    lipData: [
      {
        id: 1,
        name: 'Wide excision'
      },
      {
        id: 2,
        name: 'Lip shave'
      },
      {
        id: 3,
        name: 'Composite resection with marginal mandibulectomy'
      },
      {
        id: 4,
        name: 'Composite resection with segmental mandibulectomy'
      },
    ],
    floormouthData: [
      {
        id: 1,
        name: 'Peroral'
      },
      {
        id: 2,
        name: 'Composite resection with marginal mandibulectomy'
      },
      {
        id: 3,
        name: 'Composite resection with segmental mandibulectomy'
      },
      {
        id: 4,
        name: 'Composite resection with hemi-mandibulectomy'
      },
      {
        id: 5,
        name: 'Composite resection with mandibulectomy'
      }
    ],
    oraltongueData: [
      {
        id: 1,
        name: 'Wide excision'
      },
      {
        id: 2,
        name: 'Hemi-glossectomy'
      },
      {
        id: 3,
        name: 'Peroral subtotal / ant 2/3rd glossectomy'
      },
      {
        id: 4,
        name: 'Composite resection with marginal mandibulectomy'
      },
      {
        id: 5,
        name: 'Compsoite resection with segmental/hemi-mandibulectomy'
      },
      {
        id: 6,
        name: 'Pull through glossectomy'
      }
    ],
    alveolobuccalData: [
      {
        id: 1,
        name: 'Peroral wide excision'
      },
      {
        id: 2,
        name: 'Composite resection with marginal mandibulectomy'
      },
      {
        id: 3,
        name: 'Composite resection with Segmental mandibulectomy'
      },
      {
        id: 4,
        name: 'Composite resection with hemi/ more mandibulectomy'
      },
      {
        id: 5,
        name: 'Bite resection'
      },
      {
        id: 6,
        name: 'Upper alveolectomy peroral'
      }
    ],
    oropharynxData: [
      {
        id: 1,
        name: 'Tonsil composite resection'
      },
      {
        id: 2,
        name: 'Base tongue comnposite resection'
      },
      {
        id: 3,
        name: 'Soft palate extension'
      }
    ],
    paranasalsinusData: [
      {
        id: 1,
        name: 'Cald wel luc'
      },
      {
        id: 2,
        name: 'Partial maxillectomy'
      },
      {
        id: 3,
        name: 'Total maxillectomy'
      },
      {
        id: 4,
        name: 'Radical maxillectomy'
      },
      {
        id: 5,
        name: 'Ant craniofacial resection'
      },
      {
        id: 6,
        name: 'Median maxilelctomy'
      },
      {
        id: 7,
        name: 'Ethmoidectomy'
      }
    ],
    larynxData: [
      {
        id: 1,
        name: 'Horizontal Supraglotic'
      },
    ],
    hypopharynxData: [
      {
        id: 1,
        name: 'Lateral pharyngectomy'
      }
    ],
    salivaryglandData: [
      {
        id: 1,
        name: 'Superficial parotidectomy'
      },
      {
        id: 2,
        name: 'Total parotidectomy'
      },
      {
        id: 3,
        name: 'Radical parotidectomy'
      },
      {
        id: 4,
        name: 'Excision minor salivary gland'
      },
      {
        id: 5,
        name: 'Composite Resection'
      }
    ],
    thyroidData: [
      {
        id: 1,
        name: 'Hemi thyroidectomy'
      },
      {
        id: 2,
        name: 'Total thyroidectomy'
      },
      {
        id: 3,
        name: 'Subtotal thyroidectomy'
      },
      {
        id: 4,
        name: 'Near total thyroidectomy'
      }
    ],
    tracheaData: [
      {
        id: 1,
        name: 'Circumferential tracheal resection'
      },
      {
        id: 2,
        name: 'Partial tracheal resection'
      }
    ],
    neackData: [
      {
        id: 1,
        name: 'Excision of para pharyngeal mass'
      },
      {
        id: 2,
        name: 'Excisin of neck soft tissue sarcoma'
      }
    ],
    faceData: [
      {
        id: 1,
        name: 'W/e for skin cancer face'
      },
      {
        id: 2,
        name: 'W/e nose'
      }
    ],
    earData: [
      {
        id: 1,
        name: 'Excision of pinna total'
      },
      {
        id: 2,
        name: 'Excision of pinna partial'
      },
      {
        id: 3,
        name: 'Sleeve resection of external auditory canal'
      },
      {
        id: 4,
        name: 'Temporal bone excision'
      }
    ],
    surgeryneackData: [
      {
        id: 1,
        name: 'No surgery for Neck'
      },
      {
        id: 2,
        name: 'Ipsilateral neck dissection (RND)'
      },
      {
        id: 3,
        name: 'Ipsilateral XIth nerve sparing neck dissection'
      },
      {
        id: 4,
        name: 'Ipsilateral FND'
      },
      {
        id: 5,
        name: 'Ipsilateral SOHD'
      },
      {
        id: 6,
        name: 'B/I SOHD'
      },
      {
        id: 7,
        name: '1 or 2 with contralateral SOHD'
      },
      {
        id: 8,
        name: 'Bilateral MND'
      },
      {
        id: 9,
        name: 'Bilateral II/III/IV node dissection'
      },
      {
        id: 10,
        name: 'Ipsilateral II/III/IV node dissection'
      },
      {
        id: 11,
        name: 'Central compartment clearance'
      }
    ],
    reconstructionData: [
      {
        id: 1,
        name: 'No Reconstruction'
      },
      {
        id: 2,
        name: 'PMMC'
      },
      {
        id: 3,
        name: 'Local'
      },
      {
        id: 4,
        name: 'PMMC + DP'
      },
      {
        id: 5,
        name: 'Forehead flap'
      },
      {
        id: 6,
        name: 'Estlander'
      },
      {
        id: 7,
        name: 'Free flap'
      }
    ],
    infiltrationData: [
      {
        id: 1,
        name: 'Skin'
      },
      {
        id: 2,
        name: 'Cartilage'
      },
      {
        id: 3,
        name: 'Bone'
      },
      {
        id: 4,
        name: 'Muscle'
      },
      {
        id: 5,
        name: 'Soft tissue'
      },
      {
        id: 6,
        name: 'Others'
      }
    ],
    submucosalData: [
      {
        id: 1,
        name: 'Sub-mucosal Spread'
      }
    ],
    invasionData: [
      {
        id: 1,
        name: 'Lymphatic'
      },
      {
        id: 2,
        name: 'Vascular'
      },
      {
        id: 3,
        name: 'Perineural'
      }
    ],
    cutmarginData: [
      {
        id: 1,
        name: 'Not Applicable'
      },
      {
        id: 2,
        name: 'Negative'
      },
      {
        id: 3,
        name: 'Close'
      },
      {
        id: 4,
        name: 'Positive'
      },
      {
        id: 5,
        name: 'Ca-In-Situ'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    nodessizeData: [
      {
        id: 1,
        name: 'Not Applicable'
      },
      {
        id: 2,
        name: 'Negative for Mets.'
      },
      {
        id: 3,
        name: 'Ipsilateral Positive'
      },
      {
        id: 4,
        name: 'Contralateral Positive'
      },
      {
        id: 5,
        name: 'Bilateral Positive'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    nodepositivityData: [
      {
        id: 1,
        name: 'Not Applicable'
      },
      {
        id: 2,
        name: 'Single Level'
      },
      {
        id: 3,
        name: 'Multiple Levels'
      },
      {
        id: 4,
        name: 'Unknown'
      }
    ],
    periextranodalData: [
      {
        id: 1,
        name: 'Not Applicable'
      },
      {
        id: 2,
        name: 'Present'
      },
      {
        id: 3,
        name: 'Absent'
      },
      {
        id: 4,
        name: 'Unknown'
      }
    ],
    pathologicalPTData: [
      {
        id: 1,
        name: 'pTX'
      },
      {
        id: 2,
        name: 'pT0'
      },
      {
        id: 3,
        name: 'pTis'
      },
      {
        id: 4,
        name: 'pT1'
      },
      {
        id: 5,
        name: 'pT2'
      },
      {
        id: 6,
        name: 'pT2a'
      },
      {
        id: 7,
        name: 'pT2b'
      },
      {
        id: 8,
        name: 'pT3'
      },
      {
        id: 9,
        name: 'pT4'
      },
      {
        id: 10,
        name: 'pT4a'
      },
      {
        id: 11,
        name: 'pT4b'
      },
      {
        id: 12,
        name: 'Unknown'
      }
    ],
    pathologicalPNData: [
      {
        id: 1,
        name: 'pNX'
      },
      {
        id: 2,
        name: 'pN0'
      },
      {
        id: 3,
        name: 'pN1'
      },
      {
        id: 4,
        name: 'pN1a'
      },
      {
        id: 5,
        name: 'pN1b'
      },
      {
        id: 6,
        name: 'pN2'
      },
      {
        id: 7,
        name: 'pN2a'
      },
      {
        id: 8,
        name: 'pN2b'
      },
      {
        id: 9,
        name: 'pN2c'
      },
      {
        id: 10,
        name: 'pN3'
      },
      {
        id: 11,
        name: 'pN3a'
      },
      {
        id: 12,
        name: 'pN3b'
      },
      {
        id: 13,
        name: 'Unknown'
      }
    ],
    pathologicalPMData: [
      {
        id: 1,
        name: 'pMX'
      },
      {
        id: 2,
        name: 'pM0'
      },
      {
        id: 3,
        name: 'pM1 (specify)'
      },
      {
        id: 4,
        name: 'Unknown'
      }
    ],
    rclassificationData: [
      {
        id: 1,
        name: 'RX'
      },
      {
        id: 2,
        name: 'R0'
      },
      {
        id: 3,
        name: 'R1'
      },
      {
        id: 4,
        name: 'R2'
      },
      {
        id: 5,
        name: 'Unknown'
      }
    ],
    radiotherapyData: [
      {
        id: 1,
        name: 'Radiotherapy (RT) not planned'
      },
      {
        id: 2,
        name: 'Yes, RT given as planned'
      },
      {
        id: 3,
        name: 'Yes, RT given, but incomplete (specify reason)'
      },
      {
        id: 4,
        name: 'RT planned but not taken (specify reason)'
      },
      {
        id: 5,
        name: 'Others (specify)'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    detailteletherapyData: [
      {
        id: 1,
        name: '2DCRT'
      },
      {
        id: 2,
        name: '3DCRT'
      },
      {
        id: 3,
        name: 'IMRT'
      },
      {
        id: 4,
        name: 'IORT'
      },
      {
        id: 5,
        name: 'Tomotherapy'
      },
      {
        id: 6,
        name: 'Electron Beam'
      },
      {
        id: 7,
        name: 'Others (specify)'
      }
    ],
    rtmachineData: [
      {
        id: 1,
        name: 'Linear Accelerator'
      },
      {
        id: 2,
        name: 'Cobalt'
      },
      {
        id: 3,
        name: 'Others (specify)'
      }
    ],
    typeofRTData: [
      {
        id: 1,
        name: 'Teletherapy (External RT)'
      },
      {
        id: 2,
        name: 'Brachytherapy'
      },
      {
        id: 3,
        name: 'Others (specify)'
      }
    ],
    detailsExternalRTData: [
      {
        id: 1,
        name: 'Technique (specify)',
        field: 'input'
      },
      {
        id: 2,
        name: 'Type of beam (Photon/Electron)',
        field: 'input'
      },
      {
        id: 3,
        name: 'Energy',
        field: 'input'
      },
      {
        id: 4,
        name: 'Field Size',
        field: 'input'
      },
      {
        id: 5,
        name: 'Total No. of Fields',
        field: 'input'
      },
      {
        id: 6,
        name: 'Total Tumour Dose (cGy)',
        field: 'input'
      },
      {
        id: 7,
        name: 'Total No. of Fractions',
        field: 'input'
      },
      {
        id: 8,
        name: 'Fractions/week',
        field: 'input'
      },
      {
        id: 9,
        name: 'Region(s) of Irradiation',
        field: 'input'
      },
      {
        id: 10,
        name: 'Interruption - Yes(Y) / No(N)',
        field: 'dropdown'
      },
      {
        id: 11,
        name: 'If yes, specify reason for interruption',
        field: 'input'
      },
      {
        id: 12,
        name: 'Date first started',
        field: 'datepicker'
      },
      {
        id: 13,
        name: 'Date last ended',
        field: 'datepicker'
      }
    ],
    detailsBrachytherapyData: [
      {
        id: 1,
        name: 'Date',
        field: 'datepicker'
      },
      {
        id: 2,
        name: 'Type of Dose Rate (LDR/MDR/HDR/PDR)',
        field: 'input'
      },
      {
        id: 3,
        name: 'Prescribed dose in cGy',
        field: 'input'
      },
      {
        id: 4,
        name: 'Volume of implant',
        field: 'input'
      },
      {
        id: 5,
        name: 'Dose rate in cGy',
        field: 'input'
      }
    ],
    chemotherapyData: [
      {
        id: 1,
        name: 'Chemotherapy (CT) not planned'
      },
      {
        id: 2,
        name: 'Yes, CT given as planned'
      },
      {
        id: 3,
        name: 'Yes, CT given but incomplete'
      },
      {
        id: 4,
        name: 'CT planned but not taken'
      },
      {
        id: 5,
        name: 'Others (specify)'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    chemotherapyspecifyData: [
      {
        id: 1,
        name: 'Renal Insufficiency'
      },
      {
        id: 2,
        name: 'Other Medical Conditions'
      },
      {
        id: 3,
        name: 'Cost of CT'
      },
      {
        id: 4,
        name: 'Others(specify)'
      }
    ],
    cTData: [
      {
        id: 1,
        name: 'Induction'
      },
      {
        id: 2,
        name: 'Concurrent'
      },
      {
        id: 3,
        name: 'Adjuvant'
      },
      {
        id: 4,
        name: 'Combination of any the above'
      },
      {
        id: 5,
        name: 'Others (specify)'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    ctcyclesData: [
      {
        id: 1,
        name: 'Regimen',
        field: 'input'
      },
      {
        id: 2,
        name: 'Date(s)',
        field: 'datepicker'
      }
    ],
    rtalonewhoData: [
      {
        id: 1,
        name: 'RT not received',
        field: 'radio'
      },
      {
        id: 2,
        name: 'Complete reponse - No Evidence of Disease',
        field: 'radio'
      },
      {
        id: 3,
        name: 'Partial response',
        field: 'radio'
      },
      {
        id: 4,
        name: 'No change',
        field: 'radio'
      },
      {
        id: 5,
        name: 'Progressive disease',
        field: 'radio'
      },
      {
        id: 6,
        name: 'Post Surgical Adjuvant',
        field: 'radio'
      },
      {
        id: 7,
        name: 'Unknown',
        field: 'radio'
      },
    ],
    ctalonewhoData: [
      {
        id: 1,
        name: 'CT not received',
        field: 'radio'
      },
      {
        id: 2,
        name: 'Complete response - No Evidence of Disease',
        field: 'radio'
      },
      {
        id: 3,
        name: 'Partial response',
        field: 'radio'
      },
      {
        id: 4,
        name: 'No change',
        field: 'radio'
      },
      {
        id: 5,
        name: 'Progressive disease',
        field: 'radio'
      },
      {
        id: 6,
        name: 'Post surgical - Adjuvant',
        field: 'radio'
      },
      {
        id: 7,
        name: 'Unknown',
        field: 'radio'
      }
    ],
    rtctwhoData: [
      {
        id: 1,
        name: 'RT + CT not received',
        field: 'radio'
      },
      {
        id: 2,
        name: 'Complete response - No Evidence of Disease',
        field: 'radio'
      },
      {
        id: 3,
        name: 'Partial response',
        field: 'radio'
      },
      {
        id: 4,
        name: 'No Change',
        field: 'radio'
      },
      {
        id: 5,
        name: 'Progressive disease',
        field: 'radio'
      },
      {
        id: 6,
        name: 'Post surgical - Adjuvant',
        field: 'radio'
      },
      {
        id: 7,
        name: 'Unknown',
        field: 'radio'
      }
    ],
    complicationsTreatData: [
      {
        id: 1,
        name: 'No'
      },
      {
        id: 2,
        name: 'Yes'
      },
      {
        id: 3,
        name: 'Unknown'
      }
    ],
    diagnosticdetailsData: [
      {
        id: 1,
        name: 'Not Registered at RI'
      },
      {
        id: 2,
        name: 'Microscopically Confirmed'
      },
      {
        id: 3,
        name: 'Suspected (Microscopically / Radiologically)'
      },
      {
        id: 4,
        name: 'Unequivocal Clinical Diagnosis'
      },
      {
        id: 5,
        name: 'Suspected Clinically/To rule out Malignancy '
      },
      {
        id: 8,
        name: 'Others (specify)'
      },
      {
        id: 9,
        name: 'Unknown'
      }
    ],
    methodofDiagnosisData: [
      {
        id: 1,
        name: 'Clinical Only',
        childs: []
      },
      {
        id: 2,
        name: 'Microscopic',
        childs: [
          {
            id: 1,
            name: 'Histology of Primary'
          },
          {
            id: 2,
            name: 'Histology of Metastasis'
          },
          {
            id: 3,
            name: 'Autopsy with Histology'
          },
          {
            id: 4,
            name: 'Bone Marrow'
          },
          {
            id: 5,
            name: 'Blood Film'
          },
          {
            id: 6,
            name: 'Cytology of Primary'
          },
          {
            id: 7,
            name: 'Cytology of Metastasis'
          }
        ]
      },
      {
        id: 3,
        name: 'X-Ray/ Imaging Technique',
        childs: [
          {
            id: 1,
            name: 'X-Ray'
          },
          {
            id: 2,
            name: 'Isotopes'
          },
          {
            id: 3,
            name: 'Angiography'
          },
          {
            id: 4,
            name: 'Ultrasonogram specify test(s)'
          },
          {
            id: 5,
            name: 'All Others (specify)'
          }
        ]
      },
      {
        id: 4,
        name: 'DCO',
        childs: []
      },
      {
        id: 5,
        name: 'Others',
        childs: [
          {
            id: 1,
            name: 'Endoscopy'
          },
          {
            id: 2,
            name: 'Surgery or Autopsy without Histology'
          },
          {
            id: 3,
            name: 'Speciic Biochemical and/or Immunological Tests'
          },
          {
            id: 4,
            name: 'Others (specify)'
          }
        ]
      },
      {
        id: 6,
        name: 'Unknown',
        childs: []
      }
    ],
    methodfollowUpData: [
      {
        // id: 0,
        id: 'No Follow Up',
        name: 'No Follow-Up'
      },
      {
        // id: 1,
        id: 'Hospital visit',
        name: 'Hospital visit'
      },
      {
        // id: 2,
        id: 'By Post',
        name: 'By Post'
      },
      {
        // id: 3,
        id: 'Through Telephone',
        name: 'Through telephone'
      },
      {
        // id: 4,
        id: 'Home Visit',
        name: 'Home visit'
      },
      {
        // id: 8,
        id: 'Others',
        name: 'Others (specify)'
      },
      {
        // id: 9,
        id: 'Unknown',
        name: 'Unknown'
      }
    ],
    visitstatusData: [
      {
        id: 1,
        name: 'Alive'
      },
      {
        id: 3,
        name: 'Dead'
      },
      {
        id: 9,
        name: 'Unknown'
      }
    ],
    diseasestatusData: [
      {
        // id: 1,
        id: 'No Evidence of Disease',
        name: 'No Evidence of Disease'
      },
      {
        // id: 2,
        id: 'Residual Disease Only',
        name: 'Residual disease only'
      },
      {
        // id: 3,
        id: 'Local Recurrence',
        name: 'Local recurrence'
      },
      {
        // id: 4,
        id: 'Regional/Nodal-Recurrence',
        name: 'Regional/Nodal recurrence'
      },
      {
        // id: 5,
        id: 'Distant Metastasis',
        name: 'Distant metastasis: specify site'
      },
      {
        // id: 6,
        id: 'Progressive Disease',
        name: 'Progressive Disease'
      },
      {
        // id: 9,
        id: 'Unknown',
        name: 'Unknown'
      }
    ],
    indicatediagnosisData: [
      {
        // id: 1,
        id: 'Histopathology',
        name: 'Histopathology'
      },
      {
        // id: 2,
        id: 'Cytopathology(Other than FNAC)',
        name: 'Cytopathology (Other than FNAC)'
      },
      {
        // id: 3,
        id: 'FNAC',
        name: 'FNAC'
      },
      {
        // id: 4,
        id: 'Bone-Marrow',
        name: 'Bone Marrow'
      },
      {
        // id: 5,
        id: 'Peripheral Smear',
        name: 'Peripheral Smear'
      },
      {
        // id: 6,
        id: 'Radiological',
        name: 'Radiological'
      },
      {
        // id: 7,
        id: 'Clinical',
        name: 'Clinical'
      },
      {
        // id: 8,
        id: 'Others',
        name: 'Others (specify)'
      },
      {
        // id: 9,
        id: 'Unknown',
        name: 'Unknown'
      }
    ],
    treatmentpresenceData: [
      {
        id: 0,
        name: 'No Treatment'
      },
      {
        id: 2,
        name: 'Yes, treatment given'
      },
      {
        id: 9,
        name: 'Unknown'
      }
    ],
    latecomplicationsData: [
      {
        id: 0,
        name: 'No'
      },
      {
        id: 2,
        name: 'Yes'
      },
      {
        id: 9,
        name: 'Unknown'
      }
    ],
    causeofDeathData: [
      {
        id: 1,
        name: 'As a result of cancer'
      },
      {
        id: 2,
        name: 'Most probably due to cancer'
      },
      {
        id: 3,
        name: 'Intercuurent death'
      },
      {
        id: 4,
        name: 'Treatment related death'
      },
      {
        id: 5,
        name: 'Others (specify)'
      },
      {
        id: 6,
        name: 'Unknown'
      }
    ],
    surgicalprocedureData: [
      {
        id: 1,
        name: 'Total (extra-fascial) Abdominal Hysterectomy (Type I)'
      },
      {
        id: 2,
        name: 'Pelvic Excenteration'
      },
      {
        id: 3,
        name: 'Modified Radical Hysterectomy (Type II)'
      },
      {
        id: 4,
        name: 'Others (specify)'
      },
      {
        id: 5,
        name: 'Radical Abdominal Hysterectomy (Type III)'
      },
      {
        id: 6,
        name: 'Unknown'
      },
      {
        id: 7,
        name: 'Extended Radical Hysterectomy (Type IV)'
      }
    ],
    drugcompctregData: [
      {
        id: 1,
        name: 'Single (specify)'
      },
      {
        id: 2,
        name: 'Two Drug (specify)'
      },
      {
        id: 3,
        name: 'More than two drug (specify)'
      },
      {
        id: 4,
        name: 'Others (specify)'
      },
      {
        id: 5,
        name: 'Unknown'
      }
    ],
    sizeCervixData: [
      {
        id: 1,
        name: 'NA*'
      },
      {
        id: 2,
        name: 'No Viable Tumor'
      },
      {
        id: 3,
        name: 'Tumor Present (Size NK)'
      },
      {
        id: 4,
        name: 'Tumor < 2cm'
      },
      {
        id: 5,
        name: 'Tumor 2 - 4 cm'
      },
      {
        id: 6,
        name: 'Tumor 4 - 6 cm'
      },
      {
        id: 7,
        name: 'Tumor > 6 cm'
      },
      {
        id: 8,
        name: 'NK**'
      }
    ],
    thicknesscervicalData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: '< 1/3 / in-situ'
      },
      {
        id: 3,
        name: '< 1/2'
      },
      {
        id: 4,
        name: '= 2/3'
      },
      {
        id: 5,
        name: 'Full Thickness'
      },
      {
        id: 6,
        name: 'NK'
      }
    ],
    involvementUterusData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Involved'
      },
      {
        id: 4,
        name: 'Endocervix extn.'
      },
      {
        id: 5,
        name: 'NK'
      }
    ],
    thicknessUterineData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Endometrium'
      },
      {
        id: 3,
        name: 'Myometrium'
      },
      {
        id: 4,
        name: 'Serosa + ve'
      },
      {
        id: 5,
        name: 'NK'
      }
    ],
    involvmentvaginaData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Negative'
      },
      {
        id: 3,
        name: 'Positive'
      },
      {
        id: 4,
        name: 'Edge Close / +ve'
      },
      {
        id: 5,
        name: 'NK'
      }
    ],
    involvmentparametriumData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Negative'
      },
      {
        id: 3,
        name: 'Positive'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    tumoremboliData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'No'
      },
      {
        id: 3,
        name: 'Yes'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    involovementovariesData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Involved'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    involovementregionalData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Involved (Metastatic)'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    siteinvolvednodeData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'IIio-obtur'
      },
      {
        id: 4,
        name: 'IIiac node'
      },
      {
        id: 5,
        name: 'Obturator'
      },
      {
        id: 6,
        name: 'Pre-Sacral'
      },
      {
        id: 7,
        name: 'Paracervical'
      },
      {
        id: 8,
        name: 'Parametrial'
      },
      {
        id: 9,
        name: 'NK'
      }
    ],
    paraaorticnodeData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Involved (Metastatic)'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    lateralitynodeData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Unilateral (R / L)'
      },
      {
        id: 4,
        name: 'Bilateral'
      },
      {
        id: 5,
        name: 'NK'
      }
    ],
    nopositivenodeData: [
      {
        id: 1,
        name: '0'
      },
      {
        id: 2,
        name: '1'
      },
      {
        id: 3,
        name: '2'
      },
      {
        id: 4,
        name: '3'
      },
      {
        id: 5,
        name: '4'
      },
      {
        id: 6,
        name: '>4'
      },
      {
        id: 7,
        name: 'NK'
      }
    ],
    tumourgradeData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Grade I'
      },
      {
        id: 3,
        name: 'Grade II'
      },
      {
        id: 4,
        name: 'Grade III'
      },
      {
        id: 5,
        name: 'Grade IV'
      },
      {
        id: 6,
        name: 'NK'
      }
    ],
    lymphovascularspaceData: [
      {
        id: 1,
        name: 'NA'
      },
      {
        id: 2,
        name: 'Not involved'
      },
      {
        id: 3,
        name: 'Involved'
      },
      {
        id: 4,
        name: 'NK'
      }
    ],
    treatMentFilter: [
      {
        id: 0,
        name: 'No'
      },
      {
        id: 2,
        name: 'Yes'
      },
      {
        id: 9,
        name: 'Unknown'
      }
    ],
    filter: [
      {
        id: 0,
        name: 'No'
      },
      {
        id: 1,
        name: 'Yes'
      }
    ],
    toolbarUI: {
      'commonSearch': {
        'placeHolder': 'Search',
        'showLabel': false,
        'iconButton': null,
        'showIcon': 'search',
        'iconTheme': 'outline',
        'buttonText': null,
        'justify': 'left'
      },
      'customDatepicker': {
        'showPicker': false
      },
      'pagination': {
        'totalPages': null, // total number of data items
        'pageIndex': 1, // current page number，double binding
        'pageSize': 10, // number of data items per page, double binding
        'isSimple': false, // whether to use simple mode,
        'showPageNumbers': [
          { 'value': 10, 'label': '10 Items/Page' },
          { 'value': 20, 'label': '20 Items/Page' },
          { 'value': 50, 'label': '50 Items/Page' },
          { 'value': 100, 'label': '100 Items/Page' }
        ],
        'showSizeChanger': true,
        'justify': 'center'
      },
      'dropDownSearch': {
        'showDropDownSearch': false,
        'options': [
          { 'label': 'All', 'value': '' },
          { 'label': 'Booked', 'value': '1' },
          { 'label': 'Completed', 'value': '2' },
          { 'label': 'Missed', 'value': '3' },
          { 'label': 'Cancelled', 'value': '4' },
        ],
        'justify': 'end'
      },
      'viewOptions': false,

      
      'advanceOptions': {
        'showAdvanceSearch': false,
      },
      'sortBy': {
        'labelText': 'Sort By',
        'placeHolderText': 'Select Sort By',
        'options': [
          {'label': 'Default', 'value': 'id'},
          {'label': 'Name', 'value': 'first_name'},
          {'label': 'C.R. No.', 'value': 'cr_no'},
          {'label': 'R.T. No.', 'value': 'rt_no'},
        ], // {label: null, value: null}
        'defaultOrder': null,
        'defaultKey': null,
        'justify': 'end'
      }
    },
    availDoctset: [
      {
        id: 1,
        name: 'Geeta',
        age: 42,
        cr_number: 5562,
        rt_number: 4145,
        hbcr: 1245,
        gender: 'Female'
      },
      {
        id: 2,
        name: 'Reeta',
        age: 42,
        cr_number: 5562,
        rt_number: 4145,
        hbcr: 1245,
        gender: 'Female'
      }
    ],
    treatmentsFollowUpsToolbarUI: {
      'commonSearch': {
        'placeHolder': 'Search',
        'showLabel': false,
        'iconButton': null,
        'showIcon': 'search',
        'iconTheme': 'outline',
        'buttonText': null,
        'justify': 'left'
      },
      'customDatepicker': {
        'showPicker': false
      },
      'pagination': {
        'totalPages': null, // total number of data items
        'pageIndex': 1, // current page number，double binding
        'pageSize': 10, // number of data items per page, double binding
        'isSimple': false, // whether to use simple mode,
        'showPageNumbers': [
          { 'value': 10, 'label': '10 Items/Page' },
          { 'value': 20, 'label': '20 Items/Page' },
          { 'value': 50, 'label': '50 Items/Page' },
          { 'value': 100, 'label': '100 Items/Page' }
        ],
        'showSizeChanger': true,
        'justify': 'center'
      },
      'dropDownSearch': {
        'showDropDownSearch': false,
        'options': [
          { 'label': 'All', 'value': '' },
          { 'label': 'Booked', 'value': '1' },
          { 'label': 'Completed', 'value': '2' },
          { 'label': 'Missed', 'value': '3' },
          { 'label': 'Cancelled', 'value': '4' },
        ],
        'justify': 'end'
      },
      'viewOptions': false,
      'advanceOptions': {
        'showAdvanceSearch': false,
      }
    },
    treatmentsFollowUpsHeading: {
      columns: [
        { label: 'Doctor Name', width: '20%' },
        { label: 'Followup Method', width: '20%' },
        { label: 'Followup Treatment Status', width: '20%' },
        { label: 'Followup Due Date', width: '20%' },
        { label: 'Actual Followup Date', width: '20%' },
      ]
    },

    /**Treatment Planning **/

    stepTwoTableUI: {
      columns: [
        { label: 'Height (in cm)', width: '10%' },
        { label: 'Weight (in kg)', width: '10%' },
        { label: 'BSA (Body Surface Area) (m²)', width: '10%' },
        { label: 'Start Date', width: '20%' },
        { label: 'Status', width: '15%' },
        { label: 'Action', width: '20%' },
      ],
    },

    stepThreeTableUI: {
      columns: [
        { label: 'DRUGS & UNIT (mg/m²)', width: '20%' },
        { label: 'CALCULATED DOSE (mg)', width: '20%' },
        { label: 'Total Treatment Day', width: '20%' },
        { label: 'Cycle Type', width: '20%' },
        { label: '', width: '20%' },
      ],
    },
    ctTreatmentScheduleDays: [
      { 'label': 'Day Range & Day', 'value': '1' },
      { 'label': 'Daily', 'value': '2' },
      { 'label': 'Weekly', 'value': '3' },
    ],

    simuTreatmentFirstFormHeading: {
      columns:
        [
          { 'label': 'Stage', width: '15%' },
          { 'label': 'Start Date', width: '10%' },
          { 'label': 'Actual End Date', width: '15%' },
          { 'label': 'Total treatment Days', width: '15%' },
          { 'label': 'Minimum Interval', width: '10%' },
          { 'label': 'Status', width: '15%' },
          { 'label': 'Action', width: '5%' },
        ]
    },

    ctTrtmentActivityHeading: {
      columns:
        [
          { 'label': 'Cycle', width: '10%' },
          { 'label': 'Drugs & unit (Dose in mg)', width: '30%' },
          { 'label': 'Appointment date', width: '10%' },
          { 'label': 'Appointment status', width: '10%' },
          { 'label': 'Action', width: '8%' },
        ]
    },

   // trtPlanQuickPerforms: [],

    trtmentPlanDrawerWidth: "720",

    trtmentPlanOtherMedicins: [
      { key: "1", label: '50 ml 0.9% Sodium Chloride' },
      { key: "2", label: '100 ml 0.9% Sodium Chloride' },
      { key: "3", label: '150 ml 0.9% Sodium Chloride' },
      { key: "4", label: '200 ml 0.9% Sodium Chloride' },
      { key: "5", label: '250 ml 0.9% Sodium Chloride' },
      { key: "6", label: '300 ml 0.9% Sodium Chloride' },
      { key: "7", label: '400 ml 0.9% Sodium Chloride' },
      { key: "8", label: '500 ml 0.9% Sodium Chloride' },
      { key: "9", label: '50 ml 0.5% Dextrose' },
      { key: "10", label: '100 ml 0.5% Dextrose' },
      { key: "11", label: '150 ml 0.5% Dextrose' },
      { key: "12", label: '200 ml 0.5% Dextrose' },
      { key: "13", label: '250 ml 0.5% Dextrose' },
      { key: "14", label: '300 ml 0.5% Dextrose' },
      { key: "15", label: '400 ml 0.5% Dextrose' },
      { key: "16", label: '500 ml 0.5% Dextrose' },
      { key: "17", label: 'Oral' },
    ],
    complications: {
      'dataTable': {
        'columns': [
          {
            "name": "Nature of Complication(s)",
            "prop": "nature_of_complication",
            "required": true,
            "width": "20%",
            "type": "alphanumeric",
            "length": 100,
          },
          {
            "name": "Max Grade",
            "prop": "max_grade",
            "required": true,
            "width": "10%",
            "type": "alphanumeric",
            "length": 15
          },
          {
            "name": "Date of Onset",
            "prop": "onset_date",
            "required": true,
            "width": "20%",
            "editor": "datepicker",
            "editDisabled": true
          },
          {
            "name": "Resolved status",
            "prop": "resolved_status",
            "required": false,
            "width": "15%",
            "options": [{"label": "Yes", "value": "1"}, {"label": "No", "value": "0"}],
            "type": "number",
            "length": 1,
            "editor": "select",
          },
          {
            "name": "Date last seen",
            "prop": "last_seen_date",
            "required": false,
            "width": "20%",
            "editor": "datepicker"
          },
        ]
      }
    },
    dataType: {
      number: 'number',
      alphanumeric: 'alphanumeric',
      text: 'text',
      time: 'time',
      decimal: 'decimal'
    },
    drugComboHeading: [
      { 'label': 'Sr No.', width: '15%' },
      { 'label': 'Drugs', width: '30%' },
      { 'label': 'Note', width: '10%' },
      { 'label': 'Action', width: '5%' },
    ],

    /**Treatment Planning **/
  };
}
