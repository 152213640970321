import { Routes, RouterModule } from '@angular/router';
import { DoctorsComponent } from './doctors.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';
import { FollowupAfterTreatmentComponent } from './followup-after-treatment/followup-after-treatment.component';

export const routes: Routes = [
  {
    path: 'doctors',
    component: DoctorsComponent,
    children: [
      { path: '', redirectTo: 'today-slots', pathMatch: 'full' },
      { path: 'patient-list', loadChildren: './patient-list/patient-list.module#PatientsListModule' },
      { path: 'today-slots', loadChildren: './today-slots/today-slots.module#TodaySlotsModule' },
      { path: 'manage-patients', loadChildren: './mp/mp.module#MpModule' },
      { path: 'dashboard', loadChildren: '../base/dashboard/dashboard.module#DashboardModule' },
      { path: 'social-work-desk', loadChildren: './social-work-desk/social-work-desk.module#SocialWorkDeskModule' },
      { path: 'reports/patient-report', loadChildren: '../base/reports/reports.module#ReportsModule' },
      { path: 'manage-opd-patients', loadChildren: './opd/opd.module#OpdModule' },
      { path: 'manage-ct-patients', loadChildren: './mn-ct-patients/mn-ct-patients.module#MnCtPatientsModule' },
      { path: 'manage-rt-ct-patients', loadChildren: './mn-rt-ct-patients/mn-rt-ct-patients.module#MnRtCtPatientsModule' },
      { path: 'manage-other-patients', loadChildren: './mn-other-patients/mn-other-patients.module#MnOtherPatientsModule' },
      { path: 'old-patient-records', loadChildren: './old-patient-records/old-patient-records.module#OldPatientRecordsModule' },
      // { path: 'follow-up-after-treatment', loadChildren: './followup-after-treatment/followup-after-treatment.module#FollowupAfterTreatmentModule' },
      { path: 'follow-up-after-treatment', component:FollowupAfterTreatmentComponent},
      { path: 'my-appointments', loadChildren: './appointment-calender/appointment-calender.module#AppointmentCalenderModule' },
      { path: 'rt-appointments', loadChildren: './rt-appointments/rt-appointments.module#RtAppointmentsModule' },
      { path: 'bt-appointments', loadChildren: './bt-appointments/bt-appointments.module#BtAppointmentsModule' },
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
