import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.scss']
})
export class DoctorsComponent implements OnInit {

  isCollapsed: boolean = true;
  showDebugerPanel: boolean = false;
  setLayout: string = 'overpage';

  constructor() { }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }

  ngOnInit() {
  }
}
