import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DefaultConfig } from './default-config';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import moment from 'moment';
import { DATE_FORMAT } from '../../../shared/api/base.url';



@Component({
  selector: 'data-toolbar-ui',
  templateUrl: './data-toolbar-ui.component.html',
  styleUrls: ['./data-toolbar-ui.component.scss']
})
export class DataToolBarUiComponent implements OnInit {

  // No Use Of Code //
  @Input() pageIndex: number;
  @Input() dataTotal: number;
  @Input() showSizeChanger: boolean = true;
  @Input() quickActions: any;
  @Input() actionsBtns: any;
  @Input() searchOption: boolean = false;
  @Input() sortListOfOption: any;
  @Input() dateCalendar: boolean = false;
  @Input() perPageShow: any;
  @Input() filterVal: any;
  @Input() loadStatus: boolean = false;
  // @Input() currentDate:  any;
  // No Use Of Code End //
  @Input() config: any;
  @Output() pageIndexChange: EventEmitter<Number> = new EventEmitter();
  @Output() pageSizeChange: EventEmitter<Number> = new EventEmitter();
  @Output() advanceSearch: EventEmitter<Number> = new EventEmitter();
  @Output() pageViewChange: EventEmitter<String> = new EventEmitter();
  @Output() commonSearchAction: EventEmitter<String> = new EventEmitter();
  @Output() currentDateSelected: EventEmitter<String> = new EventEmitter();
  @Output() previousDateSelected: EventEmitter<String> = new EventEmitter();
  @Output() nextDateSelected: EventEmitter<String> = new EventEmitter();
  @Output() dropDownSearch: EventEmitter<String> = new EventEmitter();
  @Output() sortData: EventEmitter<any> = new EventEmitter();
  @Output() switchFilter: EventEmitter<any> = new EventEmitter();
  @Output() dropDownSearchMulti: EventEmitter<String> = new EventEmitter();

  nzRowSpan: number = 24;
  orderSort: boolean = false;
  visibleSearchForm = false;
  adSearchForm: FormGroup;
  paginationForm: FormGroup;
  dropDownSearchForm: FormGroup;
  dropDownSearchFormMulti: FormGroup;
  ///datepicker///////
  disableBtn: boolean = false;
  todayShowLabel: boolean = true;
  yesturdayShowLabel: boolean = false;
  tomorrowShowLabel: boolean = false;
  currentDate: any = null;
  disableCurrentDate = new Date();
  todayData: any = null;
  previousDate: any = null;
  nextDate: any = null;
  nextDateSelect: any = null;
  _today: any = moment().clone().startOf('day').format('YYYY-MM-DD');
  _yesterday: any = moment().clone().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
  _tomorrow: any = moment().clone().add(1, 'days').startOf('day').format('YYYY-MM-DD');
  externalBtn: boolean = false;
  clearValues: boolean = false;
  setFormValue: any = [];
  advanceSearchData: any = [];
  clearData: boolean;
  advanceSearchBtn: boolean = false;
  date_format = DATE_FORMAT;
  // Sort Column Name
  // sortColumnName: string = '';
  // Filter Fields
  sortFieldsName: any = [];
  // Over All inside Data-toolbar-ui
  @Output() filterByFields: EventEmitter<any> = new EventEmitter();

  disabledDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.disableCurrentDate) > 0;
  }

  disabledOldDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.disableCurrentDate) < 0;
  }

  ///datepicker///

  constructor(private fb: FormBuilder) {
    this.currentDate = this.DateTimeFormat();
  }

  ngOnInit() {
    this.config = Object.assign(Object.assign({}, DefaultConfig.config), this.config);
    if (
      this.config.advanceOptions &&
      this.config.advanceOptions.showAdvanceSearch === true &&
      this.config.advanceOptions.formConfigKey &&
      this.config.advanceOptions.formConfigKey.trim() != ''
    ) {
      this.adSearchForm = this.fb.group(this.getFieldConfig(this.config.formConfig[this.config.advanceOptions.formConfigKey]));
    }

    this.paginationForm = this.fb.group({
      pageNumber: [this.config.pagination.pageSize]
    });

    if (this.config && this.config.dropDownSearch && this.config.dropDownSearch.options) {
      this.dropDownSearchForm = this.fb.group({
        searchValue: [this.config.dropDownSearch.options]
      });
      this.config.dropDownSearch.value = '';
      if (this.config.dropDownSearch.value) {
        this.dropDownSearchForm.controls.searchValue.setValue(this.config.dropDownSearch.value);
      } else if (this.dropDownSearchForm.value.searchValue.length > 0) {
        this.dropDownSearchForm.controls.searchValue.setValue(this.config.dropDownSearch.options[0].value);
        this.config.dropDownSearch.value = this.config.dropDownSearch.options[0].value;
      }
    }

    if (this.filterVal && this.filterVal.trim() !== '') {
      this.dropDownSearchForm.controls['searchValue'].setValue(this.filterVal);
    }

    // search multi
    if (this.config && this.config.dropDownSearchMulti && this.config.dropDownSearchMulti.options) {
      this.dropDownSearchFormMulti = this.fb.group({
        searchValueMulti: [this.config.dropDownSearchMulti.options]
      });
      this.config.dropDownSearchMulti.value = '';
      if (this.config.dropDownSearchMulti.value) {
        this.dropDownSearchFormMulti.controls.searchValueMulti.setValue(this.config.dropDownSearchMulti.value);
      } else if (this.dropDownSearchFormMulti.value.searchValueMulti.length > 0) {
        this.dropDownSearchFormMulti.controls.searchValueMulti.setValue(this.config.dropDownSearch.options[0].value);
        this.config.dropDownSearchMulti.value = this.config.dropDownSearchMulti.options[0].value;
      }
    }

    if (this.filterVal && this.filterVal.trim() !== '') {
      this.dropDownSearchFormMulti.controls['searchValueMulti'].setValue(this.filterVal);
    }

    /////////////

  }


  getFieldConfig(fieldsConfig): any {
    let fields = {};

    fieldsConfig.forEach(element => {
      let fieldData = [null];
      let fieldValidator = [];

      if (element.validator && element.validator.length > 0) {
        element.validator.forEach(ele => {
          if (ele.required)
            fieldValidator.push(Validators.required);
          if (ele.regex)
            fieldValidator.push(Validators.pattern(ele.regex));
        });
      }

      if (fieldValidator.length > 0) {
        fieldData.push(fieldValidator);
      }

      fields[element.name] = fieldData;
    });

    return fields;
  }

  onPageIndexChange(evt) {
    this.pageIndexChange.emit(evt);
  }

  onPageSizeChange(evt) {
    this.pageSizeChange.emit(evt);
  }

  onPageViewChange(evt) {
    this.pageViewChange.emit(evt);
  }

  onDropDownSearch(evt) {
    this.dropDownSearch.emit(evt);
  }

  onDropDownSearchMulti(evt) {
    this.dropDownSearchMulti.emit(evt);
  }


  onSwitchFilter(evt) {
    this.switchFilter.emit(evt);
  }

  sortOrder(event: any, sortKey: number): void {
    if (sortKey === 1) {
      this.config.sortBy.isAssending = !this.config.sortBy.isAssending;
    }

    this.sortData.emit({
      sortColumnName: this.config.sortBy.defaultKey,
      orderBy: (this.config.sortBy.isAssending ? 'ASC' : 'DESC')
    });
  }

  openSearchForm() {
    this.visibleSearchForm = true;
    this.externalBtn = false;
    this.clearValues = false;
  }

  onSave() {
    this.externalBtn = (!this.externalBtn) ? true : false;
    this.clearValues = (this.clearValues) ? true : false;
    this.visibleSearchForm = false;
    this.clearData = false;
    this.advanceSearchBtn = true;
  }

  onSearch(evt) {
    this.advanceSearchData = evt;
    if (this.advanceSearchData && this.advanceSearchData.data) {
      this.advanceSearch.emit(this.advanceSearchData);
    }
    // if (
    //   this.config.advanceOptions &&
    //   this.config.advanceOptions.showAdvanceSearch === true &&
    //   this.config.advanceOptions.formConfigKey &&
    //   this.config.advanceOptions.formConfigKey.trim() != '' &&
    //   this.adSearchForm.valid
    // ) {
    //   this.advanceSearch.emit(this.adSearchForm.value);
    // }
  }

  onReset() {
    this.clearValues = true;
    if (!this.advanceSearchData.data && !this.clearData) {
      this.advanceSearch.emit(this.advanceSearchData);
      this.clearData = true;
    }
    this.advanceSearchBtn = false;
  }

  closeSearchForm() {
    this.visibleSearchForm = false;
  }

  onCommonSearch(evt) {
    this.commonSearchAction.emit(evt.target.value);
  }

  ngOnChanges(changes) {
    this.nzRowSpan = 8;
  }

  onChooseDate(evt) {
    this.disableBtn = true;
    this.yesturdayShowLabel = false;
    this.todayShowLabel = false;
    this.tomorrowShowLabel = false;
    if (moment(evt).format('YYYY-MM-DD') == this._today) {
      this.todayData = moment(evt).format('YYYY-MM-DD');
      this.todayShowLabel = true;
      this.disableBtn = false;
    } else if (moment(evt).format('YYYY-MM-DD') == this._yesterday) {
      this.todayData = moment(evt).format('YYYY-MM-DD');
      this.yesturdayShowLabel = true;
    } else if (moment(evt).format('YYYY-MM-DD') == this._tomorrow) {
      this.todayData = moment(evt).format('YYYY-MM-DD');
      this.tomorrowShowLabel = true;
    }
    this.currentDateSelected.emit(this.currentDate);
  }

  onPreviousDate(allEnabled: boolean) {
    if (allEnabled) {
      this.yesturdayShowLabel = false;
      this.todayShowLabel = false;
      this.tomorrowShowLabel = false;
      this.currentDate = moment(this.currentDate).subtract(1, 'd').format('YYYY-MM-DD');
      if (this.currentDate == this._today) {
        this.todayShowLabel = true;
      } else if (this.currentDate == this._yesterday) {
        this.yesturdayShowLabel = true;
      } else if (this.currentDate == this._tomorrow) {
        this.tomorrowShowLabel = true;
      }
      this.previousDateSelected.emit(this.currentDate);
    } else {
      this.yesturdayShowLabel = this.isYesterday(moment(this.currentDate).subtract(1, 'd'));
      this.currentDate = moment(this.currentDate).subtract(1, 'd').format('YYYY-MM-DD');
      this.disableBtn = true;
      this.todayShowLabel = false;

      this.previousDateSelected.emit(this.currentDate);
    }
  }

  onNextDate(allEnabled: boolean) {
    if (allEnabled) {
      this.yesturdayShowLabel = false;
      this.todayShowLabel = false;
      this.tomorrowShowLabel = false;
      this.currentDate = moment(this.currentDate).add(1, 'd').format('YYYY-MM-DD');
      if (this.currentDate == this._today) {
        this.todayShowLabel = true;
      } else if (this.currentDate == this._yesterday) {
        this.yesturdayShowLabel = true;
      } else if (this.currentDate == this._tomorrow) {
        this.tomorrowShowLabel = true;
      }
      this.nextDateSelected.emit(this.currentDate);
    } else {
      if (moment(new Date()).format('YYYY-MM-DD') == this.currentDate) {
        this.disableBtn = false;
        this.todayShowLabel = true;
      } else {
        this.yesturdayShowLabel = false;
        this.todayShowLabel = false;
        this.currentDate = moment(this.currentDate).add(1, 'd').format('YYYY-MM-DD');
        this.disableBtn = true;
        if (this.currentDate == this._today) {
          // this.nextDateSelect = this.currentDate
          this.todayShowLabel = true;
        } else if (this.currentDate == this._yesterday) {
          // this.nextDateSelect = this.currentDate
          this.yesturdayShowLabel = true;
        } else if (this.currentDate == this._tomorrow) {
          this.tomorrowShowLabel = true;
        }
        this.nextDateSelected.emit(this.currentDate);
      }
    }
  }

  DateTimeFormat = () => {
    let now = new Date();
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
    let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
    return year + "-" + month + "-" + day;
  }

  isToday(momentDate) {
    return momentDate.isSame(this._today, 'd');
  }

  isYesterday(momentDate) {
    return momentDate.isSame(this._yesterday, 'd');
  }

  // Add method
  onSetFieldsName(event: any): void {
    // console.log(this.sortFieldsName);
    this.filterByFields.emit({
      fields: this.sortFieldsName
    });
  }
}
