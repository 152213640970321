import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { RoutingComponent } from './app.routing';
import { AppComponent } from './app.component';
// Kolkov Angular Editor
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HrModule } from './hr/hr.module';
import { BaseModule } from './base/base.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { MailModule } from './mail/mail.module';
import { FrontDeskUserModule } from './front-desk-user/front-desk-user.module';
import { SocialWorkerModule } from './social-worker/social-worker.module';
import { DayCareUserModule } from './day-care-user/day-care-user.module';
import { RtModule } from './rt/rt.module';
import { DoctorsModule } from './doctors/doctors.module';
import { PipeModule } from './shared/pipe/pipe.module';
/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

// Loopback integration
import { SDKBrowserModule, LoopBackConfig } from './shared/lib/index';
import { OMSSdkModule } from './shared/api/index';
import { BASE_URL, API_VERSION } from './shared/api/base.url';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavUIModule } from './shared/components/nav-ui/nav-ui.module';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
// error pages
import { Page404Component } from './base/pages/page404/page404.component';
import { Page505Component } from './base/pages/page505/page505.component';
import { AccessDeniedComponent } from './base/pages/access-denied/access-denied.component';
import { LockScreenComponent } from './base/pages/lock-screen/lock-screen.component';
import { PagesComponent } from './base/pages/pages.component';
import { DycFormsModule } from './shared/components/dyc-forms/dyc-forms.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DataFormatterService } from './helpers/data-formatter.service';
import { UtilsService } from './helpers/utils.service';
// import { PipeModule } from './shared/pipe/pipe.module';
// import { CustomGlobalFilterPipe } from './shared/pipe/custom-global-filter.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { CustomSelectUiComponent } from './shared/components/custom-select-ui/custom-select-ui.component';
// import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { AuthSystemModule } from './shared/components/auth-system/auth-system.module';
// import { ClinicalHistoryFormComponent } from './shared/components/clinical-history-form/clinical-history-form.component';
// import { PatientFollowupsAfterTreatmentComponent } from './shared/components/patient-followups-after-treatment/patient-followups-after-treatment.component';
// import { PatientRemarksComponent } from './shared/components/patient-remarks/patient-remarks.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    Page404Component,
    Page505Component,
    AccessDeniedComponent,
    LockScreenComponent,
    PagesComponent,
    // ClinicalHistoryFormComponent,
    // PatientFollowupsAfterTreatmentComponent,
    // PatientRemarksComponent,
    // CustomSelectUiComponent,
    // BookAppointmentComponent,
    // CustomFilterPipe,
    // CustomGlobalFilterPipe
  ],
  imports: [
    PipeModule,
    BrowserModule,
    RoutingComponent,
    RouterModule,
    BrowserAnimationsModule,
    NgZorroAntdModule.forRoot(),
    SDKBrowserModule.forRoot(),
    OMSSdkModule.forRoot(),
    AngularEditorModule,
    BaseModule,
    FormsModule, 
    ReactiveFormsModule,
    HrModule,
    NavUIModule,
    MailModule,
    FrontDeskUserModule,
    DoctorsModule,
    DycFormsModule,
    SocialWorkerModule,
    DayCareUserModule,
    RtModule,
    DashboardModule,
    InfiniteScrollModule,
    AuthSystemModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    DataFormatterService,
    UtilsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    // Loopback setup
    LoopBackConfig.setBaseURL(BASE_URL);
    LoopBackConfig.setApiVersion(API_VERSION);
  }
}
