import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DycFormsComponent } from './dyc-forms.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CKEditorModule } from 'ngx-ckeditor';
import { DycFormsComboUiComponent } from './templates/dyc-forms-combo-ui/dyc-forms-combo-ui.component';
import { DyncTemplateDirective } from '../../../directives/dync-template.directive';
import { DycFormService } from '../../api/frontend/service/dyc-form.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroAntdModule,
    CKEditorModule
  ],
  providers: [DycFormService],
  declarations: [DycFormsComponent, DycFormsComboUiComponent, DyncTemplateDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [DycFormsComponent, DycFormsComboUiComponent],
  entryComponents: [DycFormsComboUiComponent]
})
export class DycFormsModule { }
