import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { BaseComponent } from './base/base.component';
import { FrontDeskUserComponent } from './front-desk-user/front-desk-user.component';
import { RtComponent } from './rt/rt.component';
import { SocialWorkerComponent } from './social-worker/social-worker.component';
import { DayCareUserComponent } from './day-care-user/day-care-user.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AccessDeniedComponent } from './base/pages/access-denied/access-denied.component';
import { HrComponent } from './hr/hr.component';

export const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset-password/:encKey/:dataToken', component: ResetPasswordComponent },
  { path: 'app', component: BaseComponent },
  { path: 'front-desk-user', component: FrontDeskUserComponent },
  { path: 'social-worker', component: SocialWorkerComponent },
  { path: 'doctors', component: DoctorsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: 'hr', component: HrComponent },
  { path: 'day-care', component: DayCareUserComponent },
  { path: 'rt', component: RtComponent },
];

export const RoutingComponent: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
