export class Patient {
	constructor(
		public fname: string
	) {}
}

export class LoginConfig {
	constructor(
		public login_captcha: boolean = true,
		public login_finger_auth: boolean = true,
		public otp_verification: boolean = true,
	) {}
}

export class SelectOption {
	constructor (
		public key: string,
		public label: string,
		public disabled: boolean = false,
		public data: any = null
	) {}
}

export class OptionsMaster {
	constructor (
		public bolus_thickness: Array<SelectOption> = [],
		public bolus: Array<SelectOption> = [],
		public brachytherapy: Array<SelectOption> = [],
		public category: Array<SelectOption> = [],
		public country: Array<SelectOption> = [],
		public country_phone_codes: Array<SelectOption> = [],
		public cultural_group: Array<SelectOption> = [],
		public diagnosis: Array<SelectOption> = [],
		public education: Array<SelectOption> = [],
		public machine: Array<SelectOption> = [],
		public marital_status: Array<SelectOption> = [],
		public mother_tongue: Array<SelectOption> = [],
		public mri: Array<SelectOption> = [],
		public payment: Array<SelectOption> = [],
		public pet: Array<SelectOption> = [],
		public planning: Array<SelectOption> = [],
		public position: Array<SelectOption> = [],
		public protocol: Array<SelectOption> = [],
		public region: Array<SelectOption> = [],
		public relation: Array<SelectOption> = [],
		public remarkPrescriptions: Array<SelectOption> = [],
		public remarkTitles: Array<SelectOption> = [],
		public risk: Array<SelectOption> = [],
		public rtp: Array<SelectOption> = [],
		public schedules: Array<SelectOption> = [],
		public sites: Array<SelectOption> = [],
		public subTechniques: Array<SelectOption> = [],
		public techniques: Array<SelectOption> = [],
		public treatment_unit: Array<SelectOption> = [],
		public archive_sections: Array<SelectOption> = [],
		public morphology_grade: Array<SelectOption> = [],
		public icd_o_3_topography: Array<SelectOption> = [],
		public icd_o_3_morphology: Array<SelectOption> = [],
		public icd_o_3_secondary_site: Array<SelectOption> = [],
		public icd_o_3_metastasis: Array<SelectOption> = [],
		public icd_10_tumour: Array<SelectOption> = [],
		public discharge_types: Array<SelectOption> = [],
		public discharge_suggestion_types: Array<SelectOption> = [],
	) {}
}

export class RTPlanning {
	constructor(public TransName: string,
		public AccountNumber: string,
		public TransDate: Date,
		public Description: string,
		public Amount: number,
		public IsDebit: boolean,
		public Balance: number,
		public ActualName: string) {
	}
}

export class ApplicationSetting {
	constructor(
		public logo_image: string = null,
		public favicon_image: string = null,
		public menu_image: string = null,
		public watermark_image: string = null,
		public project_title: string = null,
		public project_full_name: string = null,
	) {}
}

export class FileUpload {
	constructor(
		public uid: number = null,
		public name: string = null,
		public status: string = null,
		public url: string = null,
		public thumbUrl: string = null,
		public data: any = null
	) {}
}

export class FileSettings {
	constructor(
		public key:string = null,
		public title:string = null,
		public max_file_size:number = null,
		public min_size_unit:string = null,
		public min_file_size:number = null,
		public max_size_unit:string = null,
		public total_file_size:number = null,
		public total_file_size_unit:string = null,
		public permitted_file_types:Array<string> = [],
	) {}
}

export class SelectedTab {
	constructor(
		public section:string = null,
		public index:number = null
	) {}
}