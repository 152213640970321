/* tslint:disable */

declare var Object: any;
export class MasterEntry {  
  constructor(data?: MasterEntry) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `MasterEntry`.
   */
  public static getModelName() {
    return "MasterEntry";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of MasterEntry for dynamic purposes.
  **/
  public static factory(data: MasterEntry): MasterEntry{
    return new MasterEntry(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'MasterEntry',
      plural: 'MasterEntries',      
      path: 'master-entries',      
    }
  }
}
