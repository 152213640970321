import { Routes, RouterModule } from '@angular/router';
import { SocialWorkerComponent } from './social-worker.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'social-worker',
    component: SocialWorkerComponent,
    children: [
      { path: '', redirectTo: 'donation-request', pathMatch: 'full' },
      { path: 'donation-request', loadChildren: './view-donation-request/view-donation-request.module#ViewDonationRequestModule' },
      { path: 'missed-rt-appointment',
      loadChildren: './view-missed-rt-appointment/view-missed-rt-appointment.module#ViewMissedRtAppointmentModule' },
      { path: 'missed-ct-appointment',
      loadChildren: './view-missed-ct-appointment/view-missed-ct-appointment.module#ViewMissedCtAppointmentModule' },
      { path: 'missed-opd-appointment',
      loadChildren: './view-missed-opd-appointment/view-missed-opd-appointment.module#ViewMissedOpdAppointmentModule' },
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
