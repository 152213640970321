import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-care-user',
  templateUrl: './day-care-user.component.html',
  styleUrls: ['./day-care-user.component.scss']
})
export class DayCareUserComponent implements OnInit {

  isCollapsed: boolean = true;
  showDebugerPanel: boolean = false;
  setLayout: string = 'overpage';

  constructor() { }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }
  
  ngOnInit() {
  }
  
}
