/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
// import { NgModule, ModuleWithProviders } from '@angular/core';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable, Subject } from 'rxjs';

/**
 * Api services for the `ImportExport` model.
 */
@Injectable()
export class ImportExportService extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }

  // public importExportDataInfo<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
  //   return this.request('POST', [
  //     LoopBackConfig.getPath(),
  //     LoopBackConfig.getApiVersion(),
  //     this.model.getModelDefinition().path,
  //     'import'
  //   ].join('/'), { id, section }, null, data, null, customHeaders);
  // }

  /**
   * @method create
   * @author Jonathan Casarrubias <t: johncasarrubias, gh: mean-expert-official>
   * @license MIT
   * @param {T} data Generic data type
   * @return {Observable<T>}
   * @description
   * Generic create method
   */
  public importExportDataInfo<T>(data: T, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'import'
    ].join('/'), undefined, undefined, { data }, null, customHeaders);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `ImportExport`.
   */
  public getModelName() {
    return "ImportExport";
  }
}
