/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable } from 'rxjs';

/**
 * Api services for the `Leave` model.
 */
@Injectable()
export class CoreAPIService extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }
  // start search form value //
  appointment_date: any;
  site_id: string;
  patient_id: string;
  employee_id: string;
  // end search form value //


  public api<T>(method: string, path: string, routeParams: any, queryParams: any = undefined, getParams: any = undefined, postParams: any = undefined, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {

    let _urlParams: any = undefined;
    let _configPath: any = LoopBackConfig.getPath();
    let _apiVersion: any = LoopBackConfig.getApiVersion();
    let _pathArray: any = path.split('/');
    let _path: string = _pathArray[0]; delete (_pathArray[0]);
    let _uri: string = (_pathArray.join('/')).substr(1);
    if (getParams) {
      _urlParams = getParams;
    }

    if (filter) {
      if (_urlParams === undefined) {
        _urlParams = { "filter": filter };
      } else {
        _urlParams["filter"] = filter;
      }
    }
    if (queryParams) {
      if (_urlParams === undefined) {
        _urlParams = { q: queryParams };
      } else {
        _urlParams['q'] = queryParams;
      }
    }

    return this.request(
      method,
      [_configPath, _apiVersion, _path, _uri].join('/'),
      routeParams,
      _urlParams,
      postParams,
      null,
      customHeaders
    );
  }

  checkSmileyValidation(text) {
    let rex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;
    let returnValue = rex.test(String(text).toLowerCase());
    return returnValue;
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `Model`.
   */
  public getModelName() {
    return "CoreService";
  }
}
