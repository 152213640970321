'use strict';

export class DefaultConfig {
  public static config = {
    'customDatepicker': {
      'showPicker': false
    },
    'dropDownSearch': {
      'showDropDownSearch': false
    },
    'dropDownSearchMulti': {
      'showDropDownSearch': false
    },
    'commonSearch': {
      'placeHolder': 'Search',
      'showLabel': false,
      'iconButton': null,
      'showIcon': null,
      'buttonText': null,
      'justify': 'left'
    },
    'pagination': {
      'totalPages': null, // total number of data items
      'pageIndex': null, // current page number，double binding
      'pageSize': null, // number of data items per page, double binding
      'isSimple': false, // whether to use simple mode,
      'showPageNumbers': [], // {label: null, value: null}
      'showSizeChanger': true,
      'justify': 'center'
    },
    'advanceOptions': {
      'options': [], // {type: null, shape: null, title: null, placement: null, buttonIcon: null, buttonText: null, class: null}
      'formConfigKey': '',
      'showAdvanceSearch': false,
      'width': 320
    },
    'sortBy': {
      'labelText': 'Sort By',
      'placeHolderText': 'Select Sort By',
      'options': [], // {label: null, value: null}
      'isAssending': null,
      'defaultKey': null,
      'justify': 'end'
    },
    'viewOptions': {
      'options': [], // {type: null, shape: null, title: null, placement: null, buttonIcon: null, buttonText: null, class: null}
      'defaultView': null,
      'justify': 'end'
    },
    'formConfig': {
      'userAdvanceSearch': [
        { 'name': 'empCode', 'type': 'text', 'validator': null, 'label': 'Code' },
        { 'name': 'empName', 'type': 'text', 'validator': null, 'label': 'Name' },
        { 'name': 'empMail', 'type': 'text', 'validator': null, 'label': 'E-mail' },
        { 'name': 'empPhone', 'type': 'text', 'validator': null, 'label': 'Phone' },
      ]
    }
  }
}