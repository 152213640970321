/* tslint:disable */

declare var Object: any;
export class Leave {  
  constructor(data?: Leave) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Leave`.
   */
  public static getModelName() {
    return "Leave";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Leave for dynamic purposes.
  **/
  public static factory(data: Leave): Leave{
    return new Leave(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Leave',
      plural: 'leaves',      
      path: 'leaves',      
    }
  }
}
