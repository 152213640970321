import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzMessageService, UploadFile } from 'ng-zorro-antd';
import { NzModalService } from 'ng-zorro-antd';
import { ImportExportService } from '../../../shared/api/services';
import { LoopBackAuth } from '../../../shared/lib/services/core/auth.service';
import { BASE_URL, API_VERSION, IMPORT_URL } from '../../api/base.url';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dialog-upload-ui',
  templateUrl: './dialog-upload-ui.component.html',
  styleUrls: ['./dialog-upload-ui.component.scss']
})
export class DialogUploadUiComponent implements OnInit {

  @Input() isDialogVisible: boolean = false;
  @Input() dialogTitle: string;
  @Input() pageTitle: string;
  @Input() subPageTitle: string;
  @Input() btnConfirm: string;
  @Input() btnCancel: string;
  @Input() selectMultipleFile: boolean;
  @Input() limitUpload: number;
  @Input() modelSpinner: boolean = false;
  @Input() dataFor: string;
  @Input() fileList: UploadFile[] = [];
  @Input() fileUploader: boolean = false;
  @Input() imageUploader: boolean = false;
  @Input() imageChangedEvent: any;
  @Input() imageFileChanged: any;
  @Input() autoSwitch: boolean;
  @Input() showProfileImage: any;
  @Input() showCropper: any = null;
  @Input() maxImageSize: number = 1048576;

  @Output() onActionOk: EventEmitter<any> = new EventEmitter();
  @Output() onActioncancel: EventEmitter<any> = new EventEmitter();
  @Output() onhandleChange: EventEmitter<any> = new EventEmitter();
  @Output() onImageCropped: EventEmitter<any> = new EventEmitter();
  @Output() onFileUpload: EventEmitter<any> = new EventEmitter();
  btnDisabled: boolean = true;

  uploading: boolean = false;
  headers: any = { 'Authorization': null };
  importURL: string = [BASE_URL, API_VERSION, IMPORT_URL].join('/');

  croppedImage: any = null;

  constructor(
    private message: NzMessageService,
    private modalService: NzModalService,
    private importExportService: ImportExportService,
    private auth: LoopBackAuth,
    private fb: FormBuilder,

  ) {
    this.headers.Authorization = auth.getAccessTokenId();
  }

  ngOnInit() {
  }

  actionOk() {
    this.btnDisabled = true;
    this.showCropper = null;
    this.onActionOk.emit();
  }

  actioncancel(): void {
    this.btnDisabled = true;
    this.onActioncancel.emit();
    this.showCropper = null;
  }

  handleChange({ file, fileList }): void {
    if (fileList['0'] &&
      fileList['0'].status == 'done'
    ) {
      this.onhandleChange.emit({ file, fileList });
      setTimeout(() => { this.fileList = []; }, 3000);
    }
  }

  fileUpload(image: any): void {
    let data: any = {};
    this.btnDisabled = false;
    let status: boolean = false;
    if (image && image.target && image.target.files && image.target.files[0] &&
      this.maxImageSize > image.target.files[0].size) {
      status = false;
      this.imageChangedEvent = image;
      this.showCropper = this.imageChangedEvent.target.value;
      data = {
        'image': this.imageChangedEvent,
        'cropImage': this.showCropper
      };
    } else {
      status = true;
      this.btnDisabled = true;
      this.showCropper = false;
      data = {
        'image': image,
        // 'cropImage': (this.showCropper) ? this.showCropper : null
        'cropImage': null
      };
    }
    this.onFileUpload.emit(data);
    if (status) {
      image.target.value = null;
    }
  }

  imageCropped(event) {
    this.croppedImage = event;
    this.onImageCropped.emit(this.croppedImage);
  }

  loadImageFailed() {

  }
}
