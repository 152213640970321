import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export enum SHOW_TYPES {
  'ERROR' = 'error',
  'SUCCESS' = 'success',
  'INFO' = 'info',
  'WARNING' = 'warning',
  'LOADING' = 'loading',
  // 'CREATE' = 'create'
}

export enum NOTIFICATION_PLACEMENT {
  'TOP_LEFT' = 'topLeft',
  'TOP_RIGHT' = 'topRight',
  'BOTTOM_LEFT' = 'bottomLeft',
  'BOTTOM_RIGHT' = 'bottomRight',
}

@Injectable({
  providedIn: 'root'
})
export class NzMessagesService {

  constructor(
    private message: NzMessageService,
    private notification: NzNotificationService
  ) { }

  showMessage(sType: SHOW_TYPES, infoMessage: any, errorCode: number = 403) {
    if (errorCode === 403) {
      return;
    }

    switch (sType) {
      case SHOW_TYPES.ERROR:
        this.message.error(infoMessage, { nzDuration: 1000 });
        break;
      case SHOW_TYPES.SUCCESS:
        this.message.success(infoMessage, { nzDuration: 1000 });
        break;
      case SHOW_TYPES.INFO:
        this.message.info(infoMessage, { nzDuration: 1000 });
        break;
      case SHOW_TYPES.WARNING:
        this.message.warning(infoMessage, { nzDuration: 1000 });
        break;
      case SHOW_TYPES.LOADING:
        this.message.loading(infoMessage, { nzDuration: 1000 });
        break;
      // case SHOW_TYPES.CREATE:
      //   this.message.create(infoMessage);
      //   break;
    }

  }

  success(infoMessage: any, duration: any = { nzDuration: 1000 }, sType: SHOW_TYPES = SHOW_TYPES.SUCCESS, errorCode: number = 200) {
    this.message.success(infoMessage, duration);
  }
  error(infoMessage: any, duration: any = { nzDuration: 1000 }, sType: SHOW_TYPES = SHOW_TYPES.ERROR, errorCode: number = 200) {
    this.message.error(infoMessage, duration);
  }
  warning(infoMessage: any, duration: any = { nzDuration: 1000 }, sType: SHOW_TYPES = SHOW_TYPES.WARNING, errorCode: number = 200) {
    this.message.warning(infoMessage, duration);
  }

  showNotification(sType: SHOW_TYPES, infoMessage: any, duration: number = 5000,
    placement: NOTIFICATION_PLACEMENT = null, title: string = null ) {

    switch (sType) {
      case SHOW_TYPES.ERROR:
        this.createBasicNotification('success', infoMessage, duration, placement, title);
        break;
      case SHOW_TYPES.SUCCESS:
          this.createBasicNotification('error', infoMessage, duration, placement, title);
        break;
      case SHOW_TYPES.INFO:
        this.createBasicNotification('info', infoMessage, duration, placement, title);
        break;
      case SHOW_TYPES.WARNING:
        this.createBasicNotification('warning', infoMessage, duration, placement, title);
        break;
      // default:
      //   this.createBasicNotification('info', infoMessage, duration, placement, title);
    }

  }

  private createBasicNotification(type: string, infoMessage: any, duration: number, placement: string, title: string): void {
    this.notification.config({
      nzPlacement: (placement) ? placement : 'topRight'
    });
    this.notification.create(
      type,
      (title) ? title : '',
      infoMessage,
      {
        nzDuration: duration,
        nzStyle: {
          width: '100%',
          marginLeft: '0',
        },
        // nzClass: 'test-class'
      }
    );
  }
}
