import { Routes, RouterModule }  from '@angular/router';
import { MailComponent } from './mail.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'mail',
    component: MailComponent,
    children: [
      { path: '', redirectTo: 'inbox', pathMatch: 'full' },
      { path: 'inbox', loadChildren: './inbox/inbox.module#InboxModule' }
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);