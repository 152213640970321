import { Component, OnInit, NgZone } from '@angular/core';
import { LoopBackAuth } from '../shared/lib/services/core/auth.service';
import { Router } from '@angular/router';
import { UIConfig } from './UIConfig';
import { MasterEntryService, EmployeeService, LeaveService, HolidayService, AttendanceService, CoreAPIService } from '../shared/api/services/';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  dataSource: any;
  chartObj: any;
  chart: any = 'column2d';
  pieCharts: any = [];

  patTreatmentStats = {
    "chart": {
      "caption": "PATIENTS TREATMENT STATS",
      "showValues": "1",
      "showPercentInTooltip": "0",
      "numberPrefix": "",
      "enableMultiSlicing": "1",
      "theme": "fusion"
    },
    "data": []
  }
  docTreatmentStats = {
    "chart": {
      "caption": "DOCTOR TREATMENT STATS",
      "showValues": "1",
      "showPercentInTooltip": "0",
      "numberPrefix": "",
      "enableMultiSlicing": "1",
      "theme": "fusion"
    },
    "data": []
  }

  dashbordOption: GridsterConfig;
  dashboard: Array<GridsterItem>;
  setingFormData: any = UIConfig.config.setingFormData;
  notificationData: any = UIConfig.config.notificationData;
  settings: any = {
    "textCards": UIConfig.config.textCards,
    //"graphCards": UIConfig.config.graphCards,
    "tableGraph": UIConfig.config.tableGraph
  };
  actions: any = UIConfig.config.actions;
  widgetData: any = UIConfig.config.nwidgetData;

  designations: any;
  departments: any;
  locations: any;
  branch: any;
  shifts: any;
  codes: string = '';
  widgetsKeys: string = '';
  // widgetData: any = [];
  widgetsLoader: boolean = false;
  designationId : number;
  loggedInUserId : number;

  gridConfig: any = {
    'topGrid': {
      name: 'topGrid',
      gridType: GridType.Fixed,
      compactType: CompactType.None,
      displayGrid: DisplayGrid.None,
      fixedRowHeight: 120
    },
    'bottomGridConfig': {
      name: 'bottomGrid',
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      displayGrid: DisplayGrid.None
    },
    'rightGridConfig': {
      name: 'rightGrid',
      gridType: GridType.Fixed,
      compactType: CompactType.None,
      displayGrid: DisplayGrid.None,
      setGridSize: false,
      swap: true,
      pushItems: false
    }
  };

  isCollapsed: boolean = true;
  showDebugerPanel: boolean = false;
  setLayout: string = 'overpage';

  

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }

  constructor(
    private authService: LoopBackAuth,
    private router: Router,
    private masterService: MasterEntryService,
    private empService: EmployeeService,
    private leaveService: LeaveService,
    private holidayService: HolidayService,
    private attendanceService: AttendanceService,
    private apiService: CoreAPIService,
    private msgService: NzMessageService,
    private zone: NgZone
  ) {
    
    if (!authService.getCurrentUserId()) {
      router.navigate(['/login']);
    }

    let currentUser = this.authService.getCurrentUserData();
    if(currentUser && currentUser.hasOwnProperty('employee_details')){
      this.designationId = currentUser.employee_details.designation_id;
      this.loggedInUserId = currentUser.employee_details.id;
    }
    
    this.dataSource = {
      "chart": {
        "caption": "PATIENT RECORDS MONTH WISE",
        "showValues": "1",
        "showPercentInTooltip": "0",
        "numberPrefix": "",
        "enableMultiSlicing": "1",
        "theme": "fusion"
      },
      "data": []
    };
  }


  empData() {
    this.empService.find().subscribe(res => {
      if (res['data'] &&
        res['status'] &&
        res['data']['data']['rowCount'] &&
        res['data']['data']['newEmployeeCount']) {
        let rowCount = res['data']['data']['rowCount'];
        let newEmployeeCount = res['data']['data']['newEmployeeCount'];
        this.settings['textCards']['data'][0]['data']['value'] = [newEmployeeCount, rowCount];
      }
    },
      error => {
        console.log("Server Error:", error);
      }
    );
  }

  leaveData() {
    this.leaveService.find().subscribe(res => {
      if (res['data'] && res['status'] &&
        res['data']['data']['pendingCount'] &&
        res['data']['data']['approvedCount']) {
        let pendinfCount = res['data']['data']['pendingCount'];
        let approvedCount = res['data']['data']['approvedCount'];
        this.settings['textCards']['data'][2]['data']['value'] = [pendinfCount, approvedCount];
      }
    },
      error => {
        console.log("Server Error:", error);
      }
    );

  }

  holidayData() {
    this.holidayService.find().subscribe(res => {
      if (res['data'] && res['status'] && res['data']['data']['rowCount']) {
        let rowCount = res['data']['data']['rowCount']
        this.settings['textCards']['data'][3]['data']['value'] = [rowCount, ''];
      }
    },
      error => {
        console.log("Server Error:", error);
      }
    );
  }

  attendanceAnalysisData() {
    this.attendanceService.loadAnalysisSection().subscribe(res => {
      let attendanceValues = [];
      let locationValues = [];
      if (res['data'] && res['status'] && res['data']['data']) {
        if (res['data']['data']['attendenceData']) {
          let data = res['data']['data']['attendenceData'];
          data.forEach(element => {
            attendanceValues.push([element.date, parseInt(element.emp_count)]);
          });
          this.settings['tableGraph']['data'][1]['data'] = attendanceValues;
        }
      }
    },
      error => {
        console.log("Server Error:", error);
      }
    );
  }

  private loadWidgets() {
    this.apiService.api('GET', 'widgets/requests', undefined).subscribe(res => {

      if (res['status'] === 'success' && res['data'] && res['data']['success'] === true && res['data']['data']) {
        let data = {}, codes = [];

        for (let key in this.settings) {
          if (this.settings.hasOwnProperty(key)) {
            data[key] = [];
          }
        }

        res['data']['data'].forEach(ele => {
          if (typeof ele.config === 'string') {
            ele.config = JSON.parse(ele.config);
          }
          data[ele.config.configKey].push(Object.assign({ 'itemKey': parseInt(ele.item_key), 'code': ele.code }, ele.config));
          codes.push(ele.code.toLowerCase().trim());
          this.codes += ((!this.codes || this.codes === '') ? '' : ',') + ele.config.layoutType + ':' + ele.code.toLowerCase().trim();
        });

        for (let key in this.settings) {
          if (this.settings.hasOwnProperty(key)) {
            this.settings[key].data = data[key];
          }
        }

        // console.log(this.settings);

        if (codes.length > 0) {
          this.widgetsKeys = codes.join(',');
          this.loadWidgetsData(this.widgetsKeys);
        }

      } else {
        this.msgService.error('No data found.');
      }

    });
  }

  private loadWidgetsData(codes: string) {
    this.widgetsLoader = true;
    this.apiService.api('GET', 'widgets/get-data/' + codes, undefined).subscribe(res => {
      if (res['status'] === 'success' && res['data'] && res['data']['success'] === true && res['data']['data']) {
        let keys = this.codes.split(',');
        let data = res['data']['data'];
        let finalData = {};

        for (let key in data) {
          keys.forEach(ele => {
            if (ele.indexOf(key) >= 0) {
              finalData[key] = Object.assign({ 'layoutType': ele.split(':')[0], 'widgetType': key }, data[key]);
            }
          });
        }

        this.widgetData = finalData;
        this.widgetsLoader = false;

      } else {
        this.msgService.error('No widget data found.');
        this.widgetsLoader = true;
      }
    });
  }

  onClick(event: any) {
    if (event.action === this.actions.REMOVE) {
      this.removeWidgetAction(event.data.itemKey);
    } else if (event.action === this.actions.REFRESH) {
     this.loadWidgetsData(this.widgetsKeys);
    }
  }

  ngOnInit() {
    this.settings = UIConfig.config;

   if(this.designationId == 2 || this.designationId == 3){
    this.loadDoctorAvailabilityWidget(); 
   }else{
     this.widgetData = null;
   }

   if(this.designationId == 1){
    this.loadDoctorTreatmentStatsWidget();
   }else{
     this.docTreatmentStats = null;
   }
    this.loadPatientTreatmentStatsWidget();
    this.loadMonthWisePatientWidget();
    //this.loadWidgets();
  }

  private removeWidgetAction(id: number) {
    this.apiService.api('PUT', 'widgets/user-widget/delete/' + id, undefined, undefined, {
      'status': 0,
      'id': id
    }).subscribe(res => {
      if (res['status'] === 'success' && res['data'] && res['data']['success'] === true && res['data']['data']) {
        this.msgService.success(res['data']['data']['message'] || 'Widget updated.');
        this.loadWidgets();
      } else {
        this.msgService.error(res['message'] || 'Error: while updating widget.');
      }
    });
  }

  initialized($event) {
    this.chartObj = $event.chart; // saving chart instance
  }

  onSelectionChange(chart) {
    this.chart = chart;
    this.chartObj.chartType(chart); // Changing chart type using chart instance
  }

  loadDoctorAvailabilityWidget() {

    this.apiService.api('get','widgets/get-data/doctors-current-availability-widget', undefined, undefined, undefined, undefined ).subscribe( res => {
       
      if(res['data'] && res['data']['success']){
        if(res['data']['data'].hasOwnProperty('doctors-current-availability-widget') && res['data']['data']['doctors-current-availability-widget']['status']){
          let widgData = res['data']['data']['doctors-current-availability-widget']['data'];
          this.widgetData[0].label = ['Available','Unavailable'];
          this.widgetData[0].value = [widgData.available,widgData.unavailable];
        }
      }else{
        this.widgetData = null;
      }

    });

  }

  refreshDoctorAvailabilityWidget(){
    this.loadDoctorAvailabilityWidget();
  }

  loadPatientTreatmentStatsWidget(){
    this.apiService.api('get','widgets/get-data/patient-treatment-stats-widget', undefined, undefined, undefined, undefined ).subscribe( res => {
       
      if(res['data'] && res['data']['success']){
        if(res['data']['data'].hasOwnProperty('patient-treatment-stats-widget') && res['data']['data']['patient-treatment-stats-widget']['status']){
          let widgData = res['data']['data']['patient-treatment-stats-widget']['data'];
          // console.log('widgData',widgData);
          this.patTreatmentStats.data.push({label:'OPD Patients',value: widgData.opd_patients});
          this.patTreatmentStats.data.push({label:'Ongoing Patients',value: widgData.ongoing_patients});
          this.patTreatmentStats.data.push({label:'Closed Patients',value: widgData.closed_patients});
          this.patTreatmentStats.data.push({label:'Non-cancer Closed Patients',value: widgData.non_cancer_closed_patients});
          this.patTreatmentStats.data.push({label:'Deceased Patients',value: widgData.deceased_patients});
          this.patTreatmentStats.data.push({label:'Completed Patients',value: widgData.completed_patients});
         // this.pieCharts.push(this.patTreatmentStats);
        }
      }

    });
  }

  loadDoctorTreatmentStatsWidget(){
    let qParams = {};
    
    qParams['where'] = {"doc_emp_id":{"e":this.loggedInUserId}};
    this.apiService.api('get','widgets/get-data/doctor-treatment-stats-widget', undefined, undefined, qParams, undefined ).subscribe( res => {
       
      if(res['data'] && res['data']['success']){
        if(res['data']['data'].hasOwnProperty('doctor-treatment-stats-widget') && res['data']['data']['doctor-treatment-stats-widget']['status']){
          let widgData = res['data']['data']['doctor-treatment-stats-widget']['data'];
          // console.log('widgData',widgData);
          
          this.docTreatmentStats.data.push({label:'New Patients',value: widgData.new_patients});
          this.docTreatmentStats.data.push({label:'Followup Patients',value: widgData.followup_patients});
          this.docTreatmentStats.data.push({label:'Closed Patients',value: widgData.closed_patients});
          this.docTreatmentStats.data.push({label:'Deceased Patients',value: widgData.deceased_patients});
          this.docTreatmentStats.data.push({label:'Completed Patients',value: widgData.completed_patients});
          this.docTreatmentStats.data.push({label:'In Progress Patients',value: widgData.inprogress_patients});
          //this.pieCharts.push(this.patTreatmentStats);
        }else{
          this.docTreatmentStats = null;
        }
      }else{
        this.docTreatmentStats = null;
      }
    });

  }

  loadMonthWisePatientWidget(){
    let qParams = {};
    let date = new Date();
    let year = date.getFullYear();
    qParams['where'] = {'registered_on':{'l': year+'%'}};
    if(this.designationId == 1){
      qParams['where']['current_doc_id'] = {'e':this.loggedInUserId};
    }
    this.apiService.api('get','widgets/get-data/patient-monthwise-annual-record-widget', undefined, undefined, qParams, undefined ).subscribe( res => {
       
      if(res['data'] && res['data']['success']){
        if(res['data']['data'].hasOwnProperty('patient-monthwise-annual-record-widget') && res['data']['data']['patient-monthwise-annual-record-widget']['status']){
          let widgData = res['data']['data']['patient-monthwise-annual-record-widget']['data'];
          // console.log('widgData',widgData);
          for(var patData in widgData){

            this.dataSource.data.push({label : widgData[patData].month_name, value: widgData[patData].patient_count})
          }

        }
      }

    });
  }
}

