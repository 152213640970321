import { environment } from '../../../environments/environment';
export const BASE_URL    = environment.webServiceUrl;
export const API_VERSION = environment.apiVersion;
export const IMPORT_URL = environment.importURL;
export const ATS_REGEX = environment.atsRegex;
export const HRMS_REGEX = environment.hrmsRegex;
export const COMMON_REGEX = environment.commonRegex;
export const NAME_OF_PARTICIPATING_CENTRE = environment.nameOfParticipatingCentre;
export const CODE_OF_PARTICIPATING_CENTRE = environment.codeOfParticipatingCentre;
export const HOSPITAL_REGISTRATION_NUMBER = environment.hospitalRegistrationNumber;
//start comman status //
export const COMMAN_STATUS = environment.commanStatus;
export const PATIENT_STATUS = environment.patientStatus;
export const USER_MODULE_SETTING = environment.userModule;
export const CALENDER_DATA = environment.calenderData;
export const APPOINTMENT_TYPE = environment.appointmentType;
export const APPOINTMENT_TYPES = environment.appointmentTypes;
export const APPOINTMENT_STATUS = environment.appointmentStatus;
export const DOCTOR_APPOINTMENT_STATUS = environment.doctorAppointmentStatus;
export const DONATION_STATUS = environment.donationStatus;
export const RT_REGEX = environment.rtRegex;
export const TREATMENT_STATUS = environment.pgiTreatmentStatus;
export const RT_STATUS = environment.rtStatus;
export const BT_STATUS = environment.btStatus;
export const INDEMNITY_TYPES = environment.indemnity_types;
export const CT_STATUS = environment.ctStatus;
export const CT_REGEX = environment.ctRegex;
export const RT_TREATMENT_STATUS = environment.rtTreatmentStatus;
export const REDIRECTURL = environment.redirectUrl;
export const DATE_FORMAT = environment.dateFormat;
export const AUTH_TYPE = environment.auth_types;
export const DATETIME_FORMAT = environment.dateTimeFormat;
export const RT_SIMULATION_STEPS = environment.rtSimulationSteps;
export const BT_TRT_STATUS = environment.bttrtStatus;
export const LOGO_ASSETS_UPLOAD_PATH = environment.logoAssetUpload;
export const HBCR_STATUS = environment.hbcrStatus;
// end comman status // 





 