import { Injectable } from '@angular/core';
import { DynamicFormsAddItem } from '../../../components/dyc-forms/dyc-forms-add-items';
import { DycFormsComboUiComponent } from '../../../components/dyc-forms/templates/dyc-forms-combo-ui/dyc-forms-combo-ui.component';

export enum DycFromTypes {
  TYPE_COMBO = 'combo-ui'
}


@Injectable({
  providedIn: 'root'
})
export class DycFormService {
  getComponents(type: string, data: any) {
    let component = null;

    if (type === DycFromTypes.TYPE_COMBO) {
      component = new DynamicFormsAddItem(DycFormsComboUiComponent, data);
    }

    return component;
  }
}