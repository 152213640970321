import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorsComponent } from './doctors.component';
import { routing } from './doctors.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeaderModule } from '../core/layouts/header/header.module';
import { MpModule } from './mp/mp.module';
import { OpdModule } from './opd/opd.module';
import { MnCtPatientsModule } from './mn-ct-patients/mn-ct-patients.module';
import { MnOtherPatientsModule } from './mn-other-patients/mn-other-patients.module';
import { MnRtCtPatientsModule } from './mn-rt-ct-patients/mn-rt-ct-patients.module';
import { ManagePatientRemarksModule } from '../manage-patient-remarks/manage-patient-remarks.module';
// import { BtAppointmentsListComponent } from './bt-appointments-list/bt-appointments-list.component';
// import { BtAppointmentsComponent } from './bt-appointments/bt-appointments.component';
import { clinicalHistoryModule } from '../shared/components/clinical-history/clinical-history.module';
import { FollowupAfterTreatmentModule } from './followup-after-treatment/followup-after-treatment.module';


@NgModule({
  imports: [
    CommonModule,
    routing,
    LayoutsModule,
    FormsModule,
    HeaderModule,
    ReactiveFormsModule,
    clinicalHistoryModule,
    NgZorroAntdModule.forRoot(),
    // MpModule,
    // OpdModule,
    // ManagePatientRemarksModule,
    // MnCtPatientsModule,
    // MnOtherPatientsModule,
    // MnRtCtPatientsModule
    FollowupAfterTreatmentModule
  ],
  declarations: [DoctorsComponent]
})
export class DoctorsModule { }
