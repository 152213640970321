import { NgModule, enableProdMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { DashboardComponent } from './dashboard.component';

import { routing } from './dashboard.routing';
import { WidgetUiModule } from '../shared/components/widgets-ui/widgets-ui.module';
import { DycFormsModule } from '../shared/components/dyc-forms/dyc-forms.module';
 import { GridsterModule } from 'angular-gridster2';
import { FusionChartsModule } from 'angular-fusioncharts';

import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import * as TimeSeries from 'fusioncharts/fusioncharts.timeseries';

// Import FusionCharts library
import * as FusionCharts from 'fusioncharts';

// Load FusionCharts Individual Charts
import * as Charts from 'fusioncharts/fusioncharts.charts';
import { CustomWidgetComponent } from './custom-widget/custom-widget.component';
import { HeaderModule } from '../core/layouts/header/header.module';

// Use fcRoot function to inject FusionCharts library, and the modules you want to use
FusionChartsModule.fcRoot(FusionCharts, Charts, TimeSeries,FusionTheme)


@NgModule({
  imports: [
    CommonModule, LayoutsModule, NgZorroAntdModule, routing, WidgetUiModule,
    DycFormsModule, GridsterModule,HeaderModule,
    FusionChartsModule // Include in imports
  ],
  declarations: [DashboardComponent,CustomWidgetComponent]
})
export class DashboardModule { }
