import { Injectable } from '@angular/core'
import { CoreAPIService } from '../shared/api/services';
import { DataFormatterService } from './data-formatter.service'
import { NzMessageService } from 'ng-zorro-antd';
import { BrowserdbService } from '../shared/api/frontend/service/browserdb.service';
import { NavService } from '../shared/api/services';

@Injectable()
export class UtilsService {
  private fetchingRemarks: boolean = false;
  private fetchingSchedules: boolean = false;
  private fetchingFDMaster: boolean = false;
  private fetchingRTMaster: boolean = false;

  constructor(
    private coreApiServices: CoreAPIService,
    private dataFormatterService: DataFormatterService,
    private message: NzMessageService,
    private browserDB: BrowserdbService,
    private navService: NavService,
  ) {}

  public fetchRTPayments() {
    this.coreApiServices.api('get', `pgi-payment/get`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data'] && res['data']['success'] && res['data'].data.rows.length) {
        this.dataFormatterService.populateRTPaymentOptions(res['data']['data']['rows']);
      }
    }, error => {
      this.message.error(error);
    })
  }
  
  public fetchRTOptionsMaster() {
    if(!this.fetchingRTMaster) {
      this.fetchingRTMaster = true;
      this.coreApiServices.api('get', `pgi-rt-patients/get-master-data`, undefined, undefined, undefined).subscribe(res => {
        if (res && res['data'] && res['data']['success']) {
          this.dataFormatterService.populateOptionsMaster(res['data']['data']);
        }
        this.fetchingRTMaster = false;
      }, error => {
        this.message.error(error);
        this.fetchingRTMaster = false;
      })
    }
  }

  public fetchDoctorOptionsMaster() {
    this.coreApiServices.api('get', `pgi-rt-patients/get-techniques`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data']) {
        this.dataFormatterService.populateOptionsMaster(res['data']);
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchArchiveOptionsMaster() {
    this.coreApiServices.api('get', `pgi-archive-sections/get`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data']) {
        this.dataFormatterService.populateCustomOptions('archive_sections', res['data'].data.rows, 'id', 'section_name');
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchMorphologyGradeMaster() {
    this.coreApiServices.api('get', `pgi-morphology-grade/get`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data']) {
        this.dataFormatterService.populateCustomOptions('morphology_grade', res['data'].data.rows, 'id', 'name');
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchICDCodeMaster() {
    this.coreApiServices.api('get', `pgi-icd-code-category/get-icd-codes`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data'] && res['data'].success) {
        res['data'].data.rows.forEach(row => {
          this.dataFormatterService.populateCustomOptions(row.name.toLowerCase().replace(/[-\s]+/, '_'), row.icd_codes, 'key', 'label');
        })
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchPositionScanningSitesMaster() {
    this.coreApiServices.api('get', `pgi-site-option-values/get-option-values`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data'] && res['data'].success) {
        this.dataFormatterService.populateOptionsMaster(res['data'].data.rows);
        // res['data'].data.rows.forEach(row => {
        //   this.dataFormatterService.populateCustomOptions(row.name.toLowerCase().replace(/[-\s]+/, '_'), row.icd_codes, 'key', 'label');
        // })
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchDischargeMaster() {
    this.coreApiServices.api('get', `patients/get-master-discharge-data`, undefined, undefined, undefined).subscribe(res => {
      if (res && res['data'] && res['data'].success) {
        for (const key in res['data'].data) {
          if (res['data'].data.hasOwnProperty(key)) {
            res['data'].data[key] = {
              rows: res['data'].data[key].map(a => { return {id:a.key, name:a.label}})
            }
          }
        }
        this.dataFormatterService.populateOptionsMaster(res['data'].data);
      }
    }, error => {
      this.message.error(error);
    })
  }

  public fetchFrontDeskOptionsMaster() {
    if(!this.fetchingFDMaster) {
      this.fetchingFDMaster = true;
      this.coreApiServices.api('get', `patients/get-master-demographic-data`, undefined, undefined, undefined).subscribe(res => {
        if (res && res['data'].data) {
          let keys = Object.keys(res['data'].data);
          keys.forEach(key => {
            this.dataFormatterService.populateCustomOptions(key, res['data'].data[key], 'key', 'label');
            if(key === 'country') {
              this.dataFormatterService.populateCustomOptions('country_phone_codes', res['data'].data[key], 'phone_code', 'label');
            }
          })
        }
        this.fetchingFDMaster = false;
      }, error => {
        this.message.error(error);
        this.fetchingFDMaster = false;
      })
    }
  }

  public fetchRemarkPrescriptions = () => {
    if(!this.fetchingRemarks) {
      this.fetchingRemarks = true;
      this.coreApiServices.api('get', 'pgi-remark-suggestion/get', undefined, undefined, undefined, undefined).subscribe(
        res => {
          if (res['data'] && res['data'].data && res['data'].data.rows) {
            // this.prescriptions = res['data'].data.rows;
            this.dataFormatterService.populateRemarkTitles(res['data'].data.rows);
          }
          this.fetchingRemarks = false;
        }, error=> {
          this.fetchingRemarks = false;
        }
      );
    }
  }

  public fetchScheduleData = () => {
    if(!this.fetchingSchedules) {
      this.fetchingSchedules = true;
      this.coreApiServices.api('get', 'pgi-schedule-range/get', undefined, undefined, undefined, undefined).subscribe(
        res => {
          if (res['data'] && res['data'].data && res['data'].data.rows) {
            // this.prescriptions = res['data'].data.rows;
            this.dataFormatterService.populateSchedules(res['data'].data.rows);
          }
          this.fetchingSchedules = false;
        }, error=> {
          this.fetchingSchedules = false;
        }
      );
    }
  }

  public getDischargeSummary = (id, callback) => {
    this.coreApiServices.api('get', `patients/get-discharge-data/${id}`, undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data'].data) {
          callback(true, res['data'].data);
        }
      }, error=> {
          callback(false, 'Opps... Something went wrong');
      }
    );
  }

  public updateDischargeSummary = (formData, callback) => {
    this.coreApiServices.api('put', `pgi-patient-discharge-summaries/update-data`, undefined, undefined, undefined, formData).subscribe(res => {
      if (res && res['data'] && res['data']['success']) {
        callback(true, res['data'])
        this.message.success(res['data']['data']['message']);
      } else {
        callback(false, res['data'])
      }
    }, error => {
      callback(false, error)
    })
  }

  public fetchSidebarNavs = (menuId, rt_no, url, removableElems, def=null) => {
    this.browserDB.dbGetByID(menuId.toString(), (e, d) => {
      if (!e && d) {
        this.renderNavs(rt_no, url, removableElems, d, def)
      } else {
        this.navService.findNAVById(menuId).subscribe(
          res => {
            if (res['data'] && res['data']['success']) {
              this.renderNavs(rt_no, url, removableElems, res['data']['data'], def);
            } else {
                this.message.error(res['data']['message']);
            }
          },
          error => {
            console.error('Server Error');
          }
        );
      }
    });
  }

  public renderNavs = (rt_no, url, removableElems, d, def) => {
    let str = url.split('/').pop(),
    regex = new RegExp('^' + str + '$'),
    regex2 = new RegExp('^' + def + '$'),
    _default:string = null;
    if(_default = d['items'].find(a => regex.test(a.routerLink))) {
      // console.log('1')
    } else if(def && (_default = d['items'].find(a => regex2.test(a.routerLink)))) {
      // console.log('2')
    } else { 
      // console.log('3')
      _default = d['items'][0];
    }
    _default['isDefault'] = true;

    let cloneObj = Object.assign({}, d);
    if (cloneObj && rt_no === null) {
      removableElems.forEach(function (value, key) {
        let index = cloneObj['items'].findIndex(x => x.id === value);
        if (index != -1) {
          cloneObj['items'].splice(index, 1);
        }
      });
    }
    this.dataFormatterService.populateSidebarNavs(cloneObj);
  }

  public fetchAppSettings() {
    this.coreApiServices.api('get', 'logo-setting/get-logo-pref/get', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          this.dataFormatterService.renderAppSettings(res['data']['data']['rows'])         
        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.error('Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  public fetchArchiveAttachemntSettings(key) {
    this.coreApiServices.api('get', 'pgi-machine-load/machine-pref-get/attachment-file-size/form-data', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          if(res['data']['data']['value'].length) {
            let data = res['data']['data']['value'].find(a => a.key == key);
            data && this.dataFormatterService.renderArchiveAttachmentSettings(data)         
          }
        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.error('Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  public fetchPositionScanningSiteForms() {
    this.coreApiServices.api('get', 'pgi-position-and-range-site-forms/get', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          let siteForms = {};
          if(res['data']['data']['rows'].length) {
            res['data']['data']['rows'].forEach(a => {
              siteForms[a.name.toLowerCase().replace(/[\s]+/g, '_').replace(/[\\()]/, '')] = a.form_json;
            });
            this.dataFormatterService.setPositionScanningSiteForms(siteForms)         
          }
        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.error('Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  public validatePatientsData(formData, callback) {
    this.coreApiServices.api('post', 'patients/validate-data', undefined, undefined, undefined, formData).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          callback(true, res['data'].message)
        } else {
          callback(false, res['data'].message)
        }
      },
      error => {
        callback(false, error['message'])
      }
    );
  }
}