import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UIConfig } from './UIConfig';
import { Router, NavigationExtras } from '@angular/router';
import { NzMessageService } from 'ng-zorro-antd';
import { CoreAPIService } from '../../shared/api/services/';
import { LoopBackAuth } from '../../shared/lib/services/core/auth.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, Form } from '@angular/forms';
import { COMMON_REGEX, USER_MODULE_SETTING, TREATMENT_STATUS, PATIENT_STATUS, DATE_FORMAT, DATETIME_FORMAT } from '../../shared/api/base.url';
import moment from 'moment';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { stringify } from 'querystring';
import { last } from '@angular/router/src/utils/collection';
import { type } from 'os';
import { Input } from '@angular/core';

@Component({
  selector: 'app-followup-after-treatment',
  templateUrl: './followup-after-treatment.component.html',
  styleUrls: ['./followup-after-treatment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FollowupAfterTreatmentComponent implements OnInit {

  pageTitle: string = UIConfig.config.pageTitle;
  tableUIFollowUpPatient: any = UIConfig.config.tableUIFollowUpPatient;
  toolbarUI: any = UIConfig.config.toolbarUI;
  oldpatientData: any = UIConfig.config.oldpatientData;
  pageIndex: number = UIConfig.config.toolbarUI.pagination.pageIndex;
  pageSize: number = UIConfig.config.toolbarUI.pagination.pageSize;
  commonSearchIn: any = UIConfig.config.commonSearchIn;
  commonFilters: any[] = null;
  dropDownSearchVal: number = null;
  followUpAfterTreatmentData: any = [];
  nzDrawerWidth: number = UIConfig.config.drawerWidth;

  currentUserData: any;
  currentEmpId: number;
  currentEmpRole: number;
  progressBar: boolean = false;
  mpSpin: boolean = false;
  drawerVisible: boolean = false;
  nzDrawerTitle: string = '';
  drawerLoader: boolean = false;
  paddingTop: string;
  currentOpenDrawer: string = null;
  codeRegex = COMMON_REGEX.alpha_numeric;
  // For case sheet Doc section
  // isVisibleCaseDocSheet = false;
  // prescrptionFormData: any = [];
  // End case sheet Doc section

  // Start short order define
  sortOrder: any = null;
  // End short order define


  generateDueDate: any = null;
  today = new Date();
  followUpForm: FormGroup;
  next_follow_up_due_date: Date = null;
  actual_follow_up_due_date = moment(this.today).format('YYYY-MM-DD');
  treatment_id: number = null;
  patient_id: number = null;
  treatment_status: number = null;
  idOptions: string[] = ['ICD-1', 'ICD-2', 'Co-3'];
  isLoading: boolean = false;
  validateStatus: boolean = false;
  isVisible = false;
  current = 0;
  index: number = 1;

  latecomplicationsData: any = UIConfig.config.latecomplicationsData;
  treatmentpresenceData: any = UIConfig.config.treatmentpresenceData;
  causeofDeathData: any = UIConfig.config.causeofDeathData;
  indicatediagnosisData: any = UIConfig.config.indicatediagnosisData;
  diseasestatusData: any = UIConfig.config.diseasestatusData;
  visitstatusData: any = UIConfig.config.visitstatusData;
  methodfollowUpData: any = UIConfig.config.methodfollowUpData;
  methodfollowUpField: boolean = false;
  diseasestatusField: boolean = false;
  indicatediagnosisField: boolean = false;
  visitstatusField: boolean = false;
  followupTreatmentStatus: boolean = false;
  deathRemark: boolean = false;
  latecomplicationsField: boolean = false;
  remarkTitles = [];
  prescriptions = [];
  loadStatus: boolean = false;

  dateFormat: string = DATE_FORMAT;
  dateTimeFormat: string = DATETIME_FORMAT;

  filteredIdOptions: any = [];
  // Start Follow Information
  validateForm: FormGroup;
  arrControls: any = {
    nature_of_complication: [null, [Validators.required, Validators.pattern(COMMON_REGEX.alpha_numeric_spec)]],
    max_grade: [null, [Validators.required, Validators.pattern(COMMON_REGEX.alpha_numeric_spec)]], onset_date: [null, [Validators.required]],
    resolved_status: [null], last_seen_date: [null],
  };



  estimatedTreatmentCost: any = null;
  canSelfAfford: any = null;
  needFinancialAssistance: any = null;
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) > 0;
  }

  addField(e?: MouseEvent): void {
    this.complicationsArray.push(this.fb.group(this.arrControls));
  }
  removeField(index): void {
    this.complicationsArray.removeAt(index);
  }

  redirectFollowUp(patientId, siteId, patientDocId, patientStatus): void {

    this.router.navigate(['/doctors/manage-patients/followup', patientId, siteId, patientDocId, patientStatus]);
  }

  get complicationsArray(): FormArray {
    return this.followUpForm.get('complications') as FormArray;
  }

  loadRemarkTitles = () => {
    this.dataService.api('get', 'pgi-remark-titles/get', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data'].data && res['data'].data.rows) {
          this.remarkTitles = res['data'].data.rows;
        }
      }
    );
  }

  loadPrescriptions = () => {
    this.dataService.api('get', 'pgi-remark-suggestion/get', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data'].data && res['data'].data.rows) {
          this.prescriptions = res['data'].data.rows;
        }
      }
    );
  }

  disabledDateRange = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) < 0;
  }

  disabledForwardDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.today) > 0;
  }


  @Input() set searchValue(event) {
    (event == '' || event) && this.onCommonSearch(event);
  };
  @Input() set pageIndexChange(event) {
    event && this.onPageIndexChange(event);
  };
  isShowToolbar: boolean = true;
  @Input() set showToolbar(evt) {
    this.isShowToolbar = evt;
    !evt && this.loadAfterTreatmentdata();
  };
  get showToolbar() {
    return this.isShowToolbar;
  }
  @Input() set pageSizeChange(evt) {
    evt && this.onPageSizeChange(evt);
  };
  @Input() set onSort(evt) {
    evt && this.onSortData(evt)
  };

  @Output() toolBarChange: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    private authService: LoopBackAuth,
    private dataService: CoreAPIService,
    private message: NzMessageService,
    private fb: FormBuilder,
    private ref: ChangeDetectorRef
  ) {

    setInterval(() => {
      this.ref.markForCheck();
    }, 1000);

    this.currentUserData = this.authService.getCurrentUserData();
    if (this.currentUserData && this.currentUserData.employee_details && this.currentUserData.employee_details.id) {
      this.currentEmpId = this.currentUserData.employee_details.id;
      // console.log('id',this.currentEmpId )
      this.currentEmpRole = this.currentUserData.role;
    }
    this.toolbarUI.sortBy.defaultKey = 'id'
  }

  ngOnInit() {
    this.progressBar = true;
    this.mpSpin = true;

    /** follow up form controls **/
    this.followUpForm = this.fb.group({
      // treatment_id
      followup_date: [null, [Validators.required]],
      appointment_id: [null],
      prior_treatment_id: [null],
      booked_appointment_status: [null],
      followup_updated_date: [this.actual_follow_up_due_date, [Validators.required]],
      // followup_visit_no
      // appointment_id
      followup_method: [null, [Validators.required]],
      followup_method_remark: [null],
      followup_patient_status: [null, [Validators.required]],
      followup_disease_status: [null],
      followup_disease_status_remark: [null],
      followup_diagnosis_options: [null],
      followup_diagnosis_options_remark: [null],
      followup_treatment_status: [null],
      followup_treatment_details: [null],
      followup_complication_status: [null],
      complications: this.fb.array([]),

      cause_of_death: [null],
      date_of_death: [null],
      remark: [null]
    });
    /** end follow up form controls **/

    // this.loadAfterTreatmentdata();
    this.onSortData({
      sortColumnName: this.toolbarUI.sortBy.defaultKey,
      orderBy: (this.toolbarUI.sortBy.isAssending ? 'ASC' : 'DESC')
    });

    this.loadRemarkTitles();
    this.loadPrescriptions();
  }

  onPageIndexChange(pageIndex: number) {
    this.progressBar = true;
    this.mpSpin = true;
    this.loadAfterTreatmentdata(pageIndex);
  }

  onDropDownSearch(evt: number) {
    this.dropDownSearchVal = evt;
    this.pageIndex = 1;
    this.progressBar = true;
    this.mpSpin = true;
    this.loadAfterTreatmentdata(1);
  }

  onPageSizeChange(event: number) {
    this.pageSize = event || this.pageSize;
    this.loadAfterTreatmentdata(1);
  }

  onCommonSearch(phrase: any) {
    this.addCommonFilter(phrase);
    this.progressBar = true;
    this.mpSpin = true;
    this.loadAfterTreatmentdata(1);
  }

  addCommonFilter(phrase: any) {
    this.commonFilters = [];
    for (let i = 0; i < this.commonSearchIn.length; i++) {
      let key = this.commonSearchIn[i];
      let filter = {};
      filter[key] = { l: `%${phrase}%` };
      this.commonFilters.push(filter);
    }
  }
  onSortData(event: any): void {
    this.sortOrder = event;
    this.loadAfterTreatmentdata(1);
  }

  loadAfterTreatmentdata(pageIndex = 0) {
    this.loadStatus = true;
    this.mpSpin = true;
    if (pageIndex === 0) {
      pageIndex = this.pageIndex;
    }
    this.pageIndex = pageIndex;
    let offset = (this.pageIndex - 1) * this.pageSize;
    let limit = this.pageSize;
    let qParams = { 'limit': limit, 'offset': offset };
    // qParams['where'] = {
    //   'treatment_status': { e: TREATMENT_STATUS[3].key }, 'status': { e: PATIENT_STATUS[1].key }
    // };

    if (this.currentEmpRole == USER_MODULE_SETTING.doctorRole) {
      // qParams['where']['doc_emp_id'] = { e: this.currentEmpId };
      // qParams['where'] = {'doctor_emp_id': { e: this.currentEmpId }};
      qParams['where'] = { 'current_doc_id': { e: this.currentEmpId } };
    }

    if (null !== this.commonFilters && this.commonFilters.length > 0) {
      qParams['or'] = this.commonFilters;
    }
    // qParams['extra_filter'] = { 'patient_freeze_record': 0 };

    this.progressBar = true;
    // qParams['sort'] = { 'treatment_completion_on': -1 };
    if (null !== this.sortOrder && this.sortOrder.sortColumnName) {
      if (!qParams['sort']) {
        qParams['sort'] = {};
      }
      qParams['sort'][this.sortOrder.sortColumnName] = (this.sortOrder.orderBy === 'ASC' ? 1 : -1);
    }else{
      qParams['sort'] = { 'id': -1 }
    }

    if (!this.showToolbar) {
      qParams['q'] = { archived: true }
    }
    // this.dataService.api('get', 'pgi-treatment/get', undefined, undefined, qParams, undefined).subscribe(
    this.dataService.api('get', 'patients/get-after-treatment-followup', undefined, undefined, qParams, undefined).subscribe(
      res => {
        this.mpSpin = false;
        if (res['data'] && res['data']['success']) {
          let data = res['data']['data']['rows'];
          /*           data.forEach(function (element, index: any) {
                      let fname = (element.first_name) ? element.first_name.charAt(0) : '';
                      let lname = (element.last_name) ? element.last_name.charAt(0) : '';
                      element['profileImage'] = (element.profileImage) ? element.profileImage : '';
                      element['profileText'] = (!element.profileImage) ? (fname + lname) : '';
                      element['dueDtBtn'] = (element['next_follow_up_due_date'] === null || (element['next_follow_up_due_date']
                        && element['next_follow_up_due_date'] >= moment(this.today).format('YYYY-MM-DD'))) ? false : true;
                    }, this); */
          this.followUpAfterTreatmentData = data;
          this.toolbarUI.pagination.totalPages = res['data']['data']['rowCount'];
          this.toolbarUI.pagination.pageIndex = this.pageIndex;
          this.toolBarChange.emit(this.toolbarUI);

          this.loadStatus = false;
        }
        this.progressBar = false;

      },
      error => {
        this.loadStatus = false;
        this.mpSpin = false;
        this.message.error('Critical Error: ' + error['message']);
      }
    );
  }

  // Start: Open detail component section
  onDetail(patientsId: any) {
    // this.router.navigate(['/doctors/manage-patients', patientsId]);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        menuID: 82
      }
    };
    this.router.navigate(['/doctors/manage-patients', patientsId], navigationExtras);
  }
  // End: Open detail component section

  onGenerateNewDueDate(treatementId): void {
    if (this.generateDueDate === null) {
      this.message.warning('Please fill due date!');
    } else {
      this.generateDueDate = moment(this.generateDueDate).format('YYYY-MM-DD'); // 2019-08-06
      this.dataService.api('put', 'pgi-treatment/update-duedate/' + treatementId + '/' + this.generateDueDate,
        undefined, undefined, undefined).subscribe(res => {
          if (res && res['data'] && res['data']['success']) {
            this.loadAfterTreatmentdata(this.pageIndex);
            this.generateDueDate = null;
            this.message.success(res['data']['message']);
          } else {
            this.generateDueDate = null;
            this.message.error(res['data']['message']);
          }
        }, error => {
          this.generateDueDate = null;
          this.message.error('Critical Error: ' + error);
        });
    }
  }

  onGenerateNewDueDateCancel(): void {
    this.generateDueDate = null;
  }

  onSubmitFollowup() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
    let formData = this.followUpForm.value;
    //formData['treatment_id'] = (this.treatment_id) ? this.treatment_id : null;
    formData['patient_id'] = (this.patient_id) ? this.patient_id : null;
    formData['last_treatment_status'] = (this.treatment_status) ? this.treatment_status : null;
    formData['date_of_death'] = (formData['date_of_death']) ? moment(formData['date_of_death']).format('YYYY-MM-DD') : null;
    this.validation(formData);
    if (this.followUpForm.valid && !this.validateStatus) {
      this.dataService.api('post', 'pgi-follow-up/post', undefined, undefined, undefined, formData).subscribe(res => {
        if (res && res['data'] && res['data']['success']) {
          this.followUpForm.reset();
          this.loadAfterTreatmentdata(this.pageIndex);
          this.isLoading = false;
          this.drawerVisible = false;
          this.message.success(res['data']['message']);
        } else {
          this.message.error(res['data']['message']);
        }
      }, error => {
        this.isLoading = false;
        this.message.error(error);
      });
    } else {
      this.isLoading = false;
      for (const i in this.followUpForm.controls) {
        if (this.followUpForm.controls.hasOwnProperty(i)) {
          this.followUpForm.controls[i].markAsDirty();
          this.followUpForm.controls[i].updateValueAndValidity();
        }
      }
      for (const i in (<FormArray>this.followUpForm.get('complications')).controls) {
        if ((<FormArray>this.followUpForm.get('complications')).controls.hasOwnProperty(i)) {
          for (const i1 in (<FormGroup>(<FormArray>this.followUpForm.get('complications')).controls[i]).controls) {
            if ((<FormGroup>(<FormArray>this.followUpForm.get('complications')).controls[i]).controls.hasOwnProperty(i1)) {
              (<FormGroup>(<FormArray>this.followUpForm.get('complications')).controls[i]).controls[i1].markAsDirty();
              (<FormGroup>(<FormArray>this.followUpForm.get('complications')).controls[i]).controls[i1].updateValueAndValidity();
            }
          }
        }
      }
    }
  }

  validation(data) {
    let formData = data;
    let messages = '';
    this.validateStatus = false;
    if (formData) {
      if (formData['followup_method_remark'] && this.dataService.checkSmileyValidation(data['followup_method_remark'])) {
        this.validateStatus = true;
        messages = (messages) ? messages + ' | Please enter valid specify method of follow-up! <br>' :
          'Please enter valid Specify Method of Follow-up! <br>';
      }
      if (formData['remark'] && this.dataService.checkSmileyValidation(data['remark'])) {
        this.validateStatus = true;
        messages = (messages) ? messages + ' | Please enter valid death remark! <br>' :
          'Please enter valid death remark! <br>';
      }
      if (formData['followup_disease_status_remark'] && this.dataService.checkSmileyValidation(data['followup_disease_status_remark'])) {
        this.validateStatus = true;
        messages = (messages) ? messages + ' | Please enter valid specify disease status! <br>' :
          'Please enter valid specify disease status! <br>';
      }
      if (formData['followup_diagnosis_options_remark'] &&
        this.dataService.checkSmileyValidation(data['followup_diagnosis_options_remark'])) {
        this.validateStatus = true;
        messages = (messages) ? messages + ' | Please enter valid indicate basis of diagnosis remark! <br>' :
          'Please enter valid indicate basis of diagnosis remark! <br>';
      }
      if (formData['followup_treatment_details'] &&
        this.dataService.checkSmileyValidation(data['followup_treatment_details'])) {
        this.validateStatus = true;
        messages = (messages) ? messages + ' | Please enter valid details of treatment and outcome! <br>' :
          'Please enter valid details of treatment and outcome! <br>';
      }
    }
    if (this.validateStatus) {
      this.message.warning(messages);
    }
  }

  onFormDrawer(evt: string, index, appointmentId, priorTreatmentId) {
    if (evt == 'onfollowupForm') {
      this.nzDrawerWidth = 990;
      this.nzDrawerTitle = 'Follow Up';
      this.paddingTop = '20px';
      this.drawerVisible = true;
      this.currentOpenDrawer = evt;
      this.followUpForm.get('followup_date').setValue(new Date());
      this.followUpForm.get('followup_updated_date').setValue(new Date());
      this.treatment_id = this.followUpAfterTreatmentData[index].id;
      this.patient_id = this.followUpAfterTreatmentData[index].patient_id;
      this.treatment_status = this.followUpAfterTreatmentData[index].treatment_status;
      this.followUpForm.get('appointment_id').setValue(appointmentId);
      this.followUpForm.get('prior_treatment_id').setValue(priorTreatmentId);
    }
    // console.log("followUpForm",this.followUpForm.value);
  }

  private updateFollowUpVAlidator() {
    let length = this.followUpForm.get('complications')['controls'].length;
    let _this = this;
    if (length >= 1) {
      for (let index = 0; index <= length; index++) {
        _this.removeField(0);
      }
    }
  }

  resetForm() {
    this.drawerVisible = false;
    this.followUpForm.reset();
    this.treatment_id = null;
    this.patient_id = null;
    this.treatment_status = null;
    this.followUpForm.controls['cause_of_death'].setValue(null);
    this.index = 1;
    this.current = 0;
  }


  onClose() {
    this.resetForm();
  }

  // For case sheet Doc section
  // oncaseDocSheet(data): void {
  //   this.prescrptionFormData = data;
  //   this.isVisibleCaseDocSheet = true;
  // }

  // casesheetDocCancel(): void {
  //   this.prescrptionFormData = [];
  //   this.isVisibleCaseDocSheet = false;
  // }
  // End case sheet Doc section

  onmethodfollowUp(val) {
    this.methodfollowUpField = (val == 'Others') ? true : false;
  }

  ondiseasestatus(val) {
    this.diseasestatusField = (val == 'Distant-Metastasis') ? true : false;
  }

  onindicatediagnosis(val) {
    this.indicatediagnosisField = (val == 'Others') ? true : false;
  }

  onvisitstatus(val: number) {
    this.followUpForm.controls['cause_of_death'].setValue(null);
    if (val == 3) {
      this.visitstatusField = true;
      this.followUpForm.controls['cause_of_death'].setValidators([Validators.required]);
    } else {
      this.visitstatusField = false;
    }
  }

  onfollowupTreatmentStatus(val: number) {
    this.followupTreatmentStatus = (val == 2 ? true : false);
  }

  onDeathRemark(val: number) {
    this.deathRemark = (val == 5) ? true : false;
  }

  onlatecomplications(val: number) {
    if (val == 2) {
      this.addField();
      this.latecomplicationsField = true;
    } else {
      this.updateFollowUpVAlidator();
      this.latecomplicationsField = false;
    }
  }
}
