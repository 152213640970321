import { Injectable } from '@angular/core'
import { CoreAPIService } from '../../shared/api/services';
import { RTDataFormatterService } from './data-formatter.service'
import { NzMessageService } from 'ng-zorro-antd';

@Injectable()
export class RTUtilsService {
  fetchingRtDetails: boolean = false;

  constructor(
    private coreApiServices: CoreAPIService,
    private dataFormatterService: RTDataFormatterService,
    // private message: NzMessageService,
    ) {}

    public fetchRtPatients(qParams, callback=null) {
      this.coreApiServices.api('get', 'pgi-rt-patients/get', undefined, undefined, qParams)
      .subscribe(
        res => {
          if (res && res['data'] && res['data']['success']) {
            callback && callback()
            // if(res['data'].data.rows && res['data'].data.rows.length > 0) {
            // }
            this.dataFormatterService.populatePatients(res['data'].data.rows);
            this.dataFormatterService.updatePatientsCount(parseInt(res['data'].data.rowCount));
          }
        }
      );
    }

    public fetchRTDetails(patientRtId) {
      if(!this.fetchingRtDetails) {
        this.fetchingRtDetails = true;
        this.coreApiServices.api('get', 'pgi-rt-patients/get/' + patientRtId, undefined, undefined, undefined).subscribe(
          res => {
            if (res && res['data'] && res['data']['success']) {
              this.dataFormatterService.populateRtDetails([res['data'].data]);            
            // } else {
            //   console.log(res['data']['message']);
            }
            this.fetchingRtDetails = false;
          }, error => {
            console.log(error);
            this.fetchingRtDetails = false;
          }
        );
      }
    }

}