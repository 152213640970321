import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialWorkerComponent } from './social-worker.component';
import { routing } from './social-worker.routing';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { HeaderModule } from '../core/layouts/header/header.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
  imports: [
    CommonModule,
    routing,
    NgZorroAntdModule.forRoot(),
    HeaderModule,
    LayoutsModule
  ],
  declarations: [SocialWorkerComponent]
})
export class SocialWorkerModule { }
