import {
  Component, Input, OnInit, OnChanges, Output,
  EventEmitter, ViewChild, ComponentFactoryResolver, DoCheck
} from '@angular/core';
import { DycFormsDefaults } from './dyc-forms.default';
import { DyncTemplateDirective } from './../../../directives/dync-template.directive';
import { DynamicFormsAddItem } from './dyc-forms-add-items';
import { DynamicFormsAddInterface } from './dyc-forms-add-iterface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import { DATE_FORMAT } from '../../../shared/api/base.url';
@Component({
  selector: 'dyc-forms',
  templateUrl: './dyc-forms.component.html',
  styleUrls: ['./dyc-forms.component.scss']
})
export class DycFormsComponent implements OnInit, OnChanges, DoCheck {

  @Input() data: any = {};
  @Input() editTableData: any = {};
  @Input() externalBtn: boolean = false;
  @Input() dycLayout: string;
  @Input() clearValues: boolean; // To clear form values
  @Input() dycComponent: DynamicFormsAddItem;
  @Input() setFormValue: any = {};
  @Input() setFormArrayValues: any = {};

  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() otherEvent: EventEmitter<any> = new EventEmitter();
  @Output() clearEventClose: EventEmitter<any> = new EventEmitter(); // To call clear form values event

  disableCurrentDate: Date = new Date();
  isComponentDeclare: boolean = false;
  hasSubmitButton: boolean = true;
  listJSON: any = [];
  json: any = {};
  jsonForm: any = {};
  dycForm: FormGroup;
  date_format = DATE_FORMAT;
  private typesData: any = {
    'defaultTypes': ['email', 'password', 'number', 'select', 'textarea', 'button', 'string', 'combo'],
    'dateTypes': ['date', 'time', 'datetime', 'year', 'dateRange'],
    'radioCheckTypes': ['radio', 'checkbox']
  };

  @ViewChild(DyncTemplateDirective) dycTemplate: DyncTemplateDirective;
  constructor(
    private builder: FormBuilder,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  private validationJson(validator): any {
    let validation = [];
    if (validator && validator.required)
      validation.push(Validators.required);

    if (validator && validator.pattern && validator.pattern.trim() != '') {
      if (validator.pattern.toLowerCase() === 'email') {
        validation.push(Validators.email);
      } else {
        validation.push(Validators.pattern(new RegExp(validator.pattern)));
      }
    }

    return validation;
  }

  private defaultControls(controlOptions: any, listJson: any, formData: any): any {
    let validation = (controlOptions ? this.validationJson(controlOptions) : []);

    listJson = Object.assign({
      'label': controlOptions.label,
      'value': controlOptions.defaultValue,
      'type': controlOptions.type.toLowerCase(),
      'placeholder': (controlOptions.placeholder || 'Please enter value'),
      'min': controlOptions.min,
      'max': controlOptions.max,
      'stepNumber': controlOptions.stepNumber,
      'renderFields': (controlOptions.renderFields || []),
      'isDisplayIndivisual': (controlOptions.isDisplayIndivisual === false ? false : true),
      'isEditor': (controlOptions.isEditor === true ? true : false),
      'isReadOnly': (controlOptions.isReadOnly === true ? true : false),
      'isDisabled': (controlOptions.isDisabled === true ? true : false),
      'isShowSearch': (controlOptions.isShowSearch === false ? false : true),
      'select_optionAllowClear': (controlOptions.select_optionAllowClear === true ? true : false),
    }, listJson);

    if (controlOptions.options && controlOptions.options.length > 0) {
      listJson['options'] = controlOptions.options;
    }

    if (controlOptions.multiple) {
      listJson['multiple'] = controlOptions.multiple;
    }

    return {
      'controls': [(formData || controlOptions.defaultValue || null), validation],
      'listJson': listJson
    };
  }

  private dateControls(controlOptions: any, listJson: any, formData: any): any {
    let validation = [], value = null;

    if (controlOptions.type.toLowerCase() == 'date') {
      // controlOptions['pattern'] = '((\d+){4})-((\d+){2})-((\d+){2})';
    }

    if (controlOptions.type.toLowerCase() == 'time') {
      controlOptions['pattern'] = '((\d+){2}):((\d+){2}):((\d+){2})';
    }

    if (controlOptions.type.toLowerCase() == 'datetime') {
      controlOptions['pattern'] = '((\d+){4})-((\d+){2})-((\d+){2}) ((\d+){2}):((\d+){2}):((\d+){2})';
    }

    if (formData) {
      value = new Date(formData);
    } else if (controlOptions.defaultValue.toUpperCase() === 'NOW') {
      value = new Date();
    }

    listJson = Object.assign({
      'label': controlOptions.label,
      'value': value,
      'type': controlOptions.type.toLowerCase(),
      'placeholder': (controlOptions.placeholder || 'Please entry value'),
      'format': controlOptions.format,
      'min': controlOptions.min,
      'max': controlOptions.max,
      'stepNumber': controlOptions.stepNumber,
      'renderFields': (controlOptions.renderFields || []),
      'isDisplayIndivisual': (controlOptions.isDisplayIndivisual === false ? false : true),
      // 'disabled': (controlOptions.disabled === true ? true : false),
      'disabledFuture': (controlOptions.disabledFuture === true ? true : false),
      'disabledPast': (controlOptions.disabledPast === true ? true : false),
      'isReadOnly': (controlOptions.isReadOnly === true ? true : false),
      'isDisabled': (controlOptions.isDisabled === true ? true : false),
      'select_optionAllowClear': (controlOptions.select_optionAllowClear === true ? true : false),
    }, listJson);

    validation = (controlOptions ? this.validationJson(controlOptions) : []);

    return {
      'controls': [value, validation],
      'listJson': listJson
    };
  }

  private radioCheckControls(controlOptions: any, listJson: any, formData: any): any {
    let validation = (controlOptions ? this.validationJson(controlOptions) : []);

    listJson = Object.assign({
      'value': (controlOptions.defaultValue || null),
      'type': controlOptions.type.toLowerCase(),
      'options': controlOptions.options,
      'renderFields': (controlOptions.renderFields || []),
      'isDisplayIndivisual': (controlOptions.isDisplayIndivisual === false ? false : true),
      'isReadOnly': (controlOptions.isReadOnly === true ? true : false),
      'isDisabled': (controlOptions.isDisabled === true ? true : false)
    }, listJson);

    return {
      'controls': [(formData || controlOptions.defaultValue || null), validation],
      'listJson': listJson
    };
  }

  private loadJson(json: any) {
    let jsonData = {};
    this.jsonForm = json;
    for (let key in json) {
      if (key !== 'formData' && key !== 'hasSubmitButton') {
        let data = {};
        let listJson = { 'name': key };

        if (this.typesData['defaultTypes'].indexOf(json[key].type) != -1) {
          data = this.defaultControls(json[key], listJson, this.editTableData ? this.editTableData[key] : null);
        }

        if (this.typesData['dateTypes'].indexOf(json[key].type) != -1) {
          data = this.dateControls(json[key], listJson, this.editTableData ? this.editTableData[key] : null);
        }

        if (this.typesData['radioCheckTypes'].indexOf(json[key].type) != -1) {
          data = this.radioCheckControls(json[key], listJson, this.editTableData ? this.editTableData[key] : null);
        }

        if (!data['listJson'] && data['controls'].length > 2) {
          data['controls'].forEach(ele => {
            jsonData[ele.name] = ele.controls;

            if (json[key]['order']) {
              this.listJSON[(json[key]['order'] - 1)] = ele.listJson;
            } else if (!json[key]['order']) {
              this.listJSON.push(ele.listJson);
            }
          });
        } else {
          jsonData[key] = data['controls'];

          if (json[key]['order']) {
            this.listJSON[(json[key]['order'] - 1)] = (data['listJson'] || listJson);
          } else if (!json[key]['order']) {
            this.listJSON.push(data['listJson'] || listJson);
          }
        }
      } else if (key === 'hasSubmitButton') {
        this.hasSubmitButton = json[key];
      }
    }

    this.dycForm = this.builder.group(jsonData);
    if (this.dycComponent && this.dycComponent.component && this.dycComponent.data) {
      this.dycComponent.data = {
        dycLayout: this.dycLayout,
        dycForm: this.dycForm,
        listJSON: this.listJSON,
        onChange: this.onChange,
        onOpenChange: this.onOpenChange,
        onSubmit: this.onSubmit,
        submitEvent: this.submitEvent,
        otherEvent: this.otherEvent,
        clearEventClose: this.clearEventClose,
        onDisabledPastDate: this.onDisabledPastDate,
        onDisabledFutureDate: this.onDisabledFutureDate,
        getFormControl: this.getFormControl
      };

      setTimeout(() => {
        this.loadComponent();
      }, 100);
    }
  }

  private setFormData() {
    for (let key in this.editTableData) {
      if (this.dycForm && this.dycForm.controls[key]) {
        if (this.jsonForm[key]['type'].toLowerCase() != 'checkbox') {
          this.dycForm.controls[key].setValue(this.editTableData[key]);
        }
      }
    }
  }

  private dropdownData(loadData: any, index: number = -1, callback: any = null): void {
    if (index !== -1 && index < loadData.length) {
      let loadData1 = loadData[index];
      if (loadData1.type && loadData1.type === 'dropdown') {
        for (let index = 0; index < this.listJSON.length; index++) {
          let item = this.listJSON[index];
          if (item.name === loadData1.key) {
            this.listJSON[index].value = 'Select Option';
            this.listJSON[index].options = loadData1.values;
            // this.dycForm.controls[loadData1.key].setValue('');
          }
        }

        if ((index + 1) !== loadData.length) {
          this.dropdownData(loadData, (index + 1), callback);
        }
      } else {
        for (let item of loadData) {
          this.dycForm.controls[item.key].setValue(item.value);
        }
      }
    } else if (index === -1 && loadData.type && loadData.type === 'dropdown') {
      for (let index = 0; index < this.listJSON.length; index++) {
        let item = this.listJSON[index];
        if (item.name === loadData.key) {
          this.listJSON[index].value = 'Select Option';
          this.listJSON[index].options = loadData.values;
          this.dycForm.controls[this.setFormValue.key].setValue('');
          this.dycForm.controls[loadData.key].reset();
          break;
        }
      }
    }

    if (callback && typeof callback === 'function') {
      callback(false, true);
    }
  }

  private setFormValueChanges() {
    if (this.setFormValue && this.setFormValue.length > 0) {
      this.dropdownData(this.setFormValue, 0, (err, value) => {
        //
      });
    } else if (this.setFormValue.type && this.setFormValue.type === 'dropdown') {
      this.dropdownData(this.setFormValue);
    } else {
      for (let item of this.setFormValue) {
        if (this.dycForm.controls && this.dycForm.controls[item.key] && item && item.key) {
          this.dycForm.controls[item.key].setValue(item.value);
        }
      }
    }
  }

  onDisabledPastDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.disableCurrentDate) < 0;
  }

  onDisabledFutureDate = (current: Date): boolean => {
    return differenceInCalendarDays(current, this.disableCurrentDate) > 0;
  }

  onChange(event, name, eventType = null) {
    this.otherEvent.emit({
      'event': event,
      'data': (event && event.target && event.target.value ? event.target.value : event),
      'name': name,
      'eventType': eventType
    });
  }

  onOpenChange(event, name) {
    this.onChange(event, name, 'open_change');
  }

  onSubmit(event) {
    if (this.dycForm.valid) {
      this.submitEvent.emit({
        'event': event,
        'data': this.dycForm.value,
        'editId': (this.editTableData ? this.editTableData.id : null),
        'externalBtn': false
      });
    } else {
      this.submitEvent.emit({
        'event': null,
        'data': null,
        'editId': null,
        'externalBtn': false
      });
      for (const i in this.dycForm.controls) {
        if (this.dycForm.controls.hasOwnProperty(i)) {
          this.dycForm.controls[i].markAsDirty();
          this.dycForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  // falseSubmit() {
  //   return false;
  // }

  private resetForm() {
    this.dycForm.reset();
    this.clearEventClose.emit();
  }

  loadComponent() {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dycComponent.component);
    let viewContainerRef = this.dycTemplate.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<DynamicFormsAddInterface>componentRef.instance).data = this.dycComponent.data;
  }

  ngOnInit() {
    this.isComponentDeclare = false;
    if (this.dycComponent && this.dycComponent.component && this.dycComponent.data) {
      this.isComponentDeclare = true;
    }
  }

  ngOnChanges(changes) {
    if (changes && changes.data && changes.data.currentValue) {
      let defaultConfig = Object.assign({}, DycFormsDefaults.config);
      this.loadJson(Object.assign(defaultConfig, changes.data.currentValue));
    }
    if (changes && changes.externalBtn && changes.externalBtn.currentValue === true) {
      this.onSubmit('form_save');
    }
    if (changes && changes.editTableData && changes.editTableData.currentValue) {
      this.editTableData = changes.editTableData.currentValue;
      this.setFormData();
    }
    if (changes && changes.clearValues && changes.clearValues.currentValue === true) {
      this.clearValues = changes.clearValues.currentValue;
      this.resetForm();
    }
    if (changes && changes.setFormValue && changes.setFormValue.currentValue) {
      if ('{}' === JSON.stringify(changes.setFormValue.currentValue) || changes.setFormValue.currentValue.length > 0) {
        this.setFormValue = changes.setFormValue.currentValue;
        this.setFormValueChanges();
      } else if ('{}' !== JSON.stringify(changes.setFormValue.currentValue) && changes.setFormValue.currentValue.hasOwnProperty('key')) {
        this.setFormValue = changes.setFormValue.currentValue;
        this.setFormValueChanges();
      }
    }
  }

  ngDoCheck() {
    if (this.externalBtn === true) {
      this.submitEvent.emit({
        'event': null,
        'data': null,
        'editId': null,
        'externalBtn': false
      });
    }
  }

  getFormControl(name) {
    return this.dycForm.controls[name];
  }
}
