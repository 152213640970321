import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../api/services/index';

@Injectable()
export class AuthGuard implements CanActivate {
    loading = false;

    constructor(private router: Router, private userService: UserService) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.userService.isAuthenticated()) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    logout() {
        this.loading = true;
        this.userService.logout()
            .subscribe(
                data => {
                    this.router.navigate(['login']);
                },
                error => {
                    console.error(error);
                    this.loading = false;
                    this.router.navigate(['login']);
                });
    }
}
