import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserService } from '../../shared/api/services/User';
import { NzMessagesService, SHOW_TYPES } from '../../shared/nz-message.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dataService: UserService,
    private router: Router,
    private message: NzMessagesService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.fb.group({
      email: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    if (this.forgotPasswordForm.valid) {
      this.dataService.forgotPassword(this.forgotPasswordForm.value).subscribe(
        res => {
          if (res['status'] == 'success' && res['data'] && res['data']['success']) {
            this.message.success('Please check your email, we have just send it you.');
            this.forgotPasswordForm.reset();
            if(res['data']['data'] && res['data']['data']['url']) {
              window.location = res['data']['data']['url'];
            } else {
              setTimeout(() => {
                this.router.navigate(['/login']);
              }, 2000);
            }
          } else {
            this.message.error(res['data']['message']);
          }
        }
      )
    } else {
      for (const i in this.forgotPasswordForm.controls) {
        this.forgotPasswordForm.controls[i].markAsDirty();
        this.forgotPasswordForm.controls[i].updateValueAndValidity();
      }
    }

  }
}
