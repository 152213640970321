import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DayCareUserComponent } from './day-care-user.component';
import { routing } from './day-care-user.routing';
import { FormsModule } from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeaderModule } from '../core/layouts/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    routing, 
    LayoutsModule, 
    FormsModule, 
    HeaderModule, 
    NgZorroAntdModule.forRoot()
  ],
  declarations: [DayCareUserComponent]
})
export class DayCareUserModule { }
