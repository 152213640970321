import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html'
})
export class BaseComponent {
  isCollapsed = true;
  showDebugerPanel: boolean = false;
  setLayout = 'overpage';

  constructor() { }

  // ngOnInit() {
  // }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }

}
