/* tslint:disable */

declare var Object: any;
export class UIForms {  
  constructor(data?: UIForms) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UIForms`.
   */
  public static getModelName() {
    return "UIForms";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UIForms for dynamic purposes.
  **/
  public static factory(data: UIForms): UIForms{
    return new UIForms(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UIForms',
      plural: 'ui-forms',      
      path: 'ui-forms',      
    }
  }
}
