import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontDeskUserComponent } from './front-desk-user.component'
import { routing } from './front-desk-user.routing';
import { FormsModule } from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeaderModule } from '../core/layouts/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    routing,
    LayoutsModule,
    FormsModule,
    HeaderModule,
    NgZorroAntdModule.forRoot(),
  ],
  declarations: [FrontDeskUserComponent]
})
export class FrontDeskUserModule { }
