import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutsComponent } from './layouts.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavUIModule } from '../../shared/components/nav-ui/nav-ui.module';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    RouterModule,
    NavUIModule
  ],
  declarations: [LayoutsComponent,SidebarComponent],
  exports: [LayoutsComponent,SidebarComponent],
})
export class LayoutsModule { }
