import { Pipe, PipeTransform } from '@angular/core';
import { COMMAN_STATUS, PATIENT_STATUS, APPOINTMENT_STATUS, APPOINTMENT_TYPE, DONATION_STATUS, TREATMENT_STATUS,
  RT_STATUS, DOCTOR_APPOINTMENT_STATUS, CT_STATUS, BT_STATUS, INDEMNITY_TYPES, } from '../api/base.url';

@Pipe({
  name: 'customFilter'
})
export class CustomFilterPipe implements PipeTransform {
  transform(status: string, type: string): any {
    let JSON_DATA = null;
    switch (type) {
      case ('COMMAN_STATUS'):
        JSON_DATA = COMMAN_STATUS;
      break;
      case ('PATIENT_STATUS'):
        JSON_DATA = PATIENT_STATUS;
      break;
      case ('TREATMENT_STATUS'):
        JSON_DATA = TREATMENT_STATUS;
      break;
      case ('APPOINTMENT_STATUS'):
        JSON_DATA = APPOINTMENT_STATUS;
      break;
      case ('RT_STATUS'):
        JSON_DATA = RT_STATUS;
      break;
      case ('BT_STATUS'):
        JSON_DATA = BT_STATUS;
      break;
      case ('APPOINTMENT_TYPE'):
        JSON_DATA = APPOINTMENT_TYPE;
      break;
      case ('DOCTOR_APPOINTMENT_STATUS'):
        JSON_DATA = DOCTOR_APPOINTMENT_STATUS;
      break;
      case ('CT_STATUS'):
        JSON_DATA = CT_STATUS;
      break;
      case ('INDEMNITY_TYPES'):
        JSON_DATA = INDEMNITY_TYPES;
      break;
      case ('DONATION_STATUS'):
        JSON_DATA = DONATION_STATUS;
      break;
      default:
        return 'Pipe Not Found!';
      break;
  }
    if (JSON_DATA !== null) {
      let statusVal = JSON_DATA.find(function (ele) {
        return ele.key == status;
      });
      return (statusVal) ? statusVal.label : 'N/A';
    } else {
      return 'N/A';
    }
  }

}
