/* tslint:disable */

declare var Object: any;
export class Holiday {  
  constructor(data?: Holiday) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Holiday`.
   */
  public static getModelName() {
    return "Holiday";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Holiday for dynamic purposes.
  **/
  public static factory(data: Holiday): Holiday{
    return new Holiday(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Holiday',
      plural: 'holidays',      
      path: 'holidays',      
    }
  }
}
