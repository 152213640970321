import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolBarUiComponent } from './toolbar-ui.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
  imports: [
    CommonModule, NgZorroAntdModule
  ],
  declarations: [ToolBarUiComponent],
  exports: [ToolBarUiComponent]
})
export class ToolbarUiModule { }
