// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

   webServiceUrl: '//dev.sgpgi.mightcode.com',
  // webServiceUrl: '//sgpgi-api.loc',

  // webServiceUrl: '//192.168.43.31/sgpgi-api',
  // webServiceUrl: '//rois.com',
  // webServiceUrl: '//localhost/sgpgi/sgpgi-api',
  //  webServiceUrl: '//192.168.1.57/ERPMS/SGPGI/sgpgi-api',
  // webServiceUrl: '//localhost/ERPMS/SGPGI/sgpgi-api',

  // webServiceUrl: '//192.168.1.68/kailash/sgpgi-api',
  // webServiceUrl: '//192.168.1.106/sgpgi-api',
  // webServiceUrl: '//localhost/sgpgi/sgpgi-api',
  //  webServiceUrl: '//stage.sgpgi.mightcode.com',
  // webServiceUrl: '//api.sgpgi.loc/api',
  // webServiceUrl: '//sgpgi-api.loc',
  // webServiceUrl: 'http://localhost/rahul/sgpgi-api',
  // webServiceUrl: '//192.168.1.74/sgpgi-api',
  // webServiceUrl: '//prestage.sgpgi.mightcode.com',

  apiVersion: 'api',
  importURL: 'import-export/import',
  dateFormat: 'dd-MM-yyyy',
  dateTimeFormat: 'dd-MM-yyyy HH:mm',
  logoAssetUpload: '/api/public/upload/assets/logo/',

  // UPLOAD_MULTI_IMAGES:

  phoneNumberRegex: '^([6-9][0-9]{9})$', // Mobile Number without Country Code
  alphaNumericRegex: '^[a-zA-Z0-9]+$',
  numericRegex: '^[0-9]+$',
  alphaRegex: '^[a-zA-Z]*$',
  userModule: {
    doctorDegignationId: 1,
    doctorRole: 12,
    rtDesignationId: 6,
    nurseDesignationId: 4,
    mpDesignationId: 11,
    adminDesignation: '3',
    hbcrDesignation : '7'
  },
  atsRegex: {
    applicantTalentReference: '^.{0,45}$',
    applicantTalentReferenceAlphabateLen: '^[ a-zA-Z]{1,255}$',
    anyWithRange: '^(?=.{0,45}$).*',
    alphaRegexWithSpace: '^[ a-zA-Z]*$',
    alphaNumericWithOther: '^[ a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$'
  },
  hrmsRegex: {
    empName: /^[ a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
    empNameWithoutSpace: /^[a-zA-Z0-9_]+( [a-zA-Z0-9_]+)*$/,
    phone: /^([6-9][0-9]{9})$/, // Mobile Number with Country Code
    alpha_numeric_spec: /^[a-zA-Z0-9()-]+( [a-zA-Z0-9()-]+)*$/,
  },

  commonRegex: {
    onlyLetters: /^[a-zA-Z]+$/,
    text: /^[a-zA-Z0-9+\-*_\/\.,'"@#%&$!\s\(\)]+$/,
    text_without_special_char: /^[a-zA-Z0-9+\-*.,\s]+$/,
    letterWithSpace: /^[a-zA-Z]+( [a-zA-Z]+)*$/,
    alpha_numeric_spec: /^[a-zA-Z0-9()/-]+( [a-zA-Z0-9])*$/,
    alpha_spec: /^[a-zA-Z()/-]+( [a-zA-Z()/-]+)*$/,
    zip_code: /^[0-9]{6}$/,
    alpha_numeric: /^[a-zA-Z0-9]+$/,
    alphaNumericWithoutSpace: /^[a-zA-Z0-9\_]{0,40}$/,
    numeric: /^ *[0-9]+ *$/,
    float: /^-?\d*(\.\d{0,2})?$/,
    letters: /^[a-zA-Z]*$/,
    phone: '^([6-9][0-9]{9})$', // Mobile Number without Country Code
    startString: /^[a-zA-Z]+[a-zA-Z0-9 ]*$/,
    // email: /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/, // accept: dlmk_ksmdr@wcb.lmcdl.cmm,
    // email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})/,
    email: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    passPortNo: /^[A-Z][0-9]{8}$/,
    amountNumeric: /^([1-9][0-9]*)$/,
    alpha_num_4spec: /^(?!^\.)(?!.*[-_.,\/]$)[a-zA-Z0-9().\/,-]+( [a-zA-Z0-9().\/,-]+)*$/,
    alphaRegexWithSpace: /^[ a-zA-Z]*$/,
    removeStartingSpace : /^[a-zA-Z0-9@!#$%^&*()_+|{}":<>,.\/]+[a-zA-Z0-9+\-*\/\.@#%&$<>?{}^_!\s\(\)|\/,;:]*$/,
    // letterWithSpaceDot: "/^[a-zA-Z]+((['. ][a-zA-Z ])?[a-zA-Z]*)*+\.*$/",
    // passport: /^(?!^0+$)[a-zA-Z0-9]{3,20}$/,
    // voterId: /^([a-zA-Z]){3}([0-9]){7}?$/,

    alphaNumericSpec: /^[a-zA-Z0-9]+([a-zA-Z0-9\/])+$/,
    alpha_numeric_operators: /^[a-zA-Z0-9+-.]+$/,
    one_decimal_float: /^\d+(?:\.\d)?$/,
    two_decimal_float: /^\d+(?:\.\d\d)?$/,
    phoneregex : /^ *([0-9]{10})+ *$/,
    textareaRegex: "^[A-Za-z0-9 .'?!,@$#-+_*-*^%()!]{0,301}+$",
    textncomma: /^[a-zA-Z,]+$/
  },
  regexErrors: {
    onlyLetters: 'Only letters are allowed',
    letterWithSpace: 'Only letters and spaces are allowed',
    text: `Only letters with +.,-*/@#$!()&'" are allowed`,
    address: 'Only letters with ,./() are allowed',
    numeric: 'Only numbers are allowed',
    float: 'Only numbers and one decimal (.) are allowed',
    textncomma: 'Only letters and comma are allowed',
  },

  ctRegex: {
    cycleTypeRange: /^[0-9]*(-[0-9])*([,|-]+[0-9]+)*$/
  },

  rtRegex: {
    alpha_numeric_spec: /^[a-zA-Z0-9()./-]+( [a-zA-Z0-9()./-]+)*$/,
  },

  calenderData: {
    data_primary_color: '#000000',
    data_secondary_color: '#5f5f5f',
  },

  commanStatus: [
    {
      'key': '1',
      'label': 'Active',
    },
    {
      'key': '0',
      'label': 'Inactive',
    },
    {
      'key': '3',
      'label': 'Deleted',
    },
  ],
  hbcrStatus: [
    {
      'key': '1',
      'label': 'New',
      'disabled': false
    },
    {
      'key': '5',
      'label': 'Complete',
      'disabled': false
    },
    {
      'key': '6',
      'label': 'Closed',
      'disabled': false
    },
    {
      'key': '7',
      'label': 'Inprogress',
      'disabled': false
    },
  ],

  patientStatus: [
    {
      'key': '1',//0
      'label': 'New',
      'disabled': false,
    },
    {
      'key': '2',//1
      'label': 'Follow-Up',
      'disabled': false,
    },
    {
      'key': '3',//2
      'label': 'Deceased',
      'disabled': false,
    },
    // {
    //   'key': '4',
    //   'label': 'Cured',
    //   'disabled': false,
    // },
    {
      'key': '5',//3
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '6',//4
      'label': 'Closed',
      'disabled': false,
    },
    {
      'key': '7',//5
      'label': 'Inprogress',
      'disabled': false,
    },
    {
      'key': '4',//6
      'label': 'Refer Out RI',
      'disabled': false,
    },
    // {
    //   'key': '9',
    //   'label': 'Payment-Pending',
    //   'disabled': false,
    // },
    // {
    //   'key': '8',
    //   'label': 'Rescheduled',
    //   'disabled': true,
    // },

  ],

  rtStatus: [
    {
      'key': '1',
      'label': 'New',
      'disabled': true,
    },
    {
      'key': '2',
      'label': 'Follow-Up',
      'disabled': true,
    },
    {
      'key': '3',
      'label': 'Deceased',
      'disabled': true,
    },
    {
      'key': '5',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '6',
      'label': 'Closed',
      'disabled': false,
    },
    {
      'key': '7',
      'label': 'Inprogress',
      'disabled': true,
    },
    // {
    //   'key': '8',
    //   'label': 'Rescheduled',
    //   'disabled': true,
    // },
  ],

  bttrtStatus: [
    {
      'key': '1',
      'label': 'New',
      'disabled': true,
    },
    {
      'key': '2',
      'label': 'Follow-Up',
      'disabled': true,
    },
    {
      'key': '3',
      'label': 'Deceased',
      'disabled': true,
    },
    {
      'key': '5',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '6',
      'label': 'Closed',
      'disabled': false,
    },
    {
      'key': '7',
      'label': 'Inprogress',
      'disabled': true,
    },
    {
      'key': '8',
      'label': 'Rescheduled',
      'disabled': true,
    },
  ],

  btStatus: [
    {
      'key': '1',
      'label': 'Booked',
      'disabled': true,
    },
    {
      'key': '2',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '3',
      'label': 'Missed',
      'disabled': false,
    },
    {
      'key': '4',
      'label': 'Cancelled', 
      'disabled': false,
    }
  ],

  rtTreatmentStatus: {
    'New': '1',
    'Follow-Up': '2',
    'Deceased': '3',
    'Completed': '5',
    'Closed': '6',
    'Inprogress': '7',
    'Rescheduled': '8'
  },
  appointmentStatus: [
    {
      'key': '1',
      'label': 'Booked',
      'disabled': true,
    },
    {
      'key': '2',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '3',
      'label': 'Missed',
      'disabled': false,
    },
    {
      'key': '4',
      'label': 'Cancelled', // according to the changes Cancelled => Closed //revert
      'disabled': false,
    },
    {
      'key': '5',
      'label': 'Arrived',
      'disabled': false,
    },
    {
      'key': '6',
      'label': 'Referred',
      'disabled': false,
    },
  ],

  doctorAppointmentStatus: [
    {
      'key': '2',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '3',
      'label': 'Missed',
      'disabled': false,
    },
    {
      'key': '4',
      'label': 'Cancelled', // according to the changes Cancelled => Closed //revert
      'disabled': false,
    },
    {
      'key': '6',
      'label': 'Referred',
      'disabled': false
    },
  ],

  // appointmentType: [
  //   {
  //     'key': '1',
  //     'label': 'Opd',
  //   },
  //   {
  //     'key': '2',
  //     'label': 'RT',
  //   },
  //   {
  //     'key': '3',
  //     'label': 'CT',
  //   },
  // ],
  appointmentTypes: {
    'Opd Follow-Up': {
      key: '1',
      type: 'Opd'
    },
    'RT Simulation': {
      key: '2',
      type: 'RT'
    },
    'RT Treatment': {
      key: '3',
      type: 'RT'
    },
    'CT': {
      key: '4',
      type: 'CT'
    },
    'Opd New': {
      key: '5',
      type: 'Opd New'
    },
  },

  appointmentType: [
    {
      'key': '1',
      'label': 'OPD Follow-Up',
      'type': 'Opd',   // This is for follow up/ in treatment patient.
    },
    {
      'key': '2',
      'label': 'RT Simulation',
      'type': 'RT',
    },
    {
      'key': '3',
      'label': 'RT Treatment',
      'type': 'RT',
    },
    // {
    //   'key': '2',
    //   'label': 'RT', // this RT key only use on frontend at some place  otherwise follow is opd => 1, simulation => 2, treatment => 3, ct => 4 //
    //   'type': [
    //     {
    //       'key': '2',
    //       'label': 'Simulation',
    //     },
    //     {
    //       'key': '3',
    //       'label': 'Treatment',
    //     }],
    // },
    {
      'key': '4',
      'label': 'CT',
      'type': 'CT',
    },
    {
      'key': '5',
      'label': 'OPD New',
      'type': 'Opd New',  // This is for new patient.
    },
  ],
  


  donationStatus: [
    {
      'key': '1',
      'label': 'new',
    },
    {
      'key': '2',
      'label': 'approved',
    },
    {
      'key': '3',
      'label': 'rejected',
    },
    {
      'key': '4',
      'label': 'cancelled',
    }
  ],

  pgiTreatmentStatus: [
    {
      'key': '1',
      'label': 'New',
    },
    {
      'key': '2',
      'label': 'Follow-Up',
    },
    {
      'key': '3',
      'label': 'Deceased',
    },
    // {
    //   'key': '4',
    //   'label': 'Cured',
    // },
    {
      'key': '5',
      'label': 'Completed',
    },
    {
      'key': '6',
      'label': 'Closed',
    },
    {
      'key': '7',
      'label': 'Inprogress',
    },
  ],

  ctStatus: [
    {
      'key': '1',
      'label': 'New',
      'disabled': true,
    },
    {
      'key': '2',
      'label': 'Follow-Up',
      'disabled': true,
    },
    {
      'key': '3',
      'label': 'Deceased',
      'disabled': true,
    },
    {
      'key': '5',
      'label': 'Completed',
      'disabled': false,
    },
    {
      'key': '6',
      'label': 'Closed',
      'disabled': false,
    },
    {
      'key': '7',
      'label': 'Inprogress',
      'disabled': true,
    },
    {
      'key': '8',
      'label': 'Rescheduled',
      'disabled': false,
    },
  ],
  

  indemnity_types: [
    {
      key: '1',
      label: 'Voter ID'
    },
    {
      key: '2',
      label: 'Aadhar Card'
    },
    {
      key: '3',
      label: 'Passport'
    },
    {
      key: '4',
      label: 'Driving Licence'
    },
    {
      key: '5',
      label: 'Other'
    },
  ],

  nameOfParticipatingCentre: 'Sanjay Gandhi Post Graduate Institute',
  
  codeOfParticipatingCentre: 'C012',
  hospitalRegistrationNumber: 'HSPTL9586',

  redirectUrl: {
    1: '/app/rt-patient-treatment',
    12: '/doctors/today-slots',
    11: '/front-desk-user/scheduled-slots',
    13: '/rt/simulation-scheduled-slots',
    14: '/social-worker/donation-request',
    15: '/day-care/today-schedule',
    17: '/app/hbcr',
  },

  auth_types: {
    0: 'none',
    1: 'manual',
    2: 'biometric'
  },

  rtSimulationSteps: {
    'position_scanning': 1,
    'image_fusion': 2,
    'delineation_and_verification': 3,
    'dose_prescription': 4,
    'plan_generation': 5,
    'plan_accepted': 6,
    'plan_reviewed': 7,
    'simulation_done': 10,
  }

  /* end comman status*/
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
