import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './base.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'app',
    component: BaseComponent,
    children: [
      { path: '', redirectTo: 'setting', pathMatch: 'full' },
      { path: 'setting', loadChildren: './setting/setting.module#SettingModule' },
      { path: 'email-template', loadChildren: './email-template/email-template.module#EmailTemplateModule' },
      { path: 'master-entry', loadChildren: './master-entry/master-entry.module#MasterEntryModule' },
      { path: 'navigation', loadChildren: './navigation/navigation.module#NavigationModule' },
      { path: 'myprofile', loadChildren: './my-profile/my-profile.module#MyProfileModule' },
      // { path: 'users', loadChildren: './user/user.module#UserModule' },
      { path: 'users', loadChildren: '../hr/employee/employee.module#EmployeeModule' },
      { path: 'overload-time-slot-appointment', loadChildren: './overload-time-slot-appoinment/overload-time-slot-appoinment.module#OverloadTimeSlotAppoinmentModule' },
      { path: 'services', loadChildren: './services/services.module#ServiceModule' },
      { path: 'build-forms', loadChildren: './build-forms/build-form.module#BuildFormModule' },
      { path: 'acl', loadChildren: './acl/acl.module#AclModule' },
      { path: 'role', loadChildren: './roles/roles.module#RolesModule' },
      { path: 'holidays', loadChildren: '../hr/holiday/holiday.module#HolidayModule' },
      { path: 'reports', loadChildren: './reports/reports.module#ReportsModule' },
      { path: 'dashboard', loadChildren: '../base/dashboard/dashboard.module#DashboardModule' },
      { path: 'rt-patient-treatment', loadChildren: '../base/rt-patient-treatment/rt-patient-treatment.module#RtPatientTreatmentModule' },
      { path: 'hbcr', loadChildren: './hbcr-patient-list/hbcr-patient-list.module#HbcrPatientListModule' },
      { path: 'patients', loadChildren: '../base/patients/patients.module#PatientsModule' },
      { path: 'archive-patients', loadChildren: './archive-patient/archive-data.module#ArchiveDataModule' },
      { path: 'nav-setting', loadChildren: './nav-setting/nav-setting.module#NavSettingModule' },
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
