import { Component, OnInit, Output, Input, OnChanges, EventEmitter } from '@angular/core';
import { CompactType, DisplayGrid, GridsterConfig, GridsterItem, GridType } from 'angular-gridster2';
import { DefaultConfig } from './default-config';

@Component({
  selector: 'widgets-ui',
  templateUrl: './widgets-ui.component.html',
  styleUrls: ['./widgets-ui.component.scss']
})
export class WidgetUiComponent implements OnInit, OnChanges {

  @Input() options: any;
  @Input() formData: any;
  @Input() grid: any;
  @Input() widgetData: any;
  @Input() widgetsLoader: boolean = false;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  layoutArray: any = {};
  defaultLayout: number = 24;
  dashbordOption: Array<GridsterConfig> = [];
  dashboard: Array<GridsterItem>;

  constructor() { }

  getDataColumn(columns: any): any {
    return Object.values(columns);
  }

  onEvent(event: any, action: string, data: any) {
    this.clickEvent.emit({
      'event': event,
      'action': action,
      'data': data
    });
  }

  ngOnInit() {
    this.dashbordOption = Object.assign(Object.assign({}, DefaultConfig.config.defaults), this.grid);
  }

  ngOnChanges(changes) {
    if (changes && changes.options && changes.options.currentValue) {
      this.options = changes.options.currentValue

      this.options.data.forEach((ele, idx) => {
        if (ele.layoutDivision > 1) {
          this.layoutArray['lt' + idx] = (this.defaultLayout / ele.layoutDivision);
        } else if (ele.layoutDivision > 0 && ele.layoutDivision < 1) {
          this.layoutArray['lt' + idx] = (this.defaultLayout * ele.layoutDivision);
        }
      });
    }

    if (changes && changes.grid && changes.grid.currentValue) {
      this.grid = changes.grid.currentValue;
      this.dashbordOption = Object.assign(Object.assign({}, DefaultConfig.config.defaults), this.grid);
    }

    if (changes && changes.widgetData && changes.widgetData.currentValue) {
      this.widgetData = changes.widgetData.currentValue;
    }
  }
}
