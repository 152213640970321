/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
// import { NgModule, ModuleWithProviders } from '@angular/core';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable, Subject } from 'rxjs';
// import { catchError, map, filter } from 'rxjs/operators';
// import { MasterEntry } from '../models/MasterEntry';


/**
 * Api services for the `MasterEntry` model.
 */
@Injectable()
export class MasterEntryService extends BaseLoopBackApi {
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }

  /**
     * @method find
     * @author Jonathan Casarrubias <t: johncasarrubias, gh: mean-expert-official>
     * @license MIT
     * @return {Observable<T[+>}
     * @description
     * Generic find method
     */
  public findMaster<T>(masterPath: string, reqParams: any = {}, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      //   this.model.getModelDefinition().plural,
      masterPath,
      'get'
    ].join('/'), undefined, reqParams, undefined, null, customHeaders)//Customized/RAM/
    //OLD Code/ ].join('/'), undefined, { filter }, undefined, null, customHeaders)
    // .map((datum: T[]) => datum.pipe(map((data: T) => this.model.factory(data))));
    //.map((datum: any) => datum.pipe(map((data: T) => this.model.factory(data))));
  }

  /**
   * @method create
   * @author Jonathan Casarrubias <t: johncasarrubias, gh: mean-expert-official>
   * @license MIT
   * @param {T} data Generic data type
   * @return {Observable<T>}
   * @description
   * Generic create method
   */
  public createMaster<T>(masterPath: string, data: T, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      masterPath,
      'post'
    ].join('/'), undefined, undefined, { data }, null, customHeaders);
  }

  public deleteById<T>(masterPath: string, id: any, customHeaders?: Function): Observable<T> {
    return this.request('DELETE', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      // this.model.getModelDefinition().path,
      masterPath,
      'delete/:id'
    ].join('/'), { id }, undefined, undefined, null, customHeaders);
  }

  public updateMaster<T>(masterPath: string, id: any, data: T, customHeaders?: Function): Observable<T> {
    return this.request('PUT', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      masterPath,
      'put/:id'
    ].join('/'), { id }, undefined, { data }, null, customHeaders);
  }

  /**
   * The name of the model represented by this $resource,
   * i.e. `MasterEntry`.
   */
  public getModelName() {
    return "MasterEntry";
  }
}
