import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[dync-templates]'
})
export class DyncTemplateDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
