import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  constructor(private fb: FormBuilder, private router:Router) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      newPassword: [null],
      confirmPassword: [null],
    });
  }
  submitForm(): void {

    this.login();
  }

  login() {
  }
}
