import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavUIComponent } from './nav-ui.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule, NgZorroAntdModule, RouterModule
  ],
  declarations: [NavUIComponent],
  exports: [NavUIComponent]
})
export class NavUIModule { }