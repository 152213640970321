import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'nav-ui',
  templateUrl: './nav-ui.component.html',
  styleUrls: ['./nav-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavUIComponent implements OnInit {

  private navConfig: any;
  @Input() menuData: any = null;
  @Input() params: any = null;
  @Input() isCollapsed: boolean = false;
  @Output() eventFire: EventEmitter<any> = new EventEmitter();

  loadDefault: any;

  constructor(private ref: ChangeDetectorRef) {
    // this.navConfig = NavConfig.config;
    setInterval(() => {
      if(!this.ref['destroyed']){
        this.ref.detectChanges();
      }
    }, 1000);
  }

  doClickEvent(event, element) {
    let ele = element;
    if (ele && ele.name) {
      ele.name = ele.name.toLowerCase();
    }
    this.eventFire.emit({
      'e': event,
      'element': ele
    });
  }

  ngOnInit() {
  }

}
