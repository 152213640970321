import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { AuthGuard } from '../shared/api/auth.guard';
import { routing } from './hr.routing';
import { HrComponent } from './hr.component';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { DebugerModule } from '../core/debuger/debuger.module';
import { DialogUploadUiModule } from '../shared/components/dialog-upload-ui/dialog-upload-ui.module';
import { HeaderModule } from '../core/layouts/header/header.module';
import { NzMessagesService } from '../shared/nz-message.service';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    routing,
    LayoutsModule,
    NgZorroAntdModule.forRoot(),
    DebugerModule,
    DialogUploadUiModule
  ],
  declarations: [HrComponent],
  providers: [AuthGuard],

})
export class HrModule {
}
