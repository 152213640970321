import { Component, OnInit, Input } from '@angular/core';
import { NavConfig } from '../../../shared/components/nav-ui/NavConfig';
import { RemoteConfig } from '../../../shared/components/nav-ui/RemoteConfig';
import { NavService } from '../../../shared/api/services/';
import { BrowserdbService } from '../../../shared/api/frontend/service/browserdb.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() isCollapsed: boolean = true;

  openMap = {
    sub2: false,
    sub3: false
  };

  navConfig: any;
  appID: string = '40'

  constructor(
    private navService: NavService,
    private browserDB: BrowserdbService
  ) { }

  handleEvent(event) {
  }

  ngOnInit() {
    let navConfig = new NavConfig();
    // this.navConfig = RemoteConfig.config['data']['data'];//navConfig.getMenuItem('leftMenu');
    this.loadNavConfig();
  }

  loadNavConfig(app = null) {
    this.browserDB.dbGetByID(this.appID, (e, d) => {
      if (!e && d) {
        // let str = this.router.url;
        // let arr = d['items'];
        // arr.forEach(function(value, key) {
        //   let re = arr[key].routerLink;
        //   // if (re.indexOf(str) === -1 ) {
        //   if (str.search(re) === -1 ) {
        //     arr[key].item_options.isDefault = false;
        //   } else {
        //     arr[key].item_options.isDefault = true;
        //   }
        // });
        this.navConfig = d;
      } else {
        this.navService.findNAVById(this.appID).subscribe(
          res => {
            if (res['data'] && res['data']['success']) {
              let data = res['data']['data'];
              data['inlineCollapsed'] = true;
              this.navConfig = data;
              this.browserDB.dbSave(this.navConfig, parseInt(this.appID), "menu");
            } else {
              console.log("Error:" + res['data']['message']);
            }
          }
        );
      }
    });
  }

  toggleCollapsed(evt): void {
    this.isCollapsed = !this.isCollapsed;
  }

  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }

}
