import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customGlobalFilter'
})
export class CustomGlobalFilterPipe implements PipeTransform {

  transform(status: string, JSON_ARRAY: any, JSON_ARRAY_KEY_NAME: string, JSON_ARRAY_RETURN_NAME: string): any {
    // console.log('JSON_ARRAY', status, JSON_ARRAY, JSON_ARRAY_KEY_NAME, JSON_ARRAY_RETURN_NAME);
    let statusVal = JSON_ARRAY.find(function (ele) {
      return ele[JSON_ARRAY_KEY_NAME] == status;
    });
    return (statusVal) ? statusVal[JSON_ARRAY_RETURN_NAME] : 'N/A';
  }

}
