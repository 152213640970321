import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showDimensions'
})
export class ShowDimensions implements PipeTransform {
 
  transform(jsonStr: string): any {
   let dimensions = JSON.parse(jsonStr);
   console.log('dimensions',dimensions,Object.keys(dimensions).values);
   if(dimensions){
     return "X: "+(dimensions.X ? dimensions.X : 'N/A')+", Y: "+(dimensions.Y ? dimensions.Y : 'N/A');
   }else{
     return "";
   }
  }

}
