import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthGuard } from '../shared/api/auth.guard';
import { MailComponent } from './mail.component';
import { routing } from './mail.routing';

import {FormsModule} from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CKEditorModule } from 'ngx-ckeditor';
import { HeaderModule } from '../core/layouts/header/header.module';

@NgModule({
  imports: [
    CommonModule, routing, LayoutsModule, FormsModule, CKEditorModule, HeaderModule, NgZorroAntdModule.forRoot()
  ],
  declarations: [MailComponent],
  providers: [AuthGuard],
})
export class MailModule { }
