import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debuger',
  templateUrl: './debuger.component.html',
  styleUrls: ['./debuger.component.scss']
})
export class DebugerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
