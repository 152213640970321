import { Routes, RouterModule } from '@angular/router';
import { RtComponent } from './rt.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'rt',
    component: RtComponent,
    children: [
      { path: '', redirectTo: 'simulation-treatment', pathMatch: 'full' },
      { path: 'simulation-treatment', loadChildren: './simulation-treatment/simulation-treatment.module#SimulationTreatmentModule' },
      { path: 'simulation-scheduled-slots', loadChildren: './rt-scheduled-slots/rt-scheduled-slots.module#RtScheduledSlotsModule' },
      { path: 'treatment-scheduled-slots', loadChildren: './rt-scheduled-slots/rt-scheduled-slots.module#RtScheduledSlotsModule' },
      { path: 'check-availability', loadChildren: './rt-check-availability/rt-check-availability.module#RtCheckAvailabilityModule' },
      { path: 'rt-my-appointments', loadChildren: './rt-my-appointments/rt-my-appointments.module#RtMyAppointmentsModule'}
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);