import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.css']
})
export class LockScreenComponent implements OnInit {
  public lockScreenForm: FormGroup;
  spinLoader: boolean = false;
  
  constructor( private fb: FormBuilder, ) { }

  ngOnInit() {
    this.lockScreenForm = this.fb.group({
      password: [null],
    });
  }

}
