import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateAge'
})

export class CalculateAgePipe implements PipeTransform {
  transform(dob: string): any {
    let todaysDate = new Date();
    let patientsDob = new Date(dob);
    let ageDiff = todaysDate.getTime() - patientsDob.getTime();
    let age = new Date(ageDiff);
    let absAge = Math.abs(age.getUTCFullYear() - 1970);
    return absAge ? absAge + ' Years' : absAge;
  }

}
