import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/api/services/User';
import { LoopBackAuth } from '../../shared/lib/services/core/auth.service';
import { NzMessagesService, SHOW_TYPES } from '../../shared/nz-message.service';
import { REDIRECTURL } from '../../shared/api/base.url';
import { createCaptcha, validateCaptcha } from '../../helpers/validation';
import { CoreAPIService } from '../../shared/api/services';

import { LoginConfig, ApplicationSetting } from '../../helpers/model';
import { UtilsService } from '../../helpers/utils.service';
import { DataFormatterService } from '../../helpers/data-formatter.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  public loginForm: FormGroup;
  public otpForm: FormGroup;
  public spinLoader: boolean = false;
  public validCaptcha: boolean = false;
  public otp_verification: boolean = false;
  public is_invalid_otp: boolean = false;
  public is_otp_sent: boolean = false;
  public otp: number = 123456;
  // public loginSetting: any = [];
  // logo settings
  appSettingsSubscription: Subscription;
  appSettings: ApplicationSetting = new ApplicationSetting();
  
  public loginConfig: LoginConfig = new LoginConfig();

  // biometric
  public is_biometric: boolean = false;
  private redirctURL: string = null;
  defaultRoot: string = '/dashboard';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private userService: UserService,
    private loopbackAuth: LoopBackAuth,
    private message: NzMessagesService,
    private dataService: CoreAPIService,
    private util: UtilsService,
    private dataFormatter: DataFormatterService,
    private activateRoute: ActivatedRoute,
  ) {
    activateRoute.queryParams.subscribe(d => {
      this.redirctURL = (d.returnUrl ? d.returnUrl : this.defaultRoot);
      // this.refLogin(d.refId);
      if (loopbackAuth.getToken().id && this.redirctURL && this.redirctURL != null) {
        this.router.navigate([this.redirctURL]);
      }
    });
  }

  ngOnInit() {
    // this.loadLogoTitleSetting();
    this.loginForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      remember: [true],
      is_biometric: [false],
      captcha: [null]
    });
    this.otpForm = this.fb.group({
      otp: [null, [Validators.required,Validators.maxLength(6)]],
    });

    this.loadLoginSetting();
    this.appSettingsSubscription = this.dataFormatter.getApplicationSettings().subscribe(
      res => {
        if(res.favicon_image || res.logo_image || res.menu_image || res.project_full_name || res.project_title || res.watermark_image) {
          this.appSettings = res;
          //console.log(this.appSettings);
        } else {
          this.util.fetchAppSettings();
        }
      }
    )
  }

  ngAfterViewInit() {
    this.createCaptcha();
  }

  createCaptcha() {
    createCaptcha('captcha');
    this.loginForm.get('captcha').setValue(null);
  }

  switchForm() {
    setTimeout(() => {
      this.createCaptcha();
    }, 200);
  }

  checkCaptcha() {
    if (this.loginConfig.login_captcha) {
      validateCaptcha('captcha', this.loginForm.get('captcha').value, status => {
        if (status) {
          this.login();
        } else {
          this.loginForm.get('captcha').markAsDirty();
          this.loginForm.get('captcha').setErrors({ 'valid': false });
        }
      });
    } else {
      this.login();
    }
  }

  onCaptchaKeypress = (e) => {
    // console.log(e)
    // e.preventDefault();
    if(e.keyCode == 13) {
      //console.log('enter pressed')
      this.checkCaptcha();
      return false;
    }
    return true;
  }

  sendOTP() {
    this.is_otp_sent = false;
    // this.otp = Math.floor(Math.random()*100000);
    console.clear();
    //console.log(this.otp);
    setTimeout(() => {
      this.is_otp_sent = true;
    }, 300);
  }

  verifyOTP() {
    if (this.otpForm.valid) {

      let formData = this.otpForm.value;
      let OtpFormData = this.loginForm.value;
      OtpFormData['otp'] = formData.otp;

        this.userService.verifyOtp(OtpFormData).subscribe(
          data => {
            //console.log("otp",data);
            if (data.id && data.user.is_otp == '0' && data.user.enc_key) {
              // let rediUrl = (REDIRECTURL[data.user.role] != undefined) ? REDIRECTURL[data.user.role] : '/dashboard'; 
              // this.router.navigate([rediUrl]);
              this.redirctURL = (data['user'].hasOwnProperty('landing_root') && data['user']['landing_root']) ? data['user']['landing_root'] : this.redirctURL;
              if (this.redirctURL && this.redirctURL != null) {
                this.router.navigate([this.redirctURL]);
              }
            } else if (data.encKey && data.dataToken) {
              this.router.navigate(['/reset-password/', data.encKey, data.dataToken]);
            } else {
              //this.otp_verification = false;
              if (!data.success) {
                this.message.error(data.message);
              } else {
                this.message.error('Error occour');
              }
              this.spinLoader = false;
            }
        },
          error => {
            this.message.showMessage(SHOW_TYPES.ERROR, 'Critical Error:' + error['message'], error.errorDev.errorCode);
            this.spinLoader = false;
          });
    } else {
      for (const i in this.otpForm.controls) {
        if (this.otpForm.controls.hasOwnProperty(i)) {
          this.otpForm.controls[i].markAsDirty();
          this.otpForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  login(biometric = null) {
    if (this.loginForm.valid) {
      let loginData = this.loginForm.value;
      delete loginData.captcha;
      this.logInApi(loginData);
    } else {
      for (const i in this.loginForm.controls) {
        if (this.loginForm.controls.hasOwnProperty(i)) {
          this.loginForm.controls[i].markAsDirty();
          this.loginForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  private logInApi(loginData, section= null) {
    this.spinLoader = true;
    loginData['otp_verification'] = this.loginConfig.otp_verification;
    this.userService.login(loginData).subscribe(
      data => {
            // console.log(data);
        if (this.loginConfig.otp_verification) {
          if(data.otp_sent){
            this.otp_verification = true;
            this.is_otp_sent = true;
            this.spinLoader = false;
            this.message.success(data.message);
          }
          //this.sendOTP();
        }else{

          if (data.id && data.user.is_otp == '0' && data.user.enc_key) {
            // let rediUrl = (REDIRECTURL[data.user.role] != undefined) ? REDIRECTURL[data.user.role] : '/dashboard'; 
            // this.router.navigate([rediUrl]);
            this.redirctURL = (data['user'].hasOwnProperty('landing_root') && data['user']['landing_root']) ? data['user']['landing_root'] : this.redirctURL;
            if (this.redirctURL && this.redirctURL != null) {
              this.router.navigate([this.redirctURL]);
            }
          } else if (data.encKey && data.dataToken) {
            this.router.navigate(['/reset-password/', data.encKey, data.dataToken]);
          } else {
            this.otp_verification = false;
            if (section && section['biometric']) {
              createCaptcha('captcha');
            }
            if (!data.success) {
              this.message.error(data.message);
            } else {
              this.message.error('Error occour');
            }
            this.spinLoader = false;
          }
        }
      },
      error => {
        this.message.showMessage(SHOW_TYPES.ERROR, 'Critical Error:' + error['message'], error.errorDev.errorCode);
        this.spinLoader = false;
      }
    );
  }

  onActioncancel(obj: Object) {
    this.is_biometric = obj['isVisible'];
  }

  onActionSuccess(obj: Object) {
    if (obj['authentication']) {
      this.onSubmit(obj);
    }
  }
  private onSubmit(userData) {
    let loginData = { employee_id: userData['user']['employee_id'], user_id: userData['user']['user_id'],
    userFingerPrint: userData['userFingerPrint'], is_biometric: true };
    this.logInApi(loginData, 'biometric');
  }

  private loadLoginSetting() {
    this.dataService.api('get', 'pgi-user/get-login-pref/get', undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          // this.loginSetting = res['data']['data']['rows']; // get ManipulateData
          res['data']['data']['rows'].forEach(row => {
            this.loginConfig[row.key] = row.value;
          });

        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.error('Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  resendOtp(){

    let formData = this.loginForm.value;

    this.dataService.api('get', 'pgi-user/verify-otp/resend', undefined, undefined, undefined, [{"email":formData['email']}]).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
         
          this.message.success(res['data']['data']['message']);
        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.error('Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  ngOnDestroy() {
    this.appSettingsSubscription && this.appSettingsSubscription.unsubscribe();
  }
}
