import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd';
import { CoreAPIService } from '../../api/services/CoreService';
import { AUTH_TYPE } from '../../api/base.url';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';



@Component({
  selector: 'app-auth-system',
  templateUrl: './auth-system.component.html',
  styleUrls: ['./auth-system.component.scss']
})
export class AuthSystemComponent implements OnInit {
  mpSpin: boolean = false;
  timeOutStatus: any = false;
  biometricMatchConfirm: boolean = false;
  dbUserFingersData: any = [];
  authType: any = [];
  filteredIdOptions: any = [];
  dbUserFinger: any = null;
  dbSingleUser: any = null;
  currentEmpId: any = null;
  modelType = null; // biometric(fingerprint)/manual/none
  authenticationForm: FormGroup;
  @Input() isVisible: boolean = false;
  @Input() sectionCode: string = null;
  @Input() step: number = null;
  @Input() is_edited: boolean = false;
  @Output() onActioncancel: EventEmitter<any> = new EventEmitter();
  @Output() onActionSuccess: EventEmitter<any> = new EventEmitter();
  constructor(
    private coreAPIService: CoreAPIService,
    private message: NzMessageService,
    private fb: FormBuilder,
  ) {
    this.authenticationForm = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
      // step: [null],
      // is_edited: [null]
    });
  }

  ngOnInit() {
    this.getAuthData();
  }

  getAuthData() {
    if (this.sectionCode != null) {
      if (this.dbUserFingersData.length <= 0) {
        this.coreAPIService.api('get', 'employees/get-all-finger-templates/' + this.sectionCode, undefined, undefined,
          undefined, undefined).subscribe(rest => {
            if (rest && rest['status'] == 'success' && rest['data']) {
              this.dbUserFingersData = rest['data']['users_data'];
              this.authType = rest['data']['auth_type'];
              this.modelType = AUTH_TYPE[this.authType['section_option']];
            } else {
              this.message.warning(rest['data']['message'] || rest['message'], { nzDuration: 1000 });
            }
          },
            error => {
              this.message.error('Critical Error: ' + error['message'], error);
            }
          );
      }
    } else {
      this.message.error('Auth section code is required!', { nzDuration: 2000 });
    }
  }

  okFingerPrint() {
    let returnData = this.dbUserFingersData.filter(row => {
      return row.employee_id === this.currentEmpId;
    });
    this.dbSingleUser = (returnData && returnData[0]) ? returnData[0] : null;
    this.dbUserFinger = (this.dbSingleUser) ? this.dbSingleUser['finger_template'] : null;
    if (!this.dbUserFinger) {
      this.message.error('Finger print auth is not found on this Nurse!', { nzDuration: 2000 });
    } else if (this.dbUserFinger) {
      let dd = this.deviceDescovery();
      if (dd) {
        this.isVisible = true;
        setTimeout(() => { this.bioMetricCapture(); }, 200);
      }
    }
  }

  okManualAuth() {
    let returnData = this.dbUserFingersData.filter(row => {
      return row.email === this.currentEmpId;
    });
    this.dbSingleUser = (returnData && returnData[0]) ? returnData[0] : null;
  }

  onInput(phrase: string): void {
    this.dbSingleUser = null;
    this.dbUserFinger = null;
    let returnData = [];
    if (phrase) {
      if (this.modelType === 'biometric') {
        returnData = this.dbUserFingersData.filter(row => {
          let ass = row.label.toLowerCase().includes(phrase.toLowerCase());
          return (ass > -1) ? ass : [];
        });
      } else if ('manual') {
        returnData = this.dbUserFingersData.filter(row => {
          let ass = row.email.toLowerCase().includes(phrase.toLowerCase());
          return (ass > -1) ? ass : [];
        });
      }
    } else {
      returnData = [];
    }
    this.filteredIdOptions = returnData;
  }

  handleCancel(): void {

    this.isVisible = false;
    this.onActioncancel.emit({ 'isVisible': false });
  }

  private bioMetricCapture() {
    if (!this.dbUserFinger) {
      this.message.error('Finger print auth is not found for this user!', { nzDuration: 2000 });
    } else {
      this.timeOutStatus = false;
      this.biometricMatchConfirm = false;
      let quality = 60; //(1 to 100) (recommanded minimum 55)
      let timeout = 10; // seconds (minimum=10(recommanded), maximum=60, unlimited=0 )
      let mfs100 = new window['MFS100V9026'];

      let res = mfs100.CaptureFinger(quality, timeout);
      // let res = { httpStaus: true, data: { ErrorCode: '0', IsoTemplate: 'abcdefghijk-bio' } }; // dummy
      if (res.httpStaus) {
        if (res.data.ErrorCode == "0") {
          // this.bioMetricData.initMetricData = res.data;
          // let match = mfs100.VerifyFinger(this.dbUserFinger, res.data['IsoTemplate']);
          let match = mfs100.VerifyFinger(this.dbUserFinger, res.data['AnsiTemplate']);
          if (match.httpStaus) {
            if (match.data.Status) {
              // this.onSubmit();
              this.biometricMatchConfirm = true;
              let obj = { 'authentication': true, 'step': this.step, 'is_edited': this.is_edited, 'user': this.dbSingleUser,
              'userFingerPrint': res.data['AnsiTemplate'] };
              if (this.step) {
                this.stepCheck(obj); // custom function
              } else {
                this.onActionSuccess.emit(obj);
                this.isVisible = false;
              }
              this.onActioncancel.emit({ 'isVisible': false });
              // this.message.success('Congratulations, Authrization successfully done!');
            } else {
              this.timeOutStatus = true;
              this.onActionSuccess.emit({ 'authentication': false });
              if (match.data.ErrorCode != "0") {
                this.message.warning(match.data.ErrorDescription, { nzDuration: 2000 });
              } else {
                this.message.warning('Unmatch finger print! <br> Please try again!', { nzDuration: 2000 });
              }
            }
          } else {
            this.timeOutStatus = true;
            this.onActionSuccess.emit({ 'authentication': false });
            this.biometricMatchConfirm = false;
            this.message.warning('Unmatch finger print! <br> Please try again!', { nzDuration: 1000 });
          }
        } else if (res.data.ErrorCode == "-1140") {
          this.onActionSuccess.emit({ 'authentication': false });
          this.timeOutStatus = true;
          this.message.warning(res.data.ErrorDescription + ' finger print scan!', { nzDuration: 1000 });
        } else if (res.data.ErrorCode == "-1307") {
          this.onActionSuccess.emit({ 'authentication': false });
          this.message.error('Device is not found!', { nzDuration: 2000 });
        }
        this.dbSingleUser = null;
        this.dbUserFinger = null;
      }
    }
  }

  private deviceDescovery() {
    let mfs100 = new window['MFS100V9026'];
    let mfs100Device = mfs100.GetMFS100Info();
    if (mfs100Device.httpStaus) {
      if (mfs100Device.data.ErrorCode === "0") {
        return true;
      } else {
        this.message.warning('Finger print device is not found!', { nzDuration: 2000 });
        return false;
      }
    }
  }

  onManualAuth() {
    let formData = {};
    this.authenticationForm.get('email').setValue(document.querySelector('#user-email')['value']);
    formData = this.authenticationForm.value;
    if (!this.dbSingleUser) {
      let returnData = this.dbUserFingersData.filter(row => {
        return row.email === formData['email'];
      });
      this.dbSingleUser = (returnData && returnData[0]) ? returnData[0] : null;
    }
    formData['step'] = this.step;
    formData['is_edited'] = this.is_edited;
    if (this.authenticationForm.valid && this.dbSingleUser) {
      this.coreAPIService.api('post', 'pgi-user/simulation-step-validation', undefined, undefined, undefined, formData).subscribe(res => {
        if (res && res['data'] && res['data']['success']) {
          this.onActionSuccess.emit({
            'step': this.step, 'is_edited': this.is_edited, 'authentication': true,
            'user': this.dbSingleUser
          });
          this.isVisible = false;
          this.onActioncancel.emit({ 'isVisible': false });
          // this.message.success(res['data']['message'], { nzDuration: 1000 });
        } else {
          this.dbSingleUser = null;
          this.onActionSuccess.emit({ 'authentication': false });
          this.message.error(res['data']['message']);
        }
      }, error => {
        this.dbSingleUser = null;
        this.onActionSuccess.emit({ 'authentication': false });
        this.message.error(error);
      });
    } else {
      this.dbSingleUser = null;
      this.onActionSuccess.emit({ 'authentication': false });
      if (!this.dbSingleUser) {
        this.message.error('You are not authorized!');
      }
      for (const i in this.authenticationForm.controls) {
        if (this.authenticationForm.controls.hasOwnProperty(i)) {
          this.authenticationForm.controls[i].markAsDirty();
          this.authenticationForm.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  /** hit step api */

  private stepCheck(obj: Object) {
    let formData = { step: obj['step'], is_edited: obj['is_edited'], employee_id: obj['user']['employee_id'], role: obj['user']['role'] };
    this.coreAPIService.api('post', 'pgi-user/simulation-step-validation-biometric', undefined, undefined, undefined,
    formData).subscribe(res => {
      if (res && res['data'] && res['data']['success']) {
        this.onActionSuccess.emit(obj);
        this.isVisible = false;
        this.onActioncancel.emit({ 'isVisible': false });
      } else {
        this.isVisible = true;
        this.onActionSuccess.emit({ 'authentication': false });
        this.message.error(res['data']['message']);
      }
    }, error => {
      this.isVisible = true;
      this.onActionSuccess.emit({ 'authentication': false });
      this.message.error(error);
    });
  }

  /** end hit step api */

  onAuthenticate = e => {
    if (e.keyCode == 13) {
      this.okFingerPrint();
    }
  }

}
