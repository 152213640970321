import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ManagePatientRemarksComponent } from './manage-patient-remarks.component';
import { FormsModule } from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeaderModule } from '../core/layouts/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutsModule,
    FormsModule,
    HeaderModule,
    NgZorroAntdModule.forRoot()
  ],
  declarations: [ManagePatientRemarksComponent],
  exports: [ManagePatientRemarksComponent]
})
export class ManagePatientRemarksModule { }
