import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FollowupAfterTreatmentRoutingModule } from './followup-after-treatment-routing.module';
import { FollowupAfterTreatmentComponent } from './followup-after-treatment.component';
import { ToolbarUiModule } from '../../shared/components/toolbar-ui/toolbar-ui.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { DataToolbarUiModule } from '../../shared/components/data-toolbar-ui/data-toolbar-ui.module';
import { NavUIModule } from '../../shared/components/nav-ui/nav-ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPrintModule} from 'ngx-print';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { PipeModule } from '../../shared/pipe/pipe.module';
import { ManagePatientRemarksModule } from '../../manage-patient-remarks/manage-patient-remarks.module';
// import { PrescriptionFormComponent } from '../prescription-form/prescription-form.component';

@NgModule({
  imports: [
    CommonModule,
    // FollowupAfterTreatmentRoutingModule,
    ToolbarUiModule,
    NgZorroAntdModule,
    DataToolbarUiModule,
    NavUIModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NzPopconfirmModule,
    ManagePatientRemarksModule,
    PipeModule
  ],
  declarations: [FollowupAfterTreatmentComponent],
  exports:[FollowupAfterTreatmentComponent]
 
})

export class FollowupAfterTreatmentModule { }
