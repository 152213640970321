/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
// import { NgModule, ModuleWithProviders } from '@angular/core';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable, Subject } from 'rxjs';
// import { catchError, map, filter } from 'rxjs/operators';
// import { Attendance } from '../models/Attendance';


/**
 * Api services for the `Attendance` model.
 */
@Injectable()
export class AttendanceService extends BaseLoopBackApi {

  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }

  public loadAnalysisSection<T>(customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};

    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'chart-data'
    ].join('/'), undefined, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

    public loadAttendanceDetail<T>(date: any, id: any,  filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
      let _urlParams: any = {};
      if (filter) _urlParams.filter = filter;
      return this.request('GET', [
        LoopBackConfig.getPath(),
        LoopBackConfig.getApiVersion(),
        'attendance-inout/inout/:date/:id'
      ].join('/'), { date , id }, _urlParams, undefined, null, customHeaders);
      // .pipe(map((data: T) => this.model.factory(data)));
    }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Attendance`.
   */



  public getModelName() {
    return "Attendance";
  }
}
