import { NgModule, ModuleWithProviders } from '@angular/core';
import { OMSModels } from './OMSModels';
import {
    UserService, EmployeeService, NavService, MasterEntryService,
    UIConfigService, AttendanceService, HolidayService, LeaveService,
    EmailService, ImportExportService, ExportService,
    SettingService, UIFormService, CredentialTypeService, EnvironmentService, PreferenceService,
    NgClassService, CoreAPIService, WorkerService, WidgetService
} from './services';

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        UserService,
    ],

})

export class OMSSdkModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: OMSSdkModule,
            providers: [
                OMSModels,
                UserService,
                EmployeeService,
                MasterEntryService,
                UIConfigService,
                NavService,
                AttendanceService,
                HolidayService,
                LeaveService,
                EmailService,
                ImportExportService,
                ExportService,
                SettingService,
                UIFormService,
                CredentialTypeService,
                EnvironmentService,
                PreferenceService,
                NgClassService,
                CoreAPIService,
                WorkerService,
                WidgetService
            ],
        };
    }
}
