import { Component, OnInit, OnDestroy, Input, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PatientsListComponent } from '../doctors/patient-list/patient-list.component';
import { CoreAPIService } from '../shared/api/services';
import { NzMessageService } from 'ng-zorro-antd';
import * as moment from 'moment';
import { COMMON_REGEX, DATE_FORMAT, DATETIME_FORMAT } from '../shared/api/base.url';
import * as helpers from '../helpers/validation';

import { Subscription } from 'rxjs/Subscription';
import { DataFormatterService } from '../helpers/data-formatter.service'
import { UtilsService } from '../helpers/utils.service'
import { OptionsMaster, SelectOption } from '../helpers/model'

@Component({
  selector: 'manage-patient-remarks',
  templateUrl: './manage-patient-remarks.component.html',
  styleUrls: ['./manage-patient-remarks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagePatientRemarksComponent implements OnInit, AfterViewInit, OnDestroy {

  // Start: drawer visible declaration
  visibleRemarks = false;
  // End: Drawer visible declaration
  // Start: Add more field declaration
  @Input() patient;
  @Input() title;
  @Input() remarkTitles;
  @Input() prescriptions: Array<SelectOption>;
  @Input() sectionName;
  patientId: any;
  validateForm: FormGroup;
  loading: boolean = true;
  moment = moment;
  resetEditing = true;
  isEditing = false;
  editableRecord = [];
  filteredTitles: Array<any> = [];
  categorizedPrescription: Array<any> = [];
  filteredPrescriptions: Array<any> = [];
  listOfControl: Array<{ _id: number, _controlInstance: string, status: boolean, remark: string }> = [];
  allowedKeys = ['title', 'dose', 'schedule', 'remark', 'status', 'is_in_prescription'];
  showActionButtons: boolean = true;
  regex = COMMON_REGEX;
  dateFormat = DATE_FORMAT;
  masterOptionsSusbscription: Subscription;
  options: any;
  loadCounter: number = 0;
  schedules: Array<any>;
  filteredSchedules: Array<any> = [];
  allPrescriptions: Array<SelectOption>;
  constructor(
    private fb: FormBuilder,
    private dataService: CoreAPIService,
    private message: NzMessageService,
    private data: DataFormatterService,
    private utils: UtilsService,
    private cdr: ChangeDetectorRef
  ) {
    setInterval(() => {
      this.cdr.markForCheck();
    }, 1000);
  }

  ngOnInit(): void {
    this.patient['remarks'] = [];
    this.title = this.title ? this.title : 'New OPD Remark';
    this.validateForm = this.fb.group({});
    this.addField();
    this.filteredTitles = this.remarkTitles.map(({ name }) => name);
    this.filteredPrescriptions = [];
    if (this.patient.patient_id) {
      this.patientId = this.patient.patient_id;
    } else {
      this.message.error(`Patient Id not found. Please pass "patient_id" in patient object`)
    }
  }
  checkArray = (val) => Array.isArray(val);
  ngAfterViewInit(): void {
    this.masterOptionsSusbscription = this.data.getOptionsMaster()
      .subscribe(resp => {
        if (resp.length && resp[0].remarkPrescriptions.length && resp[0].schedules.length && this.loadCounter < 5) {
          this.options = resp[0];
          this.allPrescriptions = this.options.remarkPrescriptions;
          this.schedules = this.options.schedules.map(({ label }) => label);
          this.filteredSchedules = this.schedules;
        } else {
          this.loadCounter++;
          (!resp.length || !resp[0].remarkPrescriptions.length) && this.utils.fetchRemarkPrescriptions();
          (!resp.length || !resp[0].schedules.length) && this.utils.fetchScheduleData();
        }
      });
  }

  // newFields = [
  //   {
  //     remark: '',
  //     status: true
  //   }
  // ]
  // End: Add more field declaration
  // Start: Add more field function defined
  addField(e?: MouseEvent): void {
    if (e) {
      e.preventDefault();
    }
    const _id = Date.now();

    const control = {
      _id,
      _controlInstance: `passenger${_id}`,
      status: true,
      remark: ''
    };
    this.listOfControl.push(control);
  }

  removeField(i: { _id: number; _controlInstance: string, status: boolean, remark: string }, e: MouseEvent): void {
    e.preventDefault();
    if (this.listOfControl.length > 1) {
      const index = this.listOfControl.indexOf(i);
      this.listOfControl.splice(index, 1);
      this.validateForm.removeControl(i._controlInstance);
    }
  }

  submitForm(remarks: any = null): void {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }
    if (!remarks) {
      remarks = this.listOfControl.filter(r => r.remark.trim());
    }

    let filteredRemarks = [];
    remarks.forEach(remark => {
      let a = Object.keys(remark)
        .filter(key => this.allowedKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = remark[key];
          return obj;
        }, {})
      filteredRemarks.push(a);
    });

    let data = {
      remark: filteredRemarks,
      patient_id: this.patientId
    };
    if (this.patient.doctor_emp_id) {
      data['employee_id'] = this.patient.doctor_emp_id;
    }
    data['section_name'] = (this.sectionName) ? this.sectionName : 'Please Pass Section Name';

    if (!filteredRemarks.length) {
      this.message.error(`Can't submit without any remark`);
      return;
    }

    this.dataService.api(this.isEditing ? 'put' : 'post', `pgi-patient-remarks/${this.isEditing ? `put/${this.editableRecord['id']}` :
      `post`}`, undefined, undefined, undefined, data).subscribe(res => {
        if (res['data'].success) {
          if (this.isEditing) {
            this.editableRecord['remark'] = remarks;
          } else {
            let remark = res['data'].data,
              date_time = moment(remark['created_on']);
            remark.remark = JSON.parse(remark.remark);
            remark._date = date_time.format('DD/MM/YYYY');
            // remark._time = date_time.format('HH:mm:ss A');
            remark._expanded = false;
            this.patient.remarks.splice(0, 0, remark);
          }
          this.editableRecord['_expanded'] = true;

          this.cancelRemarks();
          if (res['status'] === 'success' && res['data'] && res['data']['success']) {
            this.message.success(res['data']['message']);
          } else {
            this.message.warning(res['message'] || res['data']['message']);
          }
        } else {
          this.message.error(res['data'].message);
        }
      },
        error => {
          this.message.error(error);
        }
      );
  }
  // End: Add more field function defined 

  // Start: Drawer function defined section
  openRemarks(): void {
    if (!(this.patient.remarks && this.patient.remarks.length)) {
      this.loading = true;
      this.dataService.api('get', `pgi-patient-remarks/get-patient/${this.patientId}`, undefined, undefined, { where: { section_name: { e: this.sectionName } } }, undefined).subscribe(
        res => {
          if (res && res['data'] && res['data'].data && res['data'].data.rowCount) {
            this.patient.remarks = res['data'].data.rows;
            this.patient.remarks.forEach(remark => {
              let date_time = moment(remark['created_on'])
              remark._date = date_time.format('DD/MM/YYYY');
              // remark._time = date_time.format('HH:mm:ss A');
              remark._expanded = false;
            });
          }
          if (this.patient.remarks && this.patient.remarks.length && this.patient.remarks[0]._date === moment().format('DD/MM/YYYY')) {
            this.startEditing(this.patient.remarks[0]);
            this.showActionButtons = true;
          } else {
            this.showActionButtons = false;
          }
          this.loading = false;
        },
        error => {
          this.message.error(error);
        });
    } else {
      if (this.patient.remarks[0]._date === moment().format('DD/MM/YYYY')) {
        this.startEditing(this.patient.remarks[0]);
      }
    }
    this.visibleRemarks = true;
  }

  toggleRemarks = remarks => {
    remarks._expanded = !remarks._expanded;
  }

  closeRemarks(): void {
    this.visibleRemarks = false;

  }

  cancelRemarks = () => {
    if (this.resetEditing) {
      this.listOfControl = [];
      this.isEditing = false;
      this.addField();
    }
    this.resetEditing = true;
    this.showActionButtons = false;
  }

  toggleSettings = (remarks, remark, key) => {
    this.isEditing = true;
    this.editableRecord = remarks;
    remark[key] = !remark[key];
    this.resetEditing = false;
    this.submitForm(remarks.remark)
  }

  startEditing = entries => {
    this.isEditing = true;
    if (entries.remark.length) {
      this.editableRecord = entries;
      this.listOfControl = [];
      entries.remark.forEach((remark, i) => {
        this.listOfControl.push(remark);
      })
      // this.addField();
    } else {
      this.cancelRemarks();
    }
  }
  // End: Drawer function defined section

  onKeyPress = (e, regex) => {
    return helpers.regexFilter(e, regex);
  }

  onPaste = (e, regex) => {
    return helpers.pasteFilter(e, regex);
  }

  onFilterDose = (value: string, titleValue: string) => {
    this.filteredPrescriptions = [];
    if (titleValue && titleValue != 'Remark') {
      this.categorizedPrescription = this.allPrescriptions.filter(a => { return a.key.toLowerCase().includes(value.toLowerCase()) })
      if (this.categorizedPrescription.length) {
        this.filteredPrescriptions = this.categorizedPrescription
          .filter(option => option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0)
          .map(({ label }) => label);
        //console.log(this.filteredPrescriptions)
      }
    } else {
      this.categorizedPrescription = [];
    }
    // console.log(this.filteredPrescriptions)
    // this.showActionButtons = this.listOfControl.filter(control => control.remark.trim()).length > 0;
  }

  onFilterSchedule = (value: string) => {
    if (value) {
      this.filteredSchedules = this.schedules
        .filter(label => label.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        .map(({ label }) => label);
      // this.filteredPrescriptions = this.categorizedPrescription
      // console.log(this.schedules)
      // .map(({label}) => label);
    } else {
      this.filteredSchedules = this.schedules;
    }
    // console.log(this.schedules)
  }

  onFilterTitle = (value: string) => {
    value = value.trim();
    if (this.remarkTitles.length) {
      this.filteredTitles = this.remarkTitles
        .filter(option => option.name.toLowerCase().indexOf(value.toLowerCase()) >= 0)
        .map(({ name }) => name);
    } else {
      this.filteredTitles = [];
    }

  }

  onChangeRemark = value => {
    this.showActionButtons = value.trim().length > 0;
  }

  ngOnDestroy() {
    this.masterOptionsSusbscription && this.masterOptionsSusbscription.unsubscribe();
  }
}
