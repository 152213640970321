import { clinicalHistoryComponent } from './clinical-history.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { NgxPrintModule } from 'ngx-print';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ToolbarUiModule } from '../../components/toolbar-ui/toolbar-ui.module';
// import { DataToolbarUiModule } from '../../components/data-toolbar-ui/data-toolbar-ui.module';
// import { NavUIModule } from '../../components/nav-ui/nav-ui.module';
import { FormsModule,  } from '@angular/forms';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { PipeModule } from '../../pipe/pipe.module';
import { ToastUiImageEditorModule } from "ngx-tui-image-editor";

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    FormsModule,
    // ReactiveFormsModule,
    NgxPrintModule,
    // ToolbarUiModule,
    PipeModule,
    // DataToolbarUiModule,
    // NavUIModule,
    NzPopconfirmModule,
    ToastUiImageEditorModule,
    NgZorroAntdModule.forRoot()
  ],
  declarations: [clinicalHistoryComponent],
  exports: [clinicalHistoryComponent]
})
export class clinicalHistoryModule { }
