import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { DialogUploadUiComponent } from './dialog-upload-ui.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  imports: [
    CommonModule,
    NgZorroAntdModule,
    ImageCropperModule
  ],
  declarations: [DialogUploadUiComponent],
  exports: [DialogUploadUiComponent]
})
export class DialogUploadUiModule { }
