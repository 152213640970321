import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rt',
  templateUrl: './rt.component.html',
  styleUrls: ['./rt.component.scss']
})
export class RtComponent implements OnInit {
  isCollapsed: boolean = true;
  showDebugerPanel: boolean = false;
  setLayout: string = 'overpage';

  constructor() { }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }

  ngOnInit() {
  }
}
