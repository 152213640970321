/* tslint:disable */

declare var Object: any;
export class Nav {  
  constructor(data?: Nav) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Nav`.
   */
  public static getModelName() {
    return "Nav";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Nav for dynamic purposes.
  **/
  public static factory(data: Nav): Nav{
    return new Nav(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Nav',
      plural: 'navs',      
      path: 'navs',      
    }
  }
}
