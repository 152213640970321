import { Component, OnInit, Input } from '@angular/core';
import { DATETIME_FORMAT, DATE_FORMAT } from '../../api/base.url'
import { ActivatedRoute } from '@angular/router';
import { CoreAPIService } from '../../api/services/';
import { NzMessagesService } from '../../nz-message.service';

@Component({
  selector: 'clinical-history',
  templateUrl: './clinical-history.component.html',
  styleUrls: ['./clinical-history.component.scss']
})
export class clinicalHistoryComponent implements OnInit {

  patientsId: 496;
  siteId: any;
  presentComplain: any;
  comorbindConditions: any;
  clinicalExam: any;
  menstrualHistory: any;
  systemicExam: any;
  loadedAllData: boolean = false;
  imageOptions: any = [];
  perinealExam: any;
  karnofskyJsonData: any = null;
  doctorName: any;
  dateTimeFormat = DATETIME_FORMAT;
  date_format = DATE_FORMAT;

  constructor(private apiCore: CoreAPIService, private message: NzMessagesService, private route: ActivatedRoute, ) { }

  getClinicalHistoryData() {
    this.loadedAllData = true;
    this.apiCore.api("GET", 'clinical-history/get-full-details/' + this.siteId + "/" + this.patientsId, undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          this.presentComplain = res['data']['data']['presenting-complain'];
          this.presentComplain.other_disease = (this.presentComplain && this.presentComplain.other_disease) ? this.presentComplain.other_disease.join(", ") : '';
          this.clinicalExam = res['data']['data']['clinical-exam'];
          // this.clinicalExam.clinical_exam['other_parameter'] 
          if (this.clinicalExam.clinical_exam && this.clinicalExam.clinical_exam.other_parameter) {
            this.clinicalExam.clinical_exam.other_parameter = (this.clinicalExam && this.clinicalExam.clinical_exam && this.clinicalExam.clinical_exam.other_parameter) ? this.clinicalExam.clinical_exam.other_parameter.join(", ") : '';
          }
          this.systemicExam = res['data']['data']['clinical-exam']['systemic_exam'];
          this.comorbindConditions = res['data']['data']['comorbind-conditions'];

          this.menstrualHistory = res['data']['data']['menstrual-history'];
          if (this.menstrualHistory && this.menstrualHistory.menstrual && this.menstrualHistory.menstrual.status) {
            this.menstrualHistory.menstrual.status = (this.menstrualHistory && this.menstrualHistory.menstrual && this.menstrualHistory.menstrual.status) ? this.menstrualHistory.menstrual.status.join(", ") : '';
          }
          this.perinealExam = res['data']['data']['perineal-exam'];
          if (this.clinicalExam && this.clinicalExam['clinical_exam'] && this.clinicalExam['clinical_exam']['karnofsky_performance_status'] && this.clinicalExam['clinical_exam']['karnofsky_performance_status'].length > 0) {
            let filteredVal = null;
            if (this.karnofskyJsonData && this.karnofskyJsonData.length > 0) {
              this.karnofskyJsonData.forEach(element => {
                filteredVal = this.clinicalExam['clinical_exam']['karnofsky_performance_status'].filter(data => data.karnofsky_id == element.id);
                if (filteredVal && filteredVal.length > 0) {
                  element.is_checked = filteredVal[0].checked;
                }
              });
            }
          }
          this.getClinicalImageInfo();
        } else {
          this.message.error(res['data']['message']);
        }
        this.loadedAllData = false;
      }
    );
  }

  getPatientInfo() {
    let qParams, url, data;
    url = { "id": { "e": this.patientsId } };
    qParams = JSON.stringify(url);
    this.apiCore.api("get", 'patients/search/?where=' + qParams, undefined, undefined, undefined, undefined).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          data = res['data']['data']['rows'];
          this.siteId = data[0].site_id;
          this.doctorName = data[0].doctor_name;
          if (this.siteId) {
            this.getClinicalHistoryData();
          }
        } else {
          this.message.error(res['data']['message']);
        }
      }
    );
  }

  private getClinicalImageInfo() {
    this.apiCore.api('GET', 'pgi-sites/get-record/' + this.siteId + '/' + this.patientsId, undefined)
      .subscribe(res => {
        if (res['status'] === 'success' && res['data'] && res['data']['data']) {
          let files = res['data']['data'].files;
          let filesD = res['data']['data'].files_data;
          filesD.forEach((fd, idx) => {
            this.imageOptions.push({
              options: {
                includeUI: {
                  loadImage: {
                    path: fd,
                    name: files[idx]
                  },
                  menu: ['text', 'shape', 'draw', 'flip', 'crop'],
                  initMenu: null
                }
              }
            });
          });
        }
        else {
        }
      });
  }

  getKarnofskyMaster() {
    let qParams = {};
    let filters = {};
    filters['status'] = { e: '1' };
    qParams['where'] = filters;
    this.loadedAllData = true;
    this.apiCore.api("GET", 'pgi-karnofsky/get', undefined, undefined, qParams).subscribe(
      res => {
        if (res['data'] && res['data']['success']) {
          this.karnofskyJsonData = res['data']['data']['rows'];
          this.loadedAllData = false;
        }
      });
  }

  ngOnInit() {
    // this.route.parent.params.subscribe(params => {
    //   this.patientsId = params['id'];
    //   this.getPatientInfo();
    // });
    // this.getKarnofskyMaster();
  }

}
