import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import '../rxj-extensions';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { Patient, PatientDetails, RTDetails } from './model';
import { PATIENT_STATUS } from '../../shared/api/base.url';


@Injectable()

export class RTDataFormatterService {
  private PatientsCount$: BehaviorSubject<Array<number>> = <BehaviorSubject<Array<number>>> new BehaviorSubject(new Array<number>());
  private RTPatients: Array<Patient> = new Array<Patient>();
	private RTPatients$: BehaviorSubject<Array<Patient>> = <BehaviorSubject<Array<Patient>>> new BehaviorSubject(new Array<Patient>());
  private RTInfos: Array<RTDetails> = new Array<RTDetails>();
	private RTInfos$: BehaviorSubject<Array<RTDetails>> = <BehaviorSubject<Array<RTDetails>>> new BehaviorSubject(new Array<RTDetails>());
  
	constructor(
    // private activatedRoute: ActivatedRoute
    ) {
  }



  public updatePatientsCount(count:number) {
    this.PatientsCount$.next([count]);
  }
  public getPatientsCount(): Observable<Array<number>> {
    return this.PatientsCount$.asObservable();
  }
  public getRTInfos(): Observable<Array<RTDetails>> {
    return this.RTInfos$.asObservable();
  }

  public populateRtDetails(rtDetails) {
    rtDetails.forEach(p => {
      let rt:Array<RTDetails> = this.RTInfos.filter(({id}) => parseInt(p.id) == id);
      if(rt.length) {
        this.RTInfos.splice(this.RTInfos.indexOf(rt[0]), 1, new RTDetails(parseInt(p.id), p))
      } else {
        this.RTInfos.push(new RTDetails(parseInt(p.id), p))
      }
    })
    this.RTInfos$.next(this.RTInfos);
  }

  public removePatients() {
    this.RTPatients = [];
  }

  public populatePatients(patients) {
    patients.forEach(p => {
      let patients:Array<Patient> = this.RTPatients.filter(({id}) => parseInt(p.id) == id);
      if(patients.length) {
        this.RTPatients.splice(this.RTPatients.indexOf(patients[0]), 1, this.renderPatientsListItem(p))
      } else {
        this.RTPatients.push(this.renderPatientsListItem(p))
      }
    })
    this.RTPatients$.next(this.RTPatients);
  }
  
  private renderPatientsListItem(p): Patient {
    let rtStatusVal = PATIENT_STATUS.filter(a => a.key == p.rt_status)
    return new Patient(
      p.avatar ? p.avatar : null,
      p.category ? p.category : null,
      p.consultant ? p.consultant : null,
      p.contact_id ? p.contact_id : null,
      p.cr_no ? p.cr_no : null,
      p.diagnosis ? p.diagnosis : null,
      p.diagnosis_method_name ? p.diagnosis_method_name : null,
      p.diagnosis_options_name ? p.diagnosis_options_name : null,
      p.diagnosis_id ? parseInt(p.diagnosis_id) : null,
      p.dob ? p.dob : null,
      p.doc_emp_id ? parseInt(p.doc_emp_id) : null,
      p.doctor_emp_id ? parseInt(p.doctor_emp_id) : null,
      p.doctor_name ? p.doctor_name : null,
      p.duration ? parseInt(p.duration) : null,
      p.email ? p.email : null,
      p.final_sign_by ? p.final_sign_by : null,
      p.first_name ? p.first_name : null,
      p.first_planning_on ? p.first_planning_on : null,
      p.gender ? p.gender : null,
      p.has_contrast ? p.has_contrast : null,
      p.hbcr_no ? p.hbcr_no : null,
      p.id ? parseInt(p.id) : null,
      p.intention_to_treat ? p.intention_to_treat : null,
      p.last_name ? p.last_name : null,
      p.marital_status ? p.marital_status : null,
      p.mid_name ? p.mid_name : null,
      p.nationality ? p.nationality : null,
      p.occupation ? p.occupation : null,
      p.patient_info ? p.patient_info : null,
      p.patient_id ? parseInt(p.patient_id) : null,
      p.patient_name ? p.patient_name : null,
      p.patient_status ? p.patient_status : null,
      p.payment ? p.payment : null,
      p.phone ? parseInt(p.phone) : null,
      p.physics ? p.physics : null,
      p.position_scanning ? p.position_scanning : null,
      p.planning ? p.planning : null,
      p.profileImage ? p.profileImage : null,
      !p.profileImage ? `${p.first_name ? p.first_name.charAt(0) : ''}${p.last_name ? p.last_name.charAt(0) : ''}` : null,
      p.protocol ? p.protocol : null,
      p.region ? p.region : null,
      p.registered_on ? p.registered_on : null,
      p.religion ? p.religion : null,
      p.remarks ? p.remarks : null,
      p.risk_factor ? p.risk_factor : null,
      p.rt_status && rtStatusVal.length ? rtStatusVal[0].label : null,
      p.rt_no ? p.rt_no : null,
      p.rt_status ? p.rt_status : null,
      p.rt_simulation_id ? p.rt_simulation_id : null,
      p.rt_treatment_completion_on ? p.rt_treatment_completion_on : null,
      p.rt_treatment_start_on ? p.rt_treatment_start_on : null,
      p.rtp_r_number ? p.rtp_r_number : null,
      p.site_id ? parseInt(p.site_id) : null,
      p.site_name ? p.site_name : null,
      p.stage ? p.stage : null,
      p.status ? parseInt(p.status) == 1 : false,
      p.sub_technique ? p.sub_technique : null,
      p.technique ? p.technique : null,
      p.technique_id ? parseInt(p.technique_id) : null,
      p.title ? p.title : null,
      p.treatment_completion_on ? p.treatment_completion_on : null,
      p.treatment_id ? parseInt(p.treatment_id) : null,
      p.treatment_start_on ? p.treatment_start_on : null,
      p.treatment_status ? parseInt(p.treatment_status) : null,
      p.treatment_suggested_on ? p.treatment_suggested_on : null,
      p.treatment_type_id ? parseInt(p.treatment_type_id) : null,
      p.treatment_unit ? p.treatment_unit : null,
      this.renderPatient(p.patient_info ? p.patient_info : p),
    )
  }

  private renderPatient(p): PatientDetails {
    return new PatientDetails(
      p.avatar ? p.avatar : null,
      p.contact_id ? parseInt(p.contact_id) : null,
      p.cr_no ? p.cr_no : null,
      p.dob ? p.dob : null,
      p.doctor_emp_id ? parseInt(p.doctor_emp_id) : null,
      p.doctor_name ? p.doctor_name : null,
      p.education ? p.education : null,
      p.email ? p.email : null,
      p.first_name ? p.first_name : null,
      p.freeze_record ? (p.freeze_record == 1) : false,
      p.gender ? p.gender : null,
      p.hbcr_no ? p.hbcr_no : null,
      p.id ? parseInt(p.id) : null,
      p.last_name ? p.last_name : null,
      p.marital_status ? p.marital_status : null,
      p.mid_name ? p.mid_name : null,
      p.mother_tongue ? p.mother_tongue : null,
      p.nationality ? p.nationality : null,
      p.occupation ? p.occupation : null,
      p.patient_id ? parseInt(p.patient_id) : null,
      p.patient_name ? p.patient_name : null,
      p.patient_status ? parseInt(p.patient_status) : null,
      p.personal_phone ? p.personal_phone : null,
      p.phone ? parseInt(p.phone) : null,
      p.profileImage ? p.profileImage : null,
      p.registered_on ? p.registered_on : null,
      p.religion ? p.religion : null,
      p.remarks ? p.remarks : null,
      p.rt_no ? p.rt_no : null,
      p.site_id ? parseInt(p.site_id) : null,
      p.site_name ? p.site_name : null,
      p.title ? p.title : null,
    )
  }

  public getRTPatients(txt=null, status=null): Observable<Array<Patient>> {
    return this.RTPatients$.asObservable();
  }
}