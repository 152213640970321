export class NavConfig {
  config: any = {
    hrUserEditMenu: {
      layout: 'inline',
      style: 'height:100%',
      type: 3,
      items: [
        {
          title: 'HR',
          isOpen: true,
          type: 1,
          icon: 'anticon anticon-user',
          items: [
            { title: "Overview", description: "Overview", type: 0 },
            { title: "Personal", description: "Personal Information", type: 0 },
            { title: "Official", description: "Official Information", type: 0 },
            { title: "Leaves", description: "Leaves Information", type: 0 },
            { title: "Salary", description: "Salary Information", type: 0 },
            { title: "Attendance", description: "Attendance Information", type: 0 },
            { title: "Performance", description: "Performance", type: 0 }
          ]
        }, {
          title: 'PM',
          isOpen: false,
          type: 1,
          icon: 'anticon anticon-laptop',
          items: [
            { title: "Projects", description: "Projects", type: 0 },
            { title: "Tasks", description: "Tasks", type: 0 },
            { title: "Teams", description: "Teams", type: 0 },
            { title: "Schedules", description: "Schedules", type: 0 }
          ]
        }
      ]
    },
    leftMenu: {
      layout: 'inline',
      style: null,
      type: 3,
      inlineCollapsed: true,
      items: [
        {
          name: "HR",
          icon: "anticon anticon-appstore",
          type: 1,
          items: [
            { name: "Employee", routerLink: "/hr/employee", type: 0 },
            { name: "Attendance", routerLink: "/hr/attendance", type: 0 },
            { name: "Leaves", routerLink: "/hr/leaves", type: 0 },
            { name: "Holidays", routerLink: "/hr/holidays", type: 0 },

          ]
        }
      ]
    },
    topMenu: {
      layout: 'horizontal',
      style: null,
      type: 3,
      inlineCollapsed: true,
      items: [
        {
          title: "Navigation One",
          icon: "anticon anticon-mail",
          type: 0
        },
        {
          title: "HR",
          type: 1,
          icon: "anticon anticon-appstore",
          items: [
            { title: "Employee", routerLink: "/hr/employee", type: 0 },
            { title: "Attendance", routerLink: "/hr/attendance", type: 0 },
            { title: "Leaves", routerLink: "/hr/leaves", type: 0 },
            { title: "Holidays", routerLink: "/hr/holidays", type: 0 },
            {
              title: "Recruitment",
              icon: null,
              isGroup: true,
              items: [
                { title: "Applicant", type: 0 },
                { title: "Opening", type: 0 },
                { title: "Interview", type: 0 },
              ]
            }
          ],
        }
      ]
    }
  }

  public getMenuItem(key) {
    let dataReturn = {};

    if (this.config[key]) {
      dataReturn = this.config[key];
    }

    return dataReturn;
  }
};