import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UIConfig } from '../UIConfig';

@Component({
  selector: 'custom-widget',
  templateUrl: './custom-widget.component.html',
  styleUrls: ['./custom-widget.component.scss']
})
export class CustomWidgetComponent implements OnInit {

@Input() widgets: any;
@Output() onRefresh: EventEmitter<any>  = new EventEmitter();

  //nwidgetData: number = UIConfig.config.nwidgetData;

  constructor() { }

  ngOnInit() {
  }

  onEvent(event:any){
    this.onRefresh.emit(event);
  }

}
