import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../shared/api/services/User';
import { NzMessagesService, SHOW_TYPES } from '../../shared/nz-message.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;
  encKey: number;
  dataToken: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: UserService,
    private route: ActivatedRoute,
    private message: NzMessagesService
  ) {
    this.route.params.subscribe(params => {
      this.encKey = params['encKey'];
      this.dataToken = params['dataToken'];
    });
  }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [null, [Validators.required]],
      confirmPassword: [null, [Validators.required]],
    });
  }

  submitForm(): void {
    let values = this.resetPasswordForm.value;

    if (values && values.newPassword && values.confirmPassword && values.newPassword !== values.confirmPassword) {
      this.message.warning('Your confirm password does not match!', { nzDuration: 1000 });
      return;
    }

    if (values && values.oldPassword && values.newPassword && values.oldPassword == values.newPassword) {
      this.message.warning('Don\'t enter same as old password!', { nzDuration: 1000 });
      return;
    }

    if (
      this.resetPasswordForm.valid &&
      values && values.newPassword && values.confirmPassword && 
      values.oldPassword && values.newPassword.trim() === values.confirmPassword.trim()
    ) {
    // introduced encoding
      let dataValues = {
        encKey: this.encKey,
        dataToken: this.dataToken,
        password: btoa(values.newPassword),
        confirm: btoa(values.confirmPassword),
        oldPassword: btoa(values.oldPassword)
      }

      this.dataService.resetPassword(dataValues).subscribe(
        res => {
          if (res && res['data'] && res['data']['success']) {
            this.router.navigate(['/login']);
          } else {
            // this.message.error('Error: while updating password');
            this.message.error(res['data']['message']);

          }
        },
        error => {
          this.message.error('Error: ', error.message);
        }
      )

    } else {
      // console.log(values);
      for (const i in this.resetPasswordForm.controls) {
        this.resetPasswordForm.controls[i].markAsDirty();
        this.resetPasswordForm.controls[i].updateValueAndValidity();
      }
    }
  }
}
