import { Routes, RouterModule } from '@angular/router';
import { FrontDeskUserComponent } from './front-desk-user.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'front-desk-user',
    component: FrontDeskUserComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'doctors-list', loadChildren: './doctors-list/doctors-list.module#DoctorsListModule' },
      { path: 'patients-list', loadChildren: './patients-list/patients-list.module#PatientsListModule' },
      { path: 'dashboard', loadChildren: '../base/dashboard/dashboard.module#DashboardModule' },
      { path: 'scheduled-slots', loadChildren: './scheduled-slots/scheduled-slots.module#ScheduledSlotsModule' },
      { path: 'check-availability', loadChildren: './check-availability/check-availability.module#CheckAvailabilityModule' },
      { path: 'railway-concession', loadChildren: './railway-concession/railway-concession.module#RailwayConcessionModule' }
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
