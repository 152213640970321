/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
// import { NgModule, ModuleWithProviders } from '@angular/core';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable, Subject } from 'rxjs';
// import { catchError, map, filter } from 'rxjs/operators';
// import { Employee } from '../models/Employee';


/**
 * Api services for the `Employee` model.
 */
@Injectable()
export class EmployeeService extends BaseLoopBackApi {
  //loadMasterData : any =[];
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }

  public loadSection<T>(id: any, section: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'get/:id/:section'
    ].join('/'), { id, section }, _urlParams, undefined, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public loadMembers<T>(filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'members/list'
    ].join('/'), undefined, _urlParams, undefined, null, customHeaders);
  }

  public addSectionInfo<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'post/:id/:section'
    ].join('/'), { id, section }, null, data, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public addwidgetnfo<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'post/:id/:section'
    ].join('/'), { id, section }, null, data, null, customHeaders);
  }

  public saveSectionInfo<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
    return this.request('PUT', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'put/:id/:section'
    ].join('/'), { id, section }, null, data, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public delete<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
    return this.request('delete', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'delete/:id/:section'
    ].join('/'), { id, section }, null, data, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public importDataInfo<T>(id: any, section: any, data: any = {}, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'import'
    ].join('/'), { id, section }, null, data, null, customHeaders);
  }

  public search<T>(reqParams: any = {}, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'search'
    ].join('/'), undefined, reqParams, undefined, null, customHeaders)
    // .pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
  }

  public updateStatus<T>(id: any, status: any, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('PUT', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'update/:id/:status'
    ].join('/'), { id, status }, _urlParams, undefined, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public setBiometric<T>(id: any, type: number, customHeaders?: Function): Observable<T> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'set-biometric-data/:id/:type'
    ].join('/'), { id, type }, null, customHeaders);
  }

  public getBiometric<T>(id: any, type: number, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'get-biometric-data/:id/:type'
    ].join('/'), { id, type }, _urlParams, undefined, null, customHeaders);
    // .pipe(map((data: T) => this.model.factory(data)));
  }

  public updateImage<T>(id: any, data: T, customHeaders?: Function): Observable<T> {
    return this.request('POST', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'update-image/:id'
    ].join('/'), { id }, undefined, { data }, null, customHeaders);
  }

  public setUserBiometricStatus<T>(id: any, type: number, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T> {
    let _urlParams: any = {};
    if (filter) _urlParams.filter = filter;
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'delete-biometric-data/:id/:type'
    ].join('/'), { id, type }, _urlParams, undefined, null, customHeaders);
  }


  /**
   * The name of the model represented by this $resource,
   * i.e. `Employee`.
   */
  public getModelName() {
    return "Employee";
  }
}
