import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-worker',
  templateUrl: './social-worker.component.html',
  styleUrls: ['./social-worker.component.scss']
})
export class SocialWorkerComponent implements OnInit {

  isCollapsed: boolean = true;
  showDebugerPanel: boolean = false;
  setLayout: string = 'overpage';

  constructor() { }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  onshowDebuger() {
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger() {
    this.showDebugerPanel = false;
  }

  ngOnInit() {
  }

}
