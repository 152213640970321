import { Component } from '@angular/core';

@Component({
  selector: 'app-hr',
  templateUrl: './hr.component.html'
})
export class HrComponent {
  isCollapsed = true;
  showDebugerPanel: boolean = false;
  setLayout = 'overpage';
  
  constructor() {
  }

  sidebarToggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnInit() {
  }

  onshowDebuger(){
    this.showDebugerPanel = !this.showDebugerPanel;
  }

  closeDebuger(){
    this.showDebugerPanel = false;
  }

}
