import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { CustomFilterPipe } from './custom-filter.pipe';
import { CalculateAgePipe } from './calculate-age.pipe';
import { ShowDimensions } from './show-dimensions.pipe';
import { CustomGlobalFilterPipe } from './custom-global-filter.pipe';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SafePipe,CustomFilterPipe,CustomGlobalFilterPipe,CalculateAgePipe,ShowDimensions],
  exports: [SafePipe,CustomFilterPipe,CustomGlobalFilterPipe,CalculateAgePipe,ShowDimensions]
})
export class PipeModule { }
