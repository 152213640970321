import { Injectable } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { of } from 'rxjs/observable/of'
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import './rxj-extensions';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { RTPlanning, SelectOption, OptionsMaster, ApplicationSetting, FileSettings, SelectedTab } from './model';
import { BASE_URL, LOGO_ASSETS_UPLOAD_PATH } from '../shared/api/base.url';

@Injectable()

export class DataFormatterService {
  private RTPlanning: RTPlanning;
  private RTPlanning$: BehaviorSubject<Array<RTPlanning>> = <BehaviorSubject<Array<RTPlanning>>> new BehaviorSubject(new Array<RTPlanning>());
  
  private RTPaymentOptions: Array<SelectOption> = new Array<SelectOption>();
	private RTPaymentOptions$: BehaviorSubject<Array<SelectOption>> = <BehaviorSubject<Array<SelectOption>>> new BehaviorSubject(new Array<SelectOption>());
  
  private OptionsMaster: OptionsMaster = new OptionsMaster();
  private OptionsMaster$: BehaviorSubject<Array<OptionsMaster>> = <BehaviorSubject<Array<OptionsMaster>>> new BehaviorSubject(new Array<OptionsMaster>());

  private AppSetting: ApplicationSetting = new ApplicationSetting();
  private AppSetting$: BehaviorSubject<ApplicationSetting> = <BehaviorSubject<ApplicationSetting>> new BehaviorSubject(new ApplicationSetting());
  
  private SidebarNavs$: BehaviorSubject<Array<any>> = <BehaviorSubject<Array<any>>> new BehaviorSubject(new Array<any>());
  
  private ArchiveAttachmentSettings:FileSettings = new FileSettings();
  private ArchiveAttachmentSettings$: BehaviorSubject<FileSettings> = <BehaviorSubject<FileSettings>> new BehaviorSubject(new FileSettings());
  
	private PositionScanningSiteForms$: BehaviorSubject<any> = <BehaviorSubject<any>> new BehaviorSubject(null);
	private selectedTabIndex$: BehaviorSubject<SelectedTab> = <BehaviorSubject<SelectedTab>> new BehaviorSubject(new SelectedTab());

	constructor() {
  }

  populateSidebarNavs(navs) {
    this.SidebarNavs$.next([navs]);
  }

  resetSidebarNavs() {
    this.SidebarNavs$.next([]);
  }
  
  public populateRTPaymentOptions(options: Array<any>) {
    this.RTPaymentOptions = this.renderOptions(options, 'id', 'payment')
    this.RTPaymentOptions$.next(this.RTPaymentOptions);
  }

  public populateOptionsMaster(options) {
    // this.OptionsMaster = !this.OptionsMaster ? new OptionsMaster() : this.OptionsMaster;
    if(options && typeof options == 'object') {
      // let keys = Object.keys(this.OptionsMaster);
      let keys = Object.keys(options);
      keys.forEach(key => {
        if(options[key]) {
          this.OptionsMaster[key] = this.renderOptions(options[key].rows)
        }
      })
    } else {
      // console.clear()
    }
    this.OptionsMaster$.next([this.OptionsMaster]);
  }

  public populateRemarkTitles = (options) => {
    this.OptionsMaster['remarkPrescriptions'] = this.renderOptions(options, 'remark_titles_name', 'drug_name')
    this.OptionsMaster$.next([this.OptionsMaster]);
  }

  public populateSchedules = (options) => {
    this.OptionsMaster['schedules'] = this.renderOptions(options)
    this.OptionsMaster$.next([this.OptionsMaster]);
  }

  public populateCustomOptions = (masterKey, options, idKey='id', valKey='name') => {
    this.OptionsMaster[masterKey] = this.renderOptions(options, idKey, valKey)
    this.OptionsMaster$.next([this.OptionsMaster]);
  }

  public setPositionScanningSiteForms = (data) => {
    this.PositionScanningSiteForms$.next(data);
  }

  getSidebarNavs(): Observable<Array<any>> {
    return this.SidebarNavs$.asObservable();
  }

  public getRTPaymentOptions(): Observable<Array<SelectOption>> {
		return this.RTPaymentOptions$.asObservable();
  }
  
  public getApplicationSettings(): Observable<ApplicationSetting> {
		return this.AppSetting$.asObservable();
	}

  public getOptionsMaster(): Observable<Array<OptionsMaster>> {
    return this.OptionsMaster$.asObservable();
  }

  public getPositionScanningSiteForms(): Observable<any> {
    return this.PositionScanningSiteForms$.asObservable();
  }

  public getSelectedTab(): Observable<any> {
    return this.selectedTabIndex$.asObservable();
  }

  public setSelectedTab(selectedTab:SelectedTab) {
    this.selectedTabIndex$.next(selectedTab);
  }

  private renderOptions(options: Array<any>, idKey='id',valKey='name',dataKey=null): Array<SelectOption> {
    let target:Array<SelectOption> = new Array<SelectOption>();
    if(options.length) {
      options.forEach(row => {
        target.push(new SelectOption(row[idKey], row[valKey], null))
      })
    }
    return target;
  }

  public renderAppSettings(data) {
    data.forEach(row => {
      if (row.key == 'login_logo_image'){
        this.AppSetting.logo_image = (BASE_URL.includes('http') ? BASE_URL : `http:${BASE_URL}`) + LOGO_ASSETS_UPLOAD_PATH + row.value
      } else if(row.key == 'favicon_logo'){
        this.AppSetting.favicon_image = (BASE_URL.includes('http') ? BASE_URL : `http:${BASE_URL}`) + LOGO_ASSETS_UPLOAD_PATH + row.value;
      } else if(row.key == 'menu_logo_image'){
        this.AppSetting.menu_image = (BASE_URL.includes('http') ? BASE_URL : `http:${BASE_URL}`) + LOGO_ASSETS_UPLOAD_PATH + row.value;
      }  else if(row.key == 'watermark_logo'){
        this.AppSetting.watermark_image = (BASE_URL.includes('http') ? BASE_URL : `http:${BASE_URL}`) + LOGO_ASSETS_UPLOAD_PATH + row.value;
      } else if (row.key == 'project_title') {
        this.AppSetting.project_title = row.value;
      } else if (row.key == 'project_full_name') {
        this.AppSetting.project_full_name = row.value;
      }
    });
    this.AppSetting$.next(this.AppSetting);
  }

  public getArchiveAttachmentSettings(): Observable<FileSettings> {
    return this.ArchiveAttachmentSettings$.asObservable();
  }

  renderArchiveAttachmentSettings = (data) => {
    this.ArchiveAttachmentSettings = new FileSettings(
      data.key,
      data.title,
      data.max_file_size && parseInt(data.max_file_size),
      data.min_size_unit,
      data.min_file_size && parseInt(data.min_file_size),
      
      data.max_size_unit,
      data.total_file_size && parseInt(data.total_file_size),
      data.total_file_size_unit,
      data.permitted_file_types.split(',')
    );
    this.ArchiveAttachmentSettings$.next(this.ArchiveAttachmentSettings);
  }
}