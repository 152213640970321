/* tslint:disable */

declare var Object: any;
export class NgClasses {  
  constructor(data?: NgClasses) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Module`.
   */
  public static getModelName() {
    return "NgClasses";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Module for dynamic purposes.
  **/
  public static factory(data: NgClasses): NgClasses{
    return new NgClasses(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'NgClasses',
      plural: 'ui-ng-classes',
      path: 'ui-ng-classes',
    }
  }
}