import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RtComponent } from './rt.component';
import { routing } from './rt.routing';
import { FormsModule } from '@angular/forms';
import { LayoutsModule } from '../core/layouts/layouts.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { HeaderModule } from '../core/layouts/header/header.module';
import { ToolbarUiModule } from '../shared/components/toolbar-ui/toolbar-ui.module';
import { DataToolbarUiModule } from '../shared/components/data-toolbar-ui/data-toolbar-ui.module';
import { RTDataFormatterService } from '../helpers/rt/data-formatter.service';
import { RTUtilsService } from '../helpers/rt/utils.service';


@NgModule({
  imports: [
    CommonModule,
    routing,
    LayoutsModule,
    FormsModule,
    HeaderModule,
    NgZorroAntdModule.forRoot(),
    ToolbarUiModule,
    DataToolbarUiModule
    
  ],
  declarations: [RtComponent],
  providers: [
    RTDataFormatterService,
    RTUtilsService,
  ],
})
export class RtModule { }
