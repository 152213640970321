import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetUiComponent } from './widgets-ui.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { GoogleChartsModule } from 'angular-google-charts';
import { DycFormsModule } from '../dyc-forms/dyc-forms.module';
import { GridsterModule } from 'angular-gridster2';

@NgModule({
  imports: [
    CommonModule, NgZorroAntdModule, GoogleChartsModule.forRoot(), GridsterModule, DycFormsModule
  ],

  declarations: [WidgetUiComponent],

  exports: [WidgetUiComponent],
})
export class WidgetUiModule { }
