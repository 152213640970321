export class UIConfig {
  public static config: any = {
    "setingFormData": {
      "formData": null,
      "email": {
        "defaultValue": '',
        "type": "string",
        "placeholder": "Heading",
        "label": "Heading"
      },
      "selectme": {
        "defaultValue": '',
        "required": true,
        "type": "select",
        "options": [
          { "label": "Select An Option", "value": "" },
          { "label": "Opt1", "value": "1" },
          { "label": "Opt2", "value": "2" },
          { "label": "Opt3", "value": "3" },
          { "label": "Opt4", "value": "4" },
          { "label": "Opt5", "value": "5" }
        ],
        "placeholder": "Select Options",
        "label": "Select Options"
      },
      "checkbox1": {
        "required": true,
        "type": "checkbox",
        "options": [
          { "value": 1, "label": "Checkbox One" },
          { "value": 2, "label": "Checkbox Two" },
          { "value": 3, "label": "Checkbox Three" }
        ]
      },
      "options": {
        "required": true,
        "type": "radio",
        "defaultValue": 1,
        "options": [
          { "value": 1, "label": "Option One" },
          { "value": 2, "label": "Option Two" },
          { "value": 3, "label": "Option Three" }
        ]
      },
      "datepicker111": {
        "defaultValue": 'NOW',
        "format": "yyyy-MM-dd",
        "required": true,
        "type": "date",
        "label": "Show Date",
        "placeholder": "Please enter date (YYYY-MM-DD)"
      },
      "saveButton": {
        "defaultValue": 'Save',
        "type": "button"
      }
    },
    "textCards": {
      "layoutType": "text_info_cards",
      "data": [
        {
          "layoutDivision": 4,
          "widgetText": "Employee",
          "icon": "fa fa-users",
          "faIcon": true,
          "class": "widget-1",
          "justify": "space-around",
          "align": "middle",
          "data": {
            "heading": ["New Employee", "Total Employee"],
          },
          "cols": 2,
          "rows": 1,
          "y": 0,
          "x": 0,
          "hasContent": true
        },
        {
          "layoutDivision": 4,
          "widgetText": "Salary",
          "icon": "dollar",
          "faIcon": false,
          "class": "widget-2",
          "justify": "space-around",
          "align": "middle",
          "cols": 2,
          "rows": 1,
          "y": 0,
          "x": 2,
          "hasContent": true,
          "data": {
            "heading": ["Total Salary", "Avg. Salary"],
          }
        },
        {
          "layoutDivision": 4,
          "widgetText": "Leave Application",
          "icon": "fa fa-bed",
          "faIcon": true,
          "class": "widget-3",
          "justify": "space-around",
          "align": "middle",
          "cols": 2,
          "rows": 1,
          "y": 0,
          "x": 4,
          "hasContent": true,
          "data": {
            "heading": ["Pending Leaves", "Approved Leaves"],
          }
        },
        {
          "layoutDivision": 4,
          "widgetText": "Upcoming Holiday",
          "icon": "fa fa-plane",
          "faIcon": true,
          "class": "widget-4",
          "justify": "space-around",
          "align": "middle",
          "cols": 2,
          "rows": 1,
          "y": 0,
          "x": 6,
          "hasContent": true,
          "data": {
            // "heading": ["Total Holiday", "Gazetted Holiday"],
            "heading": ["Total Holiday"],
          }
        }
      ]
    },
    // "graphs": {
    //   "layoutType": "graph_info_cards",
    //   "data": [
    //     {
    //    //   "layoutDivision": 2,
    //       "widgetText": "Attendace Analysis",
    //       "class": "all-graphs-block",
    //       "graphType": "LineChart",
    //       "options": {
    //         "colors": ['#90ed7d'],
    //         "is3D": true

    //       },
    //       "data": [
    //         ['13 Nov', 200],
    //         ['14 Nov', 400],
    //         ['15 Nov', 600],
    //         ['16 Nov', 800],
    //         ['17 Nov', 1200],
    //         ['18 Nov', 800],
    //         ['19 Nov', 200],

    //       ]
    //     },
    //     {
    //       "layoutDivision": 2,
    //       "widgetText": "Project Analysis",
    //       "class": "all-graphs-block",
    //       "graphType": "LineChart",
    //       "options": {
    //         "colors": ['#e0440e'],
    //         "is3D": true
    //       },
    //       "data": [
    //         ['13 Nov', 1],
    //         ['14 Nov', 4],
    //         ['15 Nov', 6],
    //         ['16 Nov', 7],
    //         ['17 Nov', 8],
    //         ['18 Nov', 6],
    //         ['19 Nov', 1],

    //       ]
    //     }
    //   ]
    // },
    "tableGraph": {
      "layoutType": "table_graph_info_cards",
      "data": [
        {
          "layoutType": "table_graph_info_cards",
          "type": "table",
          "layoutDivision": 2 / 4,
          "widgetText": "Attendace(Location-wise)",
          "class": "all-graphs-block",
          "pagination": false,
          "size": "middle",
          "cols": 4,
          "rows": 2,
          "y": 0,
          "x": 0,
          "hasContent": true,
          "colums": [
            // { "width": "33.3%", "name": "Location" },
            // { "width": "33.3%", "name": "Presence" },
            // { "width": "33.%", "name": "Total" },

            { "name": "Location" },
            { "name": "Presence" },
            { "name": "Total" },
          ],
          data: [
            // {
            //   location: 'Lucknow',
            //   presence: 84,
            //   total: 90,
            // },
            // {
            //   location: 'Noida',
            //   presence: 110,
            //   total: 120,
            // },
            // {
            //   location: 'Pune',
            //   presence: 70,
            //   total: 70,
            // }
          ],
        },

        {
          "type": "graph",
          "layoutDivision": 2 / 4,
          "widgetText": "Attendace Analysis",
          "class": "all-graphs-block",
          "graphType": "ColumnChart",
          "cols": 4,
          "rows": 2,
          "y": 0,
          "x": 4,
          "hasContent": true,
          "options": {
            colors: ['#f7a35c', '#b880e1', '#ec8f6e', '#f3b49f', '#f6c7b6'],
            is3D: true,
          },
          "data": [
            // ['13 Nov', 200],
            // ['14 Nov', 400],
          ]
        }

      ]
    },
    "textCards1": {
      "layoutType": "text_info_cards",
      "data": []
    },
    'actions': {
      'REMOVE': 'remove',
      'REFRESH': 'refresh',
    },
    notificationData: [
      {
        title: 'Ant Design Title 1', description: 'it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters', icons: 'notification', color: '#87d068', timeago: 'Just now'
      },
      {
        title: 'Ant Design Title 2', description: 'it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters', icons: 'notification', color: '#19bc9c', timeago: '2 minutes ago'
      },
      {
        title: 'Ant Design Title 3', description: 'it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters', icons: 'notification', color: '#f1c423', timeago: '3 hours ago'
      },
      {
        title: 'Ant Design Title 4', description: 'it is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters', icons: 'notification', color: '#e74c3c', timeago: '5 hours ago'
      }
    ],
    nwidgetData: [
      {
        id: '1',
        title: 'Doctors Availability', 
        icons: 'fa fa-user-md', 
        color: '#87d068', 
        label: ['Label1', 'Label2'],
        value: ['Value1', 'Value2'],
        bgcolor: '#1890ff'
      }
    ],
  }
}