import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataToolBarUiComponent } from './data-toolbar-ui.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DycFormsModule } from '../../../shared/components/dyc-forms/dyc-forms.module';

@NgModule({
  imports: [
    CommonModule, NgZorroAntdModule, FormsModule, ReactiveFormsModule, DycFormsModule
  ],
  declarations: [DataToolBarUiComponent],
  exports: [DataToolBarUiComponent]
})
export class DataToolbarUiModule { }
