import { Routes, RouterModule } from '@angular/router';
import { HrComponent } from './hr.component';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../shared/api/auth.guard';

export const routes: Routes = [
  {
    path: 'hr',
    component: HrComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'employee', loadChildren: './employee/employee.module#EmployeeModule' },
      { path: 'attendance', loadChildren: './attendance/attendance.module#AttendanceModule' },
      { path: 'holidays', loadChildren: './holiday/holiday.module#HolidayModule' },
      { path: 'dashboard', loadChildren: '../base/dashboard/dashboard.module#DashboardModule' }
    ],
    canActivate: [AuthGuard]
  },
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
