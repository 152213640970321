export class PatientDetails {
  constructor (
    public avatar: string = null,
    public contact_id: number = null,
    public cr_no: string = null,
    public dob: string = null,
    public doctor_emp_id: number = null,
    public doctor_name: string = null,
    public education: string = null,
    public email: string = null,
    public first_name: string = null,
    public freeze_record: boolean = false,
    public gender: string = null,
    public hbcr_no: string = null,
    public id: number = null,
    public last_name: string = null,
    public marital_status: string = null,
    public mid_name: string = null,
    public mother_tongue: string = null,
    public nationality: string = null,
    public occupation: string = null,
    public patient_id: number = null,
    public patient_name: string = null,
    public patient_status: number = null,
    public personal_phone: string = null,
    public phone: number = null,
    public profileImage: string = null,
    public registered_on: string = null,
    public religion: string = null,
    public remarks: string = null,
    public rt_no: string = null,
    public site_id: number = null,
    public site_name: string = null,
    public title: string = null,
  ) {}
}

export class Patient {
  constructor (
    public avatar: string = null,
    public category: string = null,
    public consultant: string = null,
    public contact_id: number = null,
    public cr_no: string = null,
    public diagnosis: string = null,
    public diagnosis_method_name: string = null,
    public diagnosis_options_name: string = null,
    public diagnosis_id: number = null,
    public dob: string = null,
    public doc_emp_id: number = null,
    public doctor_emp_id: number = null,
    public doctor_name: string = null,
    public duration: number = null,
    public email: string = null,
    public final_sign_by: string = null,
    public first_name: string = null,
    public first_planning_on: string = null,
    public gender: string = null,
    public has_contrast: string = null,
    public hbcr_no: string = null,
    public id: number = null,
    public intention_to_treat: number = null,
    public last_name: string = null,
    public marital_status: string = null,
    public mid_name: string = null,
    public nationality: string = null,
    public occupation: string = null,
    public patient_info: any = null,
    public patient_id: number = null,
    public patient_name: string = null,
    public patient_status: string = null,
    public payment: string = null,
    public phone: number = null,
    public physics: string = null,
    public planning: string = null,
    public position_scanning: any = null,
    public profileImage: string = null,
    public profileText: string = null,
    public protocol: string = null,
    public region: string = null,
    public registered_on: string = null,
    public religion: string = null,
    public remarks: string = null,
    public risk_factor: string = null,
    public rtStatusVal: string = null,
    public rt_no: string = null,
    public rt_status: string = null,
    public rt_simulation_id: string = null,
    public rt_treatment_completion_on: string = null,
    public rt_treatment_start_on: string = null,
    public rtp_r_number: string = null,
    public site_id: number = null,
    public site_name: string = null,
    public stage: string = null,
    public status: boolean = false,
    public sub_technique: string = null,
    public technique: string = null,
    public technique_id: number = null,
    public title: string = null,
    public treatment_completion_on: string = null,
    public treatment_id: number = null,
    public treatment_start_on: string = null,
    public treatment_status: number = null,
    public treatment_suggested_on: string = null,
    public treatment_type_id: number = null,
    public treatment_unit: string = null,
    public patientDetails: PatientDetails = null,
  ) {}
}

export class RTDetails {
  constructor (
    public id: number,
    public details: any
  ) {}
}