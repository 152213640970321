/* tslint:disable */
import { Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
/**
 * Default error handler
 */
@Injectable()
export class ErrorHandler {
  errorMessage: any = [];
  constructor(private message: NzMessageService, private ngZone: NgZone, private router: Router) {
    ngZone.runOutsideAngular(() => {
      window.setInterval(() => {
        this.errorMessage.pop();
      }, 2000)
    });
  }

  public handleError(errorResponse: HttpErrorResponse): Observable<never> {
    
    if (this.errorMessage.indexOf(errorResponse.name) == -1) {
     
      if (errorResponse.status == 403 || errorResponse.status == 401) {

        this.router.navigate(['access-denied']);
      } else if (errorResponse.status == 500) {
        this.message.error('Critical Error has been occoured.<br/>Error: ' + errorResponse.name + ":<br/>" + errorResponse.error.message + " | " + (errorResponse.error.error.errorDev.dev));
        this.errorMessage.push(errorResponse.name);
      } else {
        this.message.error('Critical Error has been occoured.<br/>Error: ' + errorResponse.name + ":<br/>" + (errorResponse.error.error || 'Network not found.'));
        this.errorMessage.push(errorResponse.name);
      }
    }
    return throwError(errorResponse.error.error || 'Server error');
  }
}
