/* tslint:disable */

declare var Object: any;
export class UIConfig {  
  constructor(data?: UIConfig) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `UIConfig`.
   */
  public static getModelName() {
    return "UIConfig";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of UIConfig for dynamic purposes.
  **/
  public static factory(data: UIConfig): UIConfig{
    return new UIConfig(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'UIConfig',
      plural: 'ui-configs',      
      path: 'ui-configs',      
    }
  }
}
