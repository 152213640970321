import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CoreAPIService, NavService } from '../../../shared/api/services';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BrowserdbService } from '../../../shared/api/frontend/service/browserdb.service';
import { LoopBackAuth } from '../../../shared/lib/services/core/auth.service';
import { UIConfig } from './UIConfig';
import { NzMessagesService, SHOW_TYPES } from '../../../shared/nz-message.service';
import { EmployeeService } from '../../../shared/api/services/';
//import { BASE_URL, LOGO_ASSETS_UPLOAD_PATH} from '../../../shared/api/base.url';
import { ApplicationSetting } from '../../../helpers/model';
import { UtilsService } from '../../../helpers/utils.service';
import { DataFormatterService } from '../../../helpers/data-formatter.service';
import { Subscription } from 'rxjs/Subscription';
import { error } from 'console';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  // @Input() isDataCollapsed: boolean;
  @Input() cAppKey: string = "front-desk-user";
  @Output() sidebarToggle: EventEmitter<Number> = new EventEmitter();
  @Output() onshowDebuger: EventEmitter<Number> = new EventEmitter();

  visible: boolean;
  apps: any = [];
  widgetsData: any = {
    availableWidget: [],
    addedWidget: []
  };

  navConfig: any = {};
  isCollapsed: boolean;
  isSettingUi: boolean = false;
  showWidgetsDetails: any = { added: null, available: null };
  selectedWidgetsDetails: any = { added: { count: 0, widgets: [] }, available: { count: 0, widgets: [] } };
  isCheckedButton: boolean = false;
  showIndexTabs: any = 'added';
  unreadNotifications: any = [];
  unreadNotificationsCount: number = 0;
  currentUserData: any;
  currentEmpId: number;
  personalData: any;
  officialData: any;
  empSiteId: string;

  /* // logo and title
  public logo_url: string;
  public logo_image : string;
  public favicon_image : string;
  public menu_image : string;
  public watermark_image : string;
  public project_title: string;
  public project_full_name: string; */
  // logo settings
  appSettingsSubscription: Subscription;
  appSettings: ApplicationSetting = new ApplicationSetting();
  isLoading: boolean = false;

  constructor(
    private navService: NavService,
    private browserDB: BrowserdbService,
    private loobackAuth: LoopBackAuth,
    private router: Router,
    private location: Location,
    private apiService: CoreAPIService,
    private message: NzMessagesService,
    private dataService: EmployeeService,
    private util: UtilsService,
    private dataFormatter: DataFormatterService
  ) {
    let data = location.path().replace('/', '').split('/');
    if (data.length > 0) {
      this.cAppKey = data[0];
    }
  }

  toggleCollapsed(evt) {
    this.sidebarToggle.emit(evt);
  }

  showDebuger() {
    this.onshowDebuger.emit();
  }

  // logout() {
  //   this.loobackAuth.clear();
  //   this.browserDB.clearDB();
  //   localStorage.removeItem('mp-selected-tab');
  //   window.location.href = "/#/login";
  // }
  logout(type = 0) {
    if (type == 1) {
      this.isLoading = true;
      this.apiService.api('POST', 'user/logout/'+type, undefined, undefined, undefined).subscribe(res => {
        if (res['status'] === 'success' && res['data'] && res['data']['success'] === true) {
          this.loobackAuth.clear();
          this.browserDB.clearDB();
          localStorage.removeItem('mp-selected-tab');
          window.location.href = "/#/login";
        } else {
          this.message.error(res['message'] || 'Error: while saving data.');
        }
        this.isLoading = false;
      }, error=> {
        console.log(error);
        this.isLoading = false;
      });
    }
  }

  loadNavConfig(app = null) {
    if (!app) {
      app = this.getApp(this.cAppKey);
    }

    if (!app) {
      return false;
    }

    this.browserDB.dbGetByID(app.id, (e, d) => {
      if (!e && d) {
        let str = this.router.url;
        let arr = d['items'];
        arr.forEach(function (value, key) {
          let re = arr[key].routerLink;
          // if (re.indexOf(str) === -1 ) {
          if (str.search(re) === -1) {
            arr[key].item_options.isDefault = false;
          } else {
            arr[key].item_options.isDefault = true;
          }
        });
        app['navConf'] = d;
      } else {
        this.navService.findNAVById(app.id).subscribe(
          res => {
            if (res['data'] && res['data']['success']) {
              let d = res['data']['data'];
              let str = this.router.url;
              d['items'].forEach(function (value, key) {
                let re = d['items'][key].routerLink;
                // if (re.indexOf(str) === -1 ) {
                if (str.search(re) === -1) {
                  d['items'][key].item_options.isDefault = false;
                } else {
                  d['items'][key].item_options.isDefault = true;
                }
              });

              app['navConf'] = d;
              this.browserDB.dbSave(d, app.id, 'menu');
            } else {
              // console.log("Error:" + res['data']['message']);
            }
          },
          error => {
            // console.log("Server Error");
          }
        );
      }
    });
  }

  getApp(key) {
    for (let i = 0; i < this.apps.length; i++) {
      let app = this.apps[i];
      if (key == app["key"]) {
        return app;
      }
    }
  }

  loadWidgets() {
    this.apiService.api('GET', 'widgets/added-avail-requests', undefined).subscribe(res => {
      if (res['status'] === 'success' && res['data'] && res['data']['data'] && res['data']['success'] === true) {
        this.widgetsData.addedWidget = res['data']['data']['addedWidget'];
        this.widgetsData.availableWidget = res['data']['data']['availableWidget'];
      } else {
        this.message.error(res['message'] || 'No widgets found.');
      }
    })
  }

  onMyProfile() {
    this.router.navigate(['/app/myprofile/profile']);
  }

  onAppSelect(key) {
    this.visible = false;
    let app = this.getApp(key);
    this.cAppKey = key;

    if (!(app.navConf && app.navConf["items"])) {
      this.loadNavConfig(app);
    }
  }

  onMySetting() {
    this.isSettingUi = true;
    this.showWidgetsDetails = { added: null, available: null };
  }

  OnCancel() {
    this.isSettingUi = false;
  }

  private addUserWidget(): void {
    this.apiService.api(
      'POST',
      'widgets/create/user-widget',
      undefined, undefined, undefined,
      this.selectedWidgetsDetails['available']['widgets']
    ).subscribe(res => {
      if (res['status'] === 'success' && res['data'] && res['data']['success'] === true) {
        this.isSettingUi = false;
        this.showWidgetsDetails = { added: null, available: null };
        this.selectedWidgetsDetails = { added: { count: 0, widgets: [] }, available: { count: 0, widgets: [] } };
        this.loadWidgets();
      } else {
        this.message.error(res['message'] || 'Error: while saving data.');
      }
    });
  }

  private removeUserWidget(): void {
    let addedWidget = this.selectedWidgetsDetails['added']['widgets'];
    let widgetUpdateData = [{ 'status': 0 }];
    addedWidget.forEach((widget, index) => {
      this.apiService.api(
        'PUT',
        'widgets/user-widget/delete/' + widget.itemKey,
        undefined, undefined, undefined, widgetUpdateData
      ).subscribe(res => {
        if (res['status'] === 'success' && res['data'] && res['data']['success'] === true) {
          this.isSettingUi = false;
          this.showWidgetsDetails = { added: null, available: null };
          this.selectedWidgetsDetails = { added: { count: 0, widgets: [] }, available: { count: 0, widgets: [] } };
        } else {
          this.message.error(res['message'] || 'Error: while saving data.');
        }
      });

      if ((index + 1) === addedWidget.length) {
        this.loadWidgets();
      }
    });
  }

  onSaveOk(type: string): void {
    if (type === 'add') {
      this.addUserWidget();
    } else if (type === 'remove') {
      this.removeUserWidget();
    }
  }

  onShowWidgetDetails(index: number, widgetType: string): void {
    this.showWidgetsDetails[widgetType] = this.widgetsData[widgetType + 'Widget'][index];
  }

  onCheckWidgetDetails(event: any, widgetType: string, widget: any): void {
    if (event === true) {
      this.selectedWidgetsDetails[widgetType]['count'] = this.selectedWidgetsDetails[widgetType]['count'] + 1;
      this.selectedWidgetsDetails[widgetType]['widgets'][widget.widget_id] = {
        widget_id: widget.widget_id,
        widget_config: widget.config,
        itemKey: widget.widget_id
      };
    } else {
      this.selectedWidgetsDetails[widgetType]['count'] = this.selectedWidgetsDetails[widgetType]['count'] - 1;
      this.selectedWidgetsDetails[widgetType]['widgets'].pop(widget.widget_id);
    }
  }

  onTabsClick(evt) {
    this.showIndexTabs = evt;
  }

  onRedirect(link: string) {
    if (link) {
      this.router.navigate([link]);
    } 
  }

  loadUserInfo() {
    this.dataService.loadSection(this.currentEmpId, 'overview').subscribe(
      res => {
        if (res['data'] && res['data']['success']) {

          if (res && res['data'] && res['data']['data']) {
            this.personalData = res['data'] && res['data']['data']['personal']['0'];
            this.officialData = res['data'] && res['data']['data']['official']['0'];
             this.empSiteId = this.officialData.site_id;
           
          }
        } else {
          this.message.error(res['data']['message']);
        }
      },
      error => {
        this.message.showMessage(SHOW_TYPES.ERROR, 'Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  getModules() {
    this.apiService.api('GET', 'core-modules/get-modules', undefined).subscribe(
      res => {
        if (res['status'] === 'success' && res['data'] && res['data']['success'] === true && res['data']['data']) {
          this.apps = res['data']['data'];
          this.loadNavConfig();
        } else {
          this.message.error(res['data']['message'], { nzDuration: 2000 });
        }
      },
      error => {
        this.message.showMessage(SHOW_TYPES.ERROR, 'Critical Error:' + error['message'], error.errorDev.errorCode);
      }
    );
  }

  ngOnInit() {
    //this.loadLogoTitleSetting();
    this.getModules();
    this.loadWidgets();
    this.currentUserData = this.loobackAuth.getCurrentUserData();
    if (this.currentUserData && this.currentUserData.employee_details && this.currentUserData.employee_details.id) {
      this.currentEmpId = this.currentUserData.employee_details.id;
    }
    this.loadUserInfo();
    this.appSettingsSubscription = this.dataFormatter.getApplicationSettings().subscribe(
      res => {
        if(res.favicon_image || res.logo_image || res.menu_image || res.project_full_name || res.project_title || res.watermark_image) {
          this.appSettings = res;
          //console.log(this.appSettings);
        } else {
          this.util.fetchAppSettings();
        }
      }
    )
  }

}
