/* tslint:disable */

declare var Object: any;
export class CredentialTypes {  
  constructor(data?: CredentialTypes) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `CredentialTypes`.
   */
  public static getModelName() {
    return "CredentialTypes";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of CredentialTypes for dynamic purposes.
  **/
  public static factory(data: CredentialTypes): CredentialTypes{
    return new CredentialTypes(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'CredentialTypes',
      plural: 'credential-types',      
      path: 'credential-types',      
    }
  }
}
