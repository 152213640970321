/* tslint:disable */
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams, HttpResponse, HttpParameterCodec } from '@angular/common/http';
import { ErrorHandler } from '../../lib/services/core/error.service';
import { BaseLoopBackApi } from '../../lib/services/core/base.service';
import { LoopBackAuth } from '../../lib/services/core/auth.service';
import { LoopBackConfig } from '../../lib/lb.config';
import { LoopBackFilter } from '../../lib/models/BaseModels';
import { OMSModels } from '../OMSModels';
import { Observable, Subject } from 'rxjs';


/**
 * Api services for the `Widget` model.
 */
@Injectable()
export class WidgetService extends BaseLoopBackApi {
  // public auths = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjE1NyIsImVtYWlsIjoidmlzaGFsLm1vaGFuQHVuaWNvZGVzeXN0ZW1zLmluZm8iLCJmaXJzdG5hbWUiOm51bGwsImxhc3RuYW1lIjpudWxsLCJwaWN0dXJlIjpudWxsLCJwZXJtaXNzaW9uIjpudWxsLCJpYXQiOjE1NTE0MjQ2NDgsImV4cCI6MTU1MTQyNjQ0OH0.mIb3368jxh2aogNDUN0nIq_qvcAmlMrqnLOW4naR-dY";
  constructor(
    @Inject(HttpClient) protected http: HttpClient,
    @Inject(OMSModels) protected models: OMSModels,
    @Inject(LoopBackAuth) protected auth: LoopBackAuth,
    @Optional() @Inject(ErrorHandler) protected errorHandler: ErrorHandler
  ) {
    super(http, models, auth, errorHandler);
  }

  
  /**
   * The name of the model represented by this $resource,
   * i.e. `Widget`.
   */
  public getModelName() {
    return "Widget";
  }

  
  public createWidget(options: any, customHeaders?: Function): Observable<any> {
    let _method: string = "POST";
    let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
      "/widgets/create/comm-widget";
    let _routeParams: any = {};
    let _postBody: any = {
      options: options
    };
    let _urlParams: any = {};
    let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders);
    return result;
  }

  // public getWidget(customHeaders?: Function): Observable<any> {
  //   let _urlParams: any = {};
  //   return this.request('GET', 'http://dev.erpmsfox.com/api/widgets/requests', _urlParams, null, customHeaders);
  // }

  public getWidget<T>(reqParams: any = {}, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'requests'
    ].join('/'), undefined, reqParams, undefined, null, customHeaders)
    // .pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
  }

  public getAllWidget<T>(reqParams: any = {}, filter: LoopBackFilter = {}, customHeaders?: Function): Observable<T[]> {
    return this.request('GET', [
      LoopBackConfig.getPath(),
      LoopBackConfig.getApiVersion(),
      this.model.getModelDefinition().path,
      'all-requests'
    ].join('/'), undefined, reqParams, undefined, null, customHeaders)
    // .pipe(map((datum: T[]) => datum.map((data: T) => this.model.factory(data))));
  }
}
