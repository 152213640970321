import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';

@Injectable({
  providedIn: 'root'
})
export class BrowserdbService {

  private databaseName: string = "appdb";

  public DB: any;

  constructor() {
    PouchDB.plugin(require('pouchdb-erase'));
    this.DB = new PouchDB(this.databaseName);
  }

  public dbSave(value: any, ID: number, type: string = null) {
    let data = Object.assign({ "_id": ID.toString(), "dataType": type }, value);
    this.DB.put(data);
  }

  public dbGetByID(ID: string, cb) {
    this.DB.get(ID.toString(), (err, data) => {
      if (err) return cb(true, err);
      return cb(false, data);
    });
  }

  public clearDB() {
    this.DB.erase().then(res => {
      //console.log('DB: Cleared', res);
    }).catch(err => {
      console.log(err);
    });
  }
}
